import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Logo";


export default class RecoverPasswordSuccessful extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount(){
      localStorage.clear();
      sessionStorage.clear();
    }

    

    render() {
        return (
            <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-12 col-md-6  mx-auto ">
              <div className="auth-form-light text-center p-5 ">
                <div className="brand-logo">
                  <Logo />
                </div>
                <h1><i className="mdi mdi-email-open text-success"></i></h1>
                <h4 className="text-darker">We sent you an email</h4>
                <h6 className="font-weight-light mb-5">To complete your password recovery, <br/>please follow the instructions in the email we just sent to you.</h6>
               
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
      </div>
        );
    }
}

