import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import { Link } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import "moment/locale/en-gb.js";
import Modal from "react-modal";
import Select from "react-select";
import Functions from "../../Utils/Functions";

export default class NewDiseaseReportSuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      errorMessage: null,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <MainLayout title="Disease Report: Success">
        <div className="row">
          <div className="col-md-12 mb-5">
            <div className="text-center">
              <h1 className="text-success big-icon">
                <i className="mdi mdi-checkbox-marked-circle-outline"></i>
              </h1>
              <br />
              <p>New disease report captured successfully</p>
              <div className="mt-4">
                <Link
                  to={`/in/new-disease-report`}
                  className="mt-3 mb-5 btn btn-dark btn-fw btn-rounded mr-3"
                >
                  Capture new disease report
                </Link>

                <Link
                  to="/in/home"
                  className="mt-3 mb-5 btn btn-success btn-fw btn-rounded"
                >
                  Finish
                </Link>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
