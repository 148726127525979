import React, { Component } from "react";
import CardBlock from "../CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../CenterBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import MyTable from "../../Utils/MyTable";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import Functions from "../../Utils/Functions";
import Select from "react-select";
import { functions, throttle } from "lodash";
import "moment/locale/en-gb.js";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import DateFnsUtils from "@date-io/date-fns";
import StatBox from "../StatBox";
import SingleDiseaseReport from "./SingleDiseaseReport";

export default class DiseaseReportsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      errorMessage: null,
      searchQuery: "",
      page: 1,
      pageSize: 10,
      modalIsOpen: false,
      uploadErrorMessage: null,
      uploading: false,
      redirect: null,
      user: {},
      alreadyGotResults: false,
      fromDate: new Date(),
      toDate: new Date(),
      animalSpecie: "all",
      selectedRecord: null,
    };

    this.RenderTable = this.RenderTable.bind(this);
    this.init = this.init.bind(this);

    this.throttledReload = throttle(() => {
      this.init(this.state.page, this.state.pageSize, this.state.searchQuery);
    }, 500);
  }

  componentDidMount() {
    Modal.setAppElement("body");
    this.init(1, this.state.pageSize, "");
  }

  RenderTable(_data) {
    var headerList = [
      {
        Header: "Tag Number",
        accessor: "tagNumber",
      },
      {
        Header: "Specie",
        accessor: "animalSpecie",
      },
      {
        Header: "Organ",
        accessor: "organ",
      },
      {
        Header: "Pathological Diagnosis",
        accessor: "diagnosisComponent",
      },
      {
        Header: "Condemnation",
        accessor: "condemnation",
      },
      {
        Header: "Date Recorded",
        accessor: "dateComponent",
      },
      {
        Header: "",
        accessor: "buttonComponent",
      },
    ];

    const tableHeader = React.useMemo(() => headerList, []);

    _data._data.forEach((element) => {
      element.diagnosisComponent = `${element.pathologicalDiagnosis} ${element.diseaseSuspected} ${element.confirmatoryDiagnosis}`;
      element.dateComponent = new Functions().formatDate(element.dateRecorded);
      element.buttonComponent = (
        <button
          type="button"
          onClick={() => this.setState({ selectedRecord: element })}
          className="btn-primary btn btn-xs "
        >
          See Details
        </button>
      );
      //element.roleComponent = <div style={{zoom: "0.9", textTransform: "uppercase"}} className={`badge badge-${roleColor}`}>{element.effectiveRole}</div>
    });

    const tableData = React.useMemo(() => _data, []);
    return (
      <div style={{ zoom: "0.85" }}>
        <MyTable
          columns={tableHeader}
          data={tableData._data}
          reloadData={this.init}
          enableSearch={true}
          searchQuery={this.state.searchQuery}
          page={this.state.page}
          pageSize={this.state.pageSize}
          cssArray={["", "", "", "", "", "", ""]}
        />
      </div>
    );
  }

  quickReload() {
    this.init(1, this.state.pageSize, this.state.searchQuery);
  }

  init(page, pageSize, query) {
    if (!query) query = "";

    this.setState({
      errorMessage: null,
      loading: true,
      searchQuery: query,
      page: page,
      pageSize: pageSize,
    });

    const fromDate = Functions.sqlDateString(this.state.fromDate);
    const toDate = Functions.sqlDateString(this.state.toDate);
    const animalSpecie = this.state.animalSpecie || "all";
    const userId = this.props.userId || 0;

    APICall(
      `api/diseasereports/fetch/${page}/${pageSize}/${animalSpecie}/${fromDate}/${toDate}/${userId}?query=${query}`,
      "GET"
    )
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ loading: false, data: null }, () => {
            this.setState({ data: response.data });
          });
        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Try again after sometime",
            loading: false,
          });
        }
        console.log(data);
      })
      .catch((error) => {
        this.setState({
          errorMessage:
            "Your request generated an error. Please check your network connection",
          loading: false,
        });
      });
  }

  render() {
    return this.state.redirect ? (
      <Redirect to={this.state.redirect} />
    ) : this.state.errorMessage ? (
      <CenterBlock height="150">
        <h1 className="text-danger icon-lg">
          <i className="mdi mdi-alert-circle"></i>
        </h1>
        <div className="text-dark text-center" role="alert">
          An error occurred, you can try reloading the page
        </div>
        <button
          type="button"
          onClick={() => this.init()}
          className="btn btn-inverse-primary btn-fw mt-4 mb-5"
        >
          Reload
        </button>
      </CenterBlock>
    ) : (
      <>
        {this.state.loading ? (
          <div className="text-success" style={{ position: "absolute" }}>
            <Spinner size="1.5" />{" "}
          </div>
        ) : null}
        {this.state.selectedRecord && (
          <Modal
            closeTimeoutMS={500}
            isOpen={this.state.selectedRecord ? true : false}
            contentLabel=""
            style={Constants.rightModalStyleMini}
            onRequestClose={() => this.setState({ selectedRecord: null })}
          >
            <div className="p-4">
              <h5>
                <span className="font-weight-bold theme-color">
                  Disease report details
                </span>
              </h5>
              <hr style={{ clear: "both", visibility: "hidden" }} />
              {<SingleDiseaseReport {...this.state.selectedRecord} />}
            </div>
          </Modal>
        )}
        <div style={{ zoom: "0.85" }} className="mb-3">
          <div className="form-group row">
            <div className="col col-md-3">
              <label className="control-label font-weight-bold text-darker">
                Date from
              </label>
              <DatePickerInput
                defaultValue={this.state.fromDate}
                onChange={(date) =>
                  this.setState({ fromDate: date }, () => {
                    this.quickReload();
                  })
                }
                className="my-custom-datepicker-component"
              />
            </div>
            <div className="col text-center" style={{ maxWidth: "20px" }}>
              <label
                style={{ visibility: "hidden" }}
                className="control-label font-weight-bold text-darker"
              >
                -
              </label>
              <label className="control-label font-weight-bold text-darker mt-2">
                _
              </label>
            </div>
            <div className="col col-md-3">
              <label className="control-label font-weight-bold text-darker">
                Date to
              </label>
              <DatePickerInput
                defaultValue={this.state.toDate}
                onChange={(date) =>
                  this.setState({ toDate: date }, () => {
                    this.quickReload();
                  })
                }
                className="my-custom-datepicker-component"
              />
            </div>
          </div>
        </div>
        <br style={{ clear: "both" }} />
        {this.state.data?.summary && (
          <div className="row">
            <StatBox
              link={"#"}
              colClass="col-6 col-md-3"
              title="Total Reported"
              data={this.state.data.summary.count}
            />
            <StatBox
              link={"#"}
              colClass="col-6 col-md-3"
              title="Partial Condemnation"
              data={this.state.data.summary.partialCondemnation}
            />
            <StatBox
              link={"#"}
              colClass="col-6 col-md-3"
              title="Total Condemnation"
              data={this.state.data.summary.totalCondemnation}
            />
            <StatBox
              link={"#"}
              colClass="col-6 col-md-3"
              title="Inconsequential"
              data={this.state.data.summary.inconsequential}
            />
          </div>
        )}
        {!this.state.data?.data ? null : (
          <this.RenderTable _data={this.state.data.data} />
        )}
      </>
    );
  }
}
