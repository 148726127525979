import React, { Component } from "react";
import CenterBlock from "./CenterBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../Utils/Constants";
import APICall from "../Utils/APICall";
import Spinner from "../Utils/Spinner";
import MyTable from "../Utils/MyTable";
import Modal from "react-modal";
import Functions from "../Utils/Functions";
import { throttle } from "lodash";
import "moment/locale/en-gb.js";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import DateFnsUtils from "@date-io/date-fns";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import "../sass/file-preview.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from "react-select";
import SpinnerButton from "../Utils/SpinnerButton";

export default class LabTestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      errorMessage: null,
      searchQuery: "",
      page: 1,
      pageSize: 10,
      modalIsOpen: false,
      uploadErrorMessage: null,
      uploading: false,
      redirect: null,
      user: {},
      alreadyGotResults: false,
      fromDate: this.defaultStartDate(),
      toDate: new Date(),
      pdfDocument: null,
      selectedRecord: null,
    };

    this.RenderTable = this.RenderTable.bind(this);
    this.init = this.init.bind(this);

    this.throttledReload = throttle(() => {
      this.init(this.state.page, this.state.pageSize, this.state.searchQuery);
    }, 500);
  }

  defaultStartDate() {
    var today = new Date();
    var day = today.getDay();
    let diff = today.getDate() - day + (day == 0 ? -6 : 1);
    var oneWeekAgo = new Date(today.setDate(diff));
    return oneWeekAgo;
  }

  showDocument(record) {
    if (this.props.selectAction) {
      this.props.selectAction(record);
    } else {
      this.setState({ selectedRecord: record });
    }
  }

  componentDidMount() {
    this.init();
    Modal.setAppElement("body");
  }

  RenderTable({ data }) {
    const headerList = [
      {
        Header: "Tracking Code",
        accessor: "codeComponent",
      },
      {
        Header: "Outlet",
        accessor: "outletComponent",
      },
      {
        Header: "Laboratory",
        accessor: "labComponent",
      },
      {
        Header: "Veterinary",
        accessor: "vetComponent",
      },
      {
        Header: "Date Issued",
        accessor: "dateComponent",
      },
    ];

    const tableHeader = React.useMemo(() => headerList, []);

    if (data) {
      data.forEach((element) => {
        element.dateComponent = Functions.toReadableDate(
          element.sampleCollectionDate
        );
        element.outletComponent = element.retailOutlet.name;
        element.labComponent = element.laboratory.name;
        element.vetComponent = element.vet.name;
        element.codeComponent = (
          <span
            onClick={() => this.showDocument(element)}
            style={{ fontFamily: "Helvetica, Arial", cursor: "pointer" }}
          >
            {element.sampleCode}
          </span>
        );
      });
    }

    const tableData = React.useMemo(() => data, []);
    console.log("data", tableData);
    console.log("data2", data);
    const tableParams = {
      columns: tableHeader,
      cssArray: ["font-weight-bold", "", "", "", "text-right"],
      data,
      reloadData: (page, pageSize, query) => this.init(page, pageSize, query),
      enableSearch: true,
    };
    return <div style={{ zoom: "0.85" }}>{MyTable({ ...tableParams })}</div>;
  }

  quickReload() {
    this.init(this.state.page, this.state.pageSize, this.state.searchQuery);
  }

  init(page, pageSize, query) {
    if (!query) query = "";

    this.setState({
      errorMessage: null,
      loading: true,
      searchQuery: query,
      page: page,
      pageSize: pageSize,
    });

    const vetId = this.props.vetId || 0;
    const retailOutletId = this.props.retailOutletId || 0;
    const laboratoryId = this.props.laboratoryId || 0;
    const dateFrom = Functions.sqlDateString(this.state.fromDate);
    const dateTo = Functions.sqlDateString(this.state.toDate);
    if (!page) page = 1;
    if (!pageSize) pageSize = 10;

    APICall(
      `api/labs/tests/${page}/${pageSize}/${dateFrom}/${dateTo}/${vetId}/${retailOutletId}/${laboratoryId}?query=${query}`,
      "GET",
      {}
    )
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ loading: false, data: response.data }, () => {});
        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Try again after sometime",
            loading: false,
          });
        }
        console.log(data);
      })
      .catch((error) => {
        this.setState({
          errorMessage:
            "Your request generated an error. Please check your network connection",
          loading: false,
        });
      });
  }

  displayRecord = () => {
    return (
      <div
        className="mymodal-wrapper"
        style={{ maxWidth: "100%", width: "600px" }}
      >
        <h5 className="theme-color">
          Tracking code: <strong>{this.state.selectedRecord.sampleCode}</strong>
        </h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <label className="small control-label font-weight-bold">
                Sample / Test Requested
              </label>
              <p className="text-darker mb-0">
                {this.state.selectedRecord.sampleDescription}
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12 col-md-4">
              <label className="small control-label font-weight-bold">
                Retail outlet
              </label>
              <p className="text-darker  mb-0">
                {this.state.selectedRecord.retailOutlet.name}
              </p>
            </div>
            <div className="col-12 col-md-4">
              <label className="control-label font-weight-bold small">
                Laboratory
              </label>
              <p className="text-darker  mb-0">
                {this.state.selectedRecord.laboratory.name}
              </p>
            </div>
            <div className="col-12 col-md-4">
              <label className="control-label font-weight-bold small">
                Veterinarian
              </label>
              <p className="text-darker  mb-0">
                {this.state.selectedRecord.vet.name}
              </p>
            </div>
          </div>

          {this.state.selectedRecord.testResult && (
            <div className="form-group row">
              <div className="col">
                <label className="small control-label font-weight-bold">
                  Test results:
                </label>
                <p className="text-darker mb-0">
                  {this.state.selectedRecord.testResult}
                </p>
                <p className="text-darker mt-3">
                  <strong className="text-muted">
                    Laboratory's Recommendation
                  </strong>{" "}
                  <br />
                  {this.state.selectedRecord.laboratoryComments}
                </p>
                <p className="text-darker mt-3">
                  <strong>Date: </strong>
                  {Functions.toReadableDate(
                    this.state.selectedRecord.testResultDate
                  )}
                </p>
              </div>
            </div>
          )}

          {this.state.selectedRecord.vetAction && (
            <div className="form-group row">
              <div className="col">
                <label className="small control-label font-weight-bold">
                  Vet Action:{" "}
                  {this.state.selectedRecord.vetAction == "blacklist" ? (
                    <span className="badge badge-danger">Blacklisted</span>
                  ) : (
                    <span className="badge badge-primary">
                      {this.state.selectedRecord.vetAction}
                    </span>
                  )}
                </label>
                <p className="text-darker mt-3">
                  <strong className="text-muted">Vet's Comments</strong> <br />
                  {this.state.selectedRecord.vetComments}
                </p>
                <p className="text-darker mt-3">
                  <strong>Vet's action date: </strong>
                  {Functions.toReadableDate(
                    this.state.selectedRecord.vetActionDate
                  )}
                </p>
              </div>
            </div>
          )}
          <hr />
          {!this.state.selectedRecord.testResult && (
            <p className="text-danger small">
              Test result has not been submitted by laboratory
            </p>
          )}
        </form>
      </div>
    );
  };

  render() {
    return this.state.redirect ? (
      <Redirect to={this.state.redirect} />
    ) : this.state.errorMessage ? (
      <CenterBlock height="150">
        <h1 className="text-danger icon-lg">
          <i className="mdi mdi-alert-circle"></i>
        </h1>
        <div className="text-dark text-center" role="alert">
          An error occurred, you can try reloading the page
        </div>
        <button
          type="button"
          onClick={() => this.init()}
          className="btn btn-inverse-success btn-fw mt-4 mb-5 btn-rounded"
        >
          Reload
        </button>
      </CenterBlock>
    ) : (
      <>
        {this.state.loading ? (
          <div className="text-success" style={{ position: "absolute" }}>
            <Spinner size="1.5" />{" "}
          </div>
        ) : null}

        {this.state.selectedRecord && (
          <Modal
            isOpen={this.state.selectedRecord ? true : false}
            contentLabel=""
            style={Constants.defaultModalStyle}
            onRequestClose={() => {
              this.setState({ selectedRecord: null });
            }}
          >
            {this.displayRecord()}
          </Modal>
        )}

        <div style={{ zoom: "0.85" }} className="mb-3">
          <div className="form-group row">
            <div className="col col-md-3">
              <label className="control-label font-weight-bold text-darker">
                Date from
              </label>
              <DatePickerInput
                defaultValue={this.state.fromDate}
                onChange={(date) =>
                  this.setState({ fromDate: date }, () => {
                    this.quickReload();
                  })
                }
                className="my-custom-datepicker-component"
              />
            </div>
            <div className="col text-center" style={{ maxWidth: "20px" }}>
              <label
                style={{ visibility: "hidden" }}
                className="control-label font-weight-bold text-darker"
              >
                -
              </label>
              <label className="control-label font-weight-bold text-darker mt-2">
                _
              </label>
            </div>
            <div className="col col-md-3">
              <label className="control-label font-weight-bold text-darker">
                Date to
              </label>
              <DatePickerInput
                defaultValue={this.state.toDate}
                onChange={(date) =>
                  this.setState({ toDate: date }, () => {
                    this.quickReload();
                  })
                }
                className="my-custom-datepicker-component"
              />
            </div>
          </div>
        </div>
        <br style={{ clear: "both" }} />

        {this.state.data.length > 0 && (
          <this.RenderTable data={this.state.data} />
        )}
        {this.state.data.length <= 0 && (
          <p className="mt-3">No data to display</p>
        )}
      </>
    );
  }
}
