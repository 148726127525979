import React, { useState, useEffect } from "react";
import APICall from "../../Utils/APICall";
import Toastr from "../../Utils/Toastr";
import MainLayout from "../../Components/Layouts/MainLayout";
import Select from "react-select";
import { Link } from "react-router-dom";
import CardBlock from "../../Components/CardBlock";
import Spinner from "../../Utils/Spinner";
import Tabs from "../../Utils/Tabs";
import SpinnerButton from "../../Utils/SpinnerButton";
import DataAPI from "../../Utils/DataAPI";
import Constants from "../../Utils/Constants";
import MyTable from "../../Utils/MyTable";
import Functions from "../../Utils/Functions";
import Badge from "../../Components/Badge";

export default function AnimalConfigPage() {
  const [data, setData] = useState({});
  const [species, setSpecies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updatedTracker, changeUpdatedTracker] = useState(false);
  const [subSpecies, setSubSpecies] = useState([]);
  const [newSubSpecie, setNewSubSpecie] = useState(null);
  const [newColor, setNewColor] = useState(null);
  const [specieColors, setSpecieColors] = useState([]);
  const [selectedSpecie, setSelectedSpecie] = useState(null);
  const [updating, setUpdating] = useState(false);

  const loadConfig = async () => {
    setLoading(true);
    const animalConfigs = await DataAPI.diseaseReportingConfiguration(true);
    if (animalConfigs) {
      let species = [];
      animalConfigs.species.forEach((specie) => {
        species.push({ label: specie, value: specie });
      });

      setSpecies(species);
      setData(animalConfigs);
    }
    setLoading(false);
    return animalConfigs;
  };

  useEffect(() => {
    loadConfig();
  }, [updatedTracker]);

  const addSubSpecie = async () => {
    setUpdating(true);
    const __specie = selectedSpecie.value;
    APICall("api/configuration/addsubspecie", "POST", {
      specie: __specie,
      subspecie: newSubSpecie,
    })
      .then(async (data) => {
        const response = data.data;
        if (response.isSuccess) {
          await loadSubSpecies(__specie, true);
        } else {
          Toastr("error", response.message);
        }
        setUpdating(false);
      })
      .catch((error) => {
        setUpdating(false);
        Toastr("error", "An error occurred, please check your network");
      });
  };

  const addColor = async () => {
    setUpdating(true);
    const __specie = selectedSpecie.value;
    APICall("api/configuration/addcolor", "POST", {
      specie: __specie,
      color: newColor,
    })
      .then(async (data) => {
        const response = data.data;
        if (response.isSuccess) {
          await loadColors(__specie, true);
        } else {
          Toastr("error", response.message);
        }
        setUpdating(false);
      })
      .catch((error) => {
        setUpdating(false);
        Toastr("error", "An error occurred, please check your network");
      });
  };

  const removeSubSpecie = async (id) => {
    setUpdating(true);
    const __specie = selectedSpecie.value;
    APICall(`api/configuration/removesubspecie/${id}`, "POST", {})
      .then(async (data) => {
        const response = data.data;
        if (response.isSuccess) {
          await loadSubSpecies(__specie, true);
        } else {
          Toastr("error", response.message);
        }
        setUpdating(false);
      })
      .catch((error) => {
        setUpdating(false);
        Toastr("error", "An error occurred, please check your network");
      });
  };

  const removeColor = async (id) => {
    setUpdating(true);
    const __specie = selectedSpecie.value;
    APICall(`api/configuration/removespeciecolor/${id}`, "POST", {})
      .then(async (data) => {
        const response = data.data;
        if (response.isSuccess) {
          await loadColors(__specie, true);
        } else {
          Toastr("error", response.message);
        }
        setUpdating(false);
      })
      .catch((error) => {
        setUpdating(false);
        Toastr("error", "An error occurred, please check your network");
      });
  };

  const loadSubSpecies = async (specie, force) => {
    let _animalConfigs = data;
    if (force) {
      _animalConfigs = await loadConfig();
    }
    const _subSpecies =
      _animalConfigs.subSpecies.filter(
        (a) => a.specie.toLowerCase() == specie.toLowerCase()
      ) || [];
    setSubSpecies(_subSpecies);
  };

  const loadColors = async (specie, force) => {
    let _animalConfigs = data;
    if (force) {
      _animalConfigs = await loadConfig();
    }
    const _subColors =
      _animalConfigs.colorOptions.filter(
        (a) => a.specie.toLowerCase() == specie.toLowerCase()
      ) || [];
    setSpecieColors(_subColors);
  };

  const specieChanged = async (option) => {
    //grab subspecies from loaded configuration
    setSelectedSpecie(option);
    await loadSubSpecies(option.value);
    await loadColors(option.value, false);
  };

  return (
    <MainLayout title="Specie Configuration">
      <CardBlock>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div>
              <form>
                <div className="row">
                  <div className="col-12 col-md-6 mx-auto">
                    <div className="row form-group">
                      <label className="control-label col-12 text-dark">
                        Animal Specie
                      </label>
                      <div className="col-12" style={{ zIndex: "999999999" }}>
                        <Select
                          options={species}
                          onChange={specieChanged}
                          styles={Constants.selectStyles}
                          value={selectedSpecie}
                        />
                      </div>
                    </div>
                  </div>
                  {selectedSpecie && (
                    <div className="col-12 mb-4">
                      <hr />
                    </div>
                  )}
                </div>
                {selectedSpecie && (
                  <div className="row">
                    <div className="col col-md-6">
                      <div className="row form-group">
                        <label className="control-label col-12 text-dark">
                          Sub Species
                        </label>
                        <div className="col-10">
                          <div
                            className="input-group mb-3 input-group-sm"
                            style={{ zoom: "0.85" }}
                          >
                            <input
                              type="text"
                              placeholder="new sub-specie"
                              onChange={(e) => setNewSubSpecie(e.target.value)}
                              className="form-control"
                              onKeyPress={(e) =>
                                e.key === "Enter" && addSubSpecie()
                              }
                            />
                            <div className="input-group-append">
                              <SpinnerButton
                                onClick={() => addSubSpecie()}
                                className="btn btn-dark"
                                label="+ Add Sub-specie"
                                loading={updating}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          {(!subSpecies || subSpecies.length <= 0) && (
                            <p>No sub species configured yet</p>
                          )}
                          {subSpecies.map((s, index) => (
                            <Badge
                              key={index}
                              label={s.subSpecie}
                              deleteAction={() => removeSubSpecie(s.id)}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col col-md-6">
                      <div className="row form-group">
                        <label className="control-label col-12 text-dark">
                          Colour Options
                        </label>
                        <div className="col-10">
                          <div
                            className="input-group mb-3 input-group-sm"
                            style={{ zoom: "0.85" }}
                          >
                            <input
                              type="text"
                              placeholder={`new colour option for ${selectedSpecie.value}`}
                              onChange={(e) => setNewColor(e.target.value)}
                              className="form-control"
                              onKeyPress={(e) =>
                                e.key === "Enter" && addColor()
                              }
                            />
                            <div className="input-group-append">
                              <SpinnerButton
                                onClick={() => addColor()}
                                className="btn btn-dark"
                                label="+ Add Colour"
                                loading={updating}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          {(!subSpecies || subSpecies.length <= 0) && (
                            <p>No colour options configured yet</p>
                          )}
                          {specieColors.map((s, index) => (
                            <Badge
                              key={index}
                              label={s.color}
                              theme="default"
                              deleteAction={() => removeColor(s.id)}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </>
        )}
      </CardBlock>
    </MainLayout>
  );
}
