import React, { Component } from "react";
import CardBlock from "../../Components/CardBlock";
import MainLayout from "../../Components/Layouts/MainLayout";
import UsersList from "../../Components/UsersList";
import Constants from "../../Utils/Constants";
import Select from "react-select";
import SpinnerButton from "../../Utils/SpinnerButton";
import APICall from "../../Utils/APICall";
import Toastr from "../../Utils/Toastr";
import Modal from "react-modal";
import InvestorsList from "../../Components/InvestorsList";
import Spinner from "../../Utils/Spinner";
import { DataContext } from "../../Contexts/DataContexts";

export default class InvestorsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      loading: true,
      saveErrorMessage: null,
      payload: {},
      abattoir: {},
    };

    this.newRecordForm = this.newRecordForm.bind(this);
    this.updateRecordForm = this.updateRecordForm.bind(this);
  }

  static contextType = DataContext;

  componentDidMount() {
    this.getAbattoir();
  }

  getAbattoir() {
    this.setState({ loading: true, saveErrorMessage: null });
    APICall(
      `api/abattoirs/get/${this.props.match.params.abattoirId}`,
      "GET",
      {}
    )
      .then((data) => {
        var response = data.data;
        this.setState({ loading: false });
        if (response.isSuccess) {
          this.setState({ abattoir: response.data });
        } else {
          //error scenario
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          saveErrorMessage: "An error occurred, please check your network",
        });
      });
  }

  updatePayload(value, index, forUpdate) {
    let payload = forUpdate ? this.state.selectedRecord : this.state.payload;
    payload[index] = value;
    this.setState({ payload: payload });
  }

  selectRecord(record) {
    this.setState({ selectedRecord: record, payload: record });
  }

  newRecordForm() {
    return (
      <div className="mymodal-wrapper" style={{ width: "400px" }}>
        <h5>
          <span className="font-weight-normal">Add Abattoir Investor to:</span>
          <br />
          {this.state.abattoir.name}
        </h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Investor name"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "investorName")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Phone number"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "phoneNumber")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="email"
                placeholder="Email address"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "emailAddress")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>

          {this.state.saveErrorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {this.state.saveErrorMessage}
              </div>
            </div>
          ) : null}

          <SpinnerButton
            onClick={() => this.addRecord()}
            className="btn btn-success mr-2 btn-block mb-3 mt-3 btn-rounded"
            label="Add Abattoir Investor"
            loading={this.state.loading}
          />
        </form>
      </div>
    );
  }

  updateRecordForm() {
    return (
      <div className="mymodal-wrapper" style={{ width: "400px" }}>
        <h5>
          <span className="font-weight-normal">Update Investor Record</span>
        </h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                defaultValue={this.state.selectedRecord.investorName}
                placeholder="Investor name"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "investorName", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                defaultValue={this.state.selectedRecord.phoneNumber}
                placeholder="Phone number"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "phoneNumber", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="email"
                defaultValue={this.state.selectedRecord.emailAddress}
                placeholder="Email address"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "emailAddress", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>

          {this.state.saveErrorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {this.state.saveErrorMessage}
              </div>
            </div>
          ) : null}

          <SpinnerButton
            onClick={() => this.updateRecord()}
            className="btn btn-success mr-2 btn-block mb-3 mt-3 btn-rounded"
            label="Update Record"
            loading={this.state.loading}
          />
        </form>
      </div>
    );
  }

  addRecord() {
    let payload = this.state.payload;
    payload.abattoirId =
      this.state.abattoir.id || this.props.match.params.abattoirId;
    this.setState({ loading: true, saveErrorMessage: null });
    APICall("api/abattoirs/addinvestor", "POST", payload)
      .then((data) => {
        var response = data.data;
        this.setState({ loading: false });
        if (response.isSuccess) {
          Toastr("success", "New investor added");
          this.setState({ modalIsOpen: false, payload: {} });
        } else {
          this.setState({ saveErrorMessage: response.message });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          saveErrorMessage: "An error occurred, please check your network",
        });
      });
  }

  updateRecord() {
    let payload = {
      id: this.state.selectedRecord.id,
      investorName: this.state.payload.investorName,
      phoneNumber: this.state.payload.phoneNumber,
      emailAddress: this.state.payload.emailAddress,
    };

    this.setState({ loading: true, saveErrorMessage: null });
    APICall("api/abattoirs/updateinvestor", "POST", payload)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          Toastr("success", "Record updated");
          this.setState({ selectedRecord: null, payload: {}, loading: false });
        } else {
          this.setState({ saveErrorMessage: response.message, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          saveErrorMessage: "An error occurred, please check your network",
        });
      });
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          contentLabel=""
          style={Constants.defaultModalStyle}
          onRequestClose={() =>
            this.setState({ modalIsOpen: false, payload: {} })
          }
        >
          <this.newRecordForm />
        </Modal>

        <Modal
          isOpen={this.state.selectedRecord ? true : false}
          contentLabel=""
          style={Constants.defaultModalStyle}
          onRequestClose={() =>
            this.setState({ selectedRecord: null, payload: {} })
          }
        >
          {this.state.selectedRecord && <this.updateRecordForm />}
        </Modal>

        <MainLayout
          title={
            <>
              {this.state.loading ? (
                <Spinner />
              ) : (
                <>
                  <small className="font-weight-normal">Investors for: </small>
                  <br />
                  <small>{this.state.abattoir.name}</small>
                </>
              )}

              {this.context.globalState.user.userRole.includes("admin") && (
                <button
                  onClick={() =>
                    this.setState({ modalIsOpen: true, payload: {} })
                  }
                  className="btn-sm btn btn-success btn-fw float-right btn-rounded"
                >
                  <i className="mdi mdi-account-plus mr-1"></i> Add Investor
                </button>
              )}
            </>
          }
        >
          <div className="row">
            <div className="col-md-12 mb-5">
              <CardBlock>
                <div className="text-right"></div>

                {this.state.selectedRecord
                  ? null
                  : this.state.modalIsOpen || (
                      <InvestorsList
                        selectRecord={(record) => this.selectRecord(record)}
                        abattoirId={this.props.match.params.abattoirId}
                      />
                    )}
              </CardBlock>
            </div>
          </div>
        </MainLayout>
      </>
    );
  }
}
