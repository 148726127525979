import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import { Link } from "react-router-dom";
import Constants from "../../Utils/Constants";

export default class SendTestSampleSuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      errorMessage: null,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <MainLayout title="Sample Sent to Lab">
        <div className="row">
          <div className="col-md-12 mb-5">
            <div className="text-center">
              <h1 className="text-success big-icon">
                <i className="mdi mdi-checkbox-marked-circle-outline"></i>
              </h1>
              <br />
              <p>Laboratory has been notified of this sample</p>
              <h3>
                <strong className="text-dark">Tracking code: </strong>
                <span className="theme-color">
                  {this.props.match.params.trackingCode}
                </span>
              </h3>
              <div className="mt-4">
                <Link
                  to={`/in/send-test-to-lab`}
                  className="mt-3 mb-5 btn btn-dark btn-fw btn-rounded mr-3"
                >
                  Send New Sample
                </Link>

                <Link
                  to={`/in/home`}
                  className="mt-3 mb-5 btn btn-dark btn-fw btn-rounded mr-3"
                >
                  Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
