import APICall from "./APICall";
import React from "react";
import { jssPreset } from "@material-ui/core";

export default class DataAPI {
  static specializationsList(callback) {
    APICall("api/specializations/masterlist", "GET", null)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          callback(response.data, null);
        } else {
          callback(null, response.message);
        }
      })
      .catch((error) => {
        callback(null, "Please check your connection");
      });
  }

  static diseaseReportingConfiguration = async (noCache) => {
    let data = sessionStorage.getItem("disease-reporting-configuration");
    if (data && !noCache) return JSON.parse(data);

    await APICall("api/diseasereports/loadconfiguration", "GET", null).then(
      (apidata) => {
        var response = apidata.data;
        if (response.isSuccess) {
          sessionStorage.setItem(
            "disease-reporting-configuration",
            JSON.stringify(response.data)
          );
          data = response.data;
        }
      }
    );
    return data;
  };

  static findAnimal = async (tagNumber) => {
    let data = null;
    await APICall(`api/abattoirs/fetchanimal/${tagNumber}`, "GET", null).then(
      (apidata) => {
        const response = apidata.data;
        if (response.isSuccess) {
          data = response.data;
        }
      }
    );
    return data;
  };

  static findAnimalBirthInformation = async (tagNumber) => {
    let data = null;
    await APICall(`api/Ranch/getranchanimalbyanimaltag/${tagNumber}`, "GET", null).then(
      (apidata) => {
        const response = apidata.data;
        if (response.isSuccess) {
          data = response.data;
        }
      }
    );
    return data;
  };

  static findAnimalMonthlyRecords = async (tagNumber) => {
    let data = null;
    await APICall(`api/Ranch/getmonthlyranchanimalbyanimaltag/${tagNumber}`, "GET", null).then(
      (apidata) => {
        const response = apidata.data;
        if (response.isSuccess) {
          data = response.data;
        }
      }
    );
    return data;
  };

  static listUsers = async (page, pageSize, role, query) => {
    let data = null;
    await APICall(
      `api/users/fetch/${page}/${pageSize}/${role}?query=${query}`,
      "GET",
      null
    ).then((apidata) => {
      const response = apidata.data;
      if (response.isSuccess) {
        data = response.data;
      }
    });
    return data;
  };

  static listSupplyLog = async (certificateCode) => {
    let data = null;
    await APICall(`api/supplies/list/${certificateCode}`, "GET", null).then(
      (apidata) => {
        const response = apidata.data;
        if (response.isSuccess) {
          data = response.data;
        }
      }
    );
    return data;
  };

  static verifyCertificate = async (certificateCode) => {
    let data = null;
    await APICall(
      `api/certificates/verify/${certificateCode}`,
      "GET",
      null
    ).then((apidata) => {
      const response = apidata.data;
      if (response.isSuccess) {
        data = response.data;
      }
    });

    return {
      certificate: data?.certificate,
      diseaseReport: data?.diseaseReport,
      supplyList: data?.supplyList,
    };
  };

  static banksList = async () => {
    let data = sessionStorage.getItem("banks-list");
    if (data) return JSON.parse(data);

    await APICall("api/bankaccounts/listbanks", "GET", null).then((apidata) => {
      var response = apidata.data;
      if (response.isSuccess) {
        sessionStorage.setItem("banks-list", JSON.stringify(response.data));
        data = response.data;
      }
    });
    return data;
  };

  static retailersAddresses = async () => {
    let data = sessionStorage.getItem("retail-addresses");
    if (data) {
      return JSON.parse(data);
    }

    await APICall("api/configuration/listaddresses", "GET", null).then(
      (apidata) => {
        var response = apidata.data;
        if (response.isSuccess) {
          sessionStorage.setItem(
            "retail-addresses",
            JSON.stringify(response.data)
          );
          data = response.data;
        }
      }
    );

    return data || [];
  };

  static listDistributors = async (state, country) => {
    let returnData = null;
    // await APICall(`api/distributors/list`, "get")
    await APICall(`api/distributors/get/${country}/${state}`, "get")
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          if (response.data) {
            returnData = response.data;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return returnData;
  };
}
