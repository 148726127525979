import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CenterBlock from "../../Components/CenterBlock";
import AdminDashboard from "../../Components/Dashboards/AdminDashboard";
import Spinner from "../../Utils/Spinner";
import VetDashboard from "../../Components/Dashboards/VetDashboard";
import DistributorDashboard from "../../Components/Dashboards/DistributorDashboard";
import { DataContext } from "../../Contexts/DataContexts";
import VetSupervisorDashboard from "../../Components/Dashboards/VetSupervisorDashboard";
import RegularDashboard from "../../Components/Dashboards/RegularDashboard";
import RanchDashboard from "../../Components/Dashboards/RanchDashboard";
import DeliveryPage from "../Distributors/DeliveryPage";
import TakeDeliveryPage from "../Vets/TakeDeliveryPage";
import { Navigate } from "react-router";
import { useHistory } from 'react-router-dom';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorState: false,
    };
  }

  static contextType = DataContext;

  componentDidMount() { }

  handleButtonClick = () => {
    const { history } = this.props;
    history.push('/in/take-delivery');
  }

  render() {
    return (
      <DataContext.Consumer>
        {(value) => (
          <MainLayout title="">
            {this.state.loading ? (
              <Spinner />
            ) : this.state.errorState ? (
              <CenterBlock height="150">
                <h1 className="text-danger">
                  <i className="mdi mdi-exclamation"></i>
                </h1>
                <div className="text-danger small" role="alert">
                  An error occurred on this page. <br />
                  Try reloading the page.
                </div>
                <button
                  type="button"
                  onClick={() => this.init()}
                  className="btn btn-inverse-dark btn-fw mt-4 btn-rounded"
                >
                  Reload
                </button>
              </CenterBlock>
            ) : value.globalState.user.userRole == "superadmin" ? (
              <AdminDashboard />
            ) : value.globalState.user.userRole == "admin" ? (
              <AdminDashboard />
            ) : value.globalState.user.userRole == "vetsupervisor" ? (
              <VetSupervisorDashboard />
            ) : value.globalState.user.userRole == "vet" ? (
              <VetDashboard />
            ) : value.globalState.user.userRole == "distributor" ? (
              <DistributorDashboard />
            ) : value.globalState.user.userRole == "operationsassistant" ? (
              <p>{this.handleButtonClick()}</p>
            ) : value.globalState.user.userRole == "ranch" ? (
              <RanchDashboard />
            ) : (
              <RegularDashboard />
            )}
          </MainLayout>
        )}
      </DataContext.Consumer>
    );
  }
}
