import React, { Component } from "react";
import Constants from "../../Utils/Constants";
import AddAppScripts from "./AddAppScripts";

export default function PublicWithTopStripBackground({ children }) {
  return (
    <div
      className="container-fluid page-body-wrapper full-page-wrapper"
      style={{
        backgroundImage: `url(${Constants.subfolder}/assets/images/background-strip.jpeg)`,
        backgroundPosition: "0px -100px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <AddAppScripts />
      <div
        className="content-wrapper d-flex align-items-center auth"
        style={{ background: "transparent" }}
      >
        <div className="row flex-grow">{children}</div>
      </div>
      {/* content-wrapper ends */}
    </div>
  );
}
