import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../Logo";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "../../sass/react-phone.scss";
import PublicWithTopStripBackground from "../../Components/Layouts/PublicWithTopStripBackground";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRecord: {},
      errorMessage: null,
      loading: false,
      recaptchaToken: null,
      redirectUrl: null,
      phoneNumber: null,
    };

    this.handlePhoneOnChange = this.handlePhoneOnChange.bind(this);
  }

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
    // eval(`configureRegistrationCaptcha();`);
  }

  handlePhoneOnChange(phone, data2) {
    this.setState({ phoneNumber: phone });
    console.log(phone);
    console.log(data2);
  }

  upateProp(e, prop) {
    var record = this.state.newRecord;
    record[prop] = e.target.value;
    this.setState({ newRecord: record });
  }

  validatePhone() {
    APICall("api/users/register/phone", "POST", {
      data: this.state.phoneNumber,
      captchaToken: this.state.recaptchaToken,
    })
      .then((data) => {
        console.log(data);
        if (data.data.isSuccess) {
          this.setState({
            redirectUrl:
              `/register-complete-${this.props.match.params.role}/` +
              data.data.data,
          });
        } else {
          this.setState({ errorMessage: data.data.message, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  submit() {
    if (!this.state.phoneNumber || this.state.phoneNumber.trim() === "") {
      this.setState({ errorMessage: "Enter your phone number to proceed" });
      return;
    } else {
      this.setState({ errorMessage: null, loading: true });
      this.validatePhone(); //comment this line if using captcha
      return; //comment if using captcha

      //eval(`grecaptcha.execute();`);
      eval(
        `var recaptchaResponse = grecaptcha.getResponse(window.recaptchaWidgetId);`
      );
      var _await = setInterval(() => {
        var token = sessionStorage.getItem("recaptchaToken");
        if (token) {
          this.setState({ recaptchaToken: token }, () => {
            clearInterval(_await);
            this.validatePhone();
          });
        }
      }, 200);
    }
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <PublicWithTopStripBackground
        children={
          <div className="col-lg-4 mx-auto">
            <div
              className="auth-form-light text-left px-3"
              style={{ borderRadius: "15px" }}
            >
              <div className="brand-logo">
              <Logo style={{width: "300px"}} />
              </div>
              <h4 className="text-darker">User Registration</h4>
              <h6 className="font-weight-light">
                Signing up is easy. It only takes a few steps
              </h6>
              <form className="pt-5">
                <div className="form-group">
                  <ReactPhoneInput
                    country={"ng"}
                    value={this.state.phoneNumber}
                    onChange={this.handlePhoneOnChange}
                  />
                </div>

                {this.state.errorMessage ? (
                  <div className="mb-3">
                    <div className="alert alert-danger small" role="alert">
                      {this.state.errorMessage}
                    </div>
                  </div>
                ) : null}

                <div className="mt-3">
                  {this.state.loading ? (
                    <button
                      type="button"
                      className="btn btn-block btn-light btn-lg font-weight-medium auth-form-btn"
                    >
                      <Spinner size="1" />{" "}
                    </button>
                  ) : (
                    <button
                      id="confirm-phone-button"
                      type="button"
                      onClick={() => this.submit()}
                      className="btn btn-rounded btn-block btn-success btn-lg font-weight-medium auth-form-btn"
                    >
                      NEXT
                    </button>
                  )}
                </div>
                <div className="text-center mt-4 font-weight-light">
                  {" "}
                  Already have an account?
                  <Link to={"/login"} className="text-primary">
                    Login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        }
      />
    );
  }
}

export default Register;
