import React from "react";
import "../sass/badge.scss";

export default function Badge({ label, theme, deleteAction }) {
  if (!theme) {
    theme = "primary";
  }

  return (
    <span className="my-badges">
      <span className={`badge badge-${theme} badge-closable`}>
        {label}
        {deleteAction && (
          <span
            className="close"
            onClick={() => deleteAction()}
            title="delete record"
          >
            <i className="fa fa-times"></i>
          </span>
        )}
      </span>
    </span>
  );
}
