import React, { Component } from "react";
import { Link } from "react-router-dom";
import PublicWithTopStripBackground from "../../Components/Layouts/PublicWithTopStripBackground";
import Logo from "../../Logo";

export default class RegistrationSuccesful extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
  }

  render() {
    return (
      <PublicWithTopStripBackground
        children={
          <div className="col-12 col-md-6  mx-auto ">
            <div className="auth-form-light text-center px-3">
              <div className="brand-logo">
                <Logo />
              </div>
              <h1>
                <i className="mdi mdi-account-check text-success"></i>
              </h1>
              <h4 className="text-darker">We just sent you an email</h4>
              <h5 className="font-weight-light mb-5 text-dark">
                Your registration was successful, <br />
                click the link we sent to you to activate your account and
                login.
              </h5>
            </div>
          </div>
        }
      />
    );
  }
}
