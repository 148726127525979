import React, { useState, useEffect } from "react";
import "../sass/setting.css";

function Setting({
  id,
  name,
  description,
  optionsList,
  value,
  handleChange,
  regexStatus,
}) {
  return (
    <div className="setting border-bottom p-4">
      <div className="setting__name text-left pt-4">
        <h5>{name}</h5>
        <p>{description}?</p>
      </div>
      <div className="setting__body">
        <div className="setting__options" style={{ maxWidth: "300px" }}>
          {optionsList?.length ? (
            <select
              defaultValue={value}
              onChange={(e) => handleChange(id, e.target.value)}
              name="option"
              className="form-control form-control-sm"
            >
              <option value=""></option>
              {optionsList?.map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
          ) : (
            <div className="input">
              <div>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  placeholder="type here..."
                  defaultValue={value}
                  onChange={(e) => handleChange(id, e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Setting;
