import React, { Component } from "react";
import { Link } from "react-router-dom";
import Constants from "../Utils/Constants";
import Menus from "../Utils/Menus";
import Functions from "../Utils/Functions";
import Spinner from "../Utils/Spinner";
import { DataContext } from "../Contexts/DataContexts";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      loading: false,
    };
  }

  static contextType = DataContext;

  componentDidMount() {
    const globalState = this.context.globalState;
    this.setState({ menus: Menus[globalState.user?.userRole.toLowerCase()] });
  }

  render() {
    return (
      <nav
        className={
          "sidebar sidebar-offcanvas" +
          (this.props.mobileMenuIsOpen ? " active" : "")
        }
        id="sidebar"
      >
        <ul className="nav">
          <li className="nav-item nav-category">Main</li>
          {this.state.loading ? (
            <li className="nav-item">
              <a className="nav-link" href="#">
                <span className="icon-bg">
                  <Spinner size="0.5" />
                </span>
                <span className="menu-title text-primary"></span>
              </a>
            </li>
          ) : null}
          {!this.state.menus
            ? null
            : this.state.menus.map((menu, index) => (
                <li className="nav-item" key={index}>
                  <Link className="nav-link" to={`/in${menu.url}`}>
                    <span className="icon-bg">
                      <i className={menu.icon + " menu-icon"} />
                    </span>
                    <span className="menu-title ">{menu.label}</span>
                  </Link>
                </li>
              ))}
          <li className="nav-item sidebar-user-actions">
            <hr />
          </li>
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <Link to={"/logout"} className="nav-link">
                <i className="mdi mdi-logout menu-icon" />
                <span className="menu-title">Log Out</span>
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}
