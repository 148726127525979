import React, { Component } from "react";
import CardBlock from "./CardBlock";
import Tabs from "../Utils/Tabs";
import CenterBlock from "./CenterBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../Utils/Constants";
import APICall from "../Utils/APICall";
import Spinner from "../Utils/Spinner";
import SpinnerButton from "../Utils/SpinnerButton";
import Toastr from "../Utils/Toastr";
import MyTable from "../Utils/MyTable";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import Functions from "../Utils/Functions";
import Select from "react-select";
import { throttle } from "lodash";

export default class InvestorsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      errorMessage: null,
      searchQuery: "",
      page: 1,
      pageSize: 10,
      modalIsOpen: false,
      uploadErrorMessage: null,
      uploading: false,
      redirect: null,
      user: {},
      alreadyGotResults: false,
    };

    this.RenderTable = this.RenderTable.bind(this);
    this.init = this.init.bind(this);

    this.throttledReload = throttle(() => {
      this.init(this.state.page, this.state.pageSize, this.state.searchQuery);
    }, 500);
  }

  componentDidMount() {
    Modal.setAppElement("body");
    this.init();
    //        new Functions().whoIsLoggedIn(this.setLoggedInUser, false);
  }

  RenderTable(_data) {
    let { selectRecord } = this.props;
    const headerList = [
      {
        Header: "Name",
        accessor: "investorNameComponent",
      },
      {
        Header: "Phone number",
        accessor: "phoneNumber",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Email",
        accessor: "emailAddress",
      },
    ];

    _data._data.forEach((element) => {
      element.investorNameComponent = (
        <strong className="clickable" onClick={() => selectRecord(element)}>
          {element.investorName}
        </strong>
      );
    });

    const tableHeader = React.useMemo(() => headerList, []);
    const tableData = React.useMemo(() => _data, []);
    return (
      <div style={{ zoom: "0.85" }}>
        <MyTable
          columns={tableHeader}
          data={tableData._data}
          enableSearch={true}
        />
      </div>
    );
  }

  init(page, pageSize, query) {
    if (!query) query = "";

    this.setState({
      errorMessage: null,
      loading: true,
      searchQuery: query,
      page: page,
      pageSize: pageSize,
    });

    APICall(`api/abattoirs/listInvestors/${this.props.abattoirId}`, "GET", {})
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ loading: false, data: null }, () => {
            this.setState({ data: response.data });
          });
        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Try again after sometime",
            loading: false,
          });
        }
        console.log(data);
      })
      .catch((error) => {
        this.setState({
          errorMessage:
            "Your request generated an error. Please check your network connection",
          loading: false,
        });
      });
  }

  render() {
    return this.state.redirect ? (
      <Redirect to={this.state.redirect} />
    ) : this.state.errorMessage ? (
      <CenterBlock height="150">
        <h1 className="text-danger icon-lg">
          <i className="mdi mdi-alert-circle"></i>
        </h1>
        <div className="text-dark text-center" role="alert">
          An error occurred, you can try reloading the page
        </div>
        <button
          type="button"
          onClick={() => this.init()}
          className="btn btn-inverse-light btn-fw mt-4 mb-5 btn-rounded"
        >
          Reload
        </button>
      </CenterBlock>
    ) : (
      <>
        {this.state.loading ? (
          <div className="text-success" style={{ position: "absolute" }}>
            <Spinner size="1.5" />{" "}
          </div>
        ) : null}

        {!this.state.data ? null : <this.RenderTable _data={this.state.data} />}
      </>
    );
  }
}
