import React, { Component } from "react";
import "../sass/steps-indicator.scss";
import { Link } from "react-router-dom";
import { setDefaultLocale } from "react-datepicker";

export default class StepsIndicator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: []
        };
    }

    componentDidMount() { 
        
    }

    render() {
        return (
            <div className="steps-indicator steps-container">
            <ul className="progressbar">
                {
                    this.props.steps.map((step, index)=>
                <li key={index} className={(this.props.currentStep >= (index+1))? "active": ""}>
                    <Link to={step.link} className="text-muted">{step.label}</Link></li>
                    )
                }
            </ul>
          </div>
        );
    }
}
