import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../Logo";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import SpinnerButton from "../../Utils/SpinnerButton";
import Constants from "../../Utils/Constants";

export default class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
    global.loggedInUser = null;
  }

  render() {
    return <Redirect to={"/login"} />;
  }
}
