import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import Modal from "react-modal";
import Select from "react-select";
import DataAPI from "../../Utils/DataAPI";
import Functions from "../../Utils/Functions";
import DiseaseList from "../../Components/DiseaseReporting/DiseaseList";
import NewDiseaseReport from "../../Components/DiseaseReporting/NewDiseaseReport";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import AbattoirsList from "../../Components/AbattoirsList";
import RanchAnimalMonthlyList from "../../Components/RanchAnimalMonthlyList";

export default class FetchAnimalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diseaseListLoading: false,
      loading: false,
      data: null,
      errorMessage: null,
      step: 1,
      currentCheckin: {},
      distributors: [],
      redirectUrl: null,
      payload: {},
      investors: [],
      validateLoading: false,
      animal: null,
      animalSlaughterInfo: null,
      modalIsOpen: false,
      destinations: [],
    };

    this.formControl = this.formControl.bind(this);
    this.resetDiseaseList = this.resetDiseaseList.bind(this);
  }


  data = {
    labels: ["1", "2", "3", "4", "5", "6"],
    datasets: [
      {
        label: "# of Red Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "# of Blue Votes",
        data: [2, 3, 20, 5, 1, 4],
        backgroundColor: "rgb(54, 162, 235)",
      },
    ],
  };

  options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.9,
          categoryPercentage: 0.2,
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  doughNutOptions = {
    legend: {
      display: false,
    },
  };
  doughNutData = {
    labels: [
      "Abattoir 1",
      "Abattoir 2",
      "Abattoir 3",
      "Abattoir 4",
      "Abattoir 5",
      "Abattoir 6",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  lineData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        fill: true,
        backgroundColor: "rgb(131, 183, 57)",
        borderColor: "rgba(0, 110, 71, 0.45)",
      },
    ],
  };

  lineOptions = {
    elements: {
      point: {
        radius: 0,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [
        {
          display: false,
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  componentDidMount() {
    // this.getLastCheckin();
    Modal.setAppElement("body");
  }

  async validateTagNumber() {
    this.setState({ validateLoading: true, animal: null });
    let animal = await DataAPI.findAnimal(this.state.payload?.tagNumber);
    if (animal) {
      this.setState({ animalSlaughterInfo: animal });
      Toastr("success", "Animal's delivery has already been taking ");

      // this.setState({ animal: animal });
      // this.updateProp(animal.specie, "animalSpecie");
      // this.updateProp(
      //   this.state.payload.tagNumber.toUpperCase(),
      //   "identificationMark"
      // );
    } else {
      console.log("error", "Invalid animal tag number");
    }
    this.setState({ validateLoading: false });
  }



  async fetchBirthInformation() {
    this.setState({ validateLoading: true, animal: null });
    let animal = await DataAPI.findAnimalBirthInformation(this.state.payload?.tagNumber);
    if (animal) {
      this.setState({ animal: animal });
      this.updateProp(animal.specie, "animalSpecie");
      this.updateProp(
        this.state.payload.tagNumber.toUpperCase(),
        "identificationMark"
      );
    } else {
      Toastr("error", "Invalid animal tag number");
    }
    this.setState({ validateLoading: false });
  }
  // async fetchMonthlyRecord() {
  //   this.setState({ validateLoading: true, animal: null });
  //   let animal = await DataAPI.findAnimalMonthlyRecords(this.state.payload?.tagNumber);
  //   if (animal) {
  //     // this.setState({ animal: animal });
  //     // this.updateProp(animal.specie, "animalSpecie");
  //     // this.updateProp(
  //     //   this.state.payload.tagNumber.toUpperCase(),
  //     //   "identificationMark"
  //     // );
  //   } else {
  //     Toastr("error", "Invalid animal tag number");
  //   }
  //   this.setState({ validateLoading: false });
  // }

  addDestination = () => {
    let destinations = this.state.destinations;
    if (!destinations) destinations = [];

    if (this.state.newDestination && this.state.newDestination.trim()) {
      if (
        destinations.find(
          (a) =>
            a.toLowerCase() == this.state.newDestination.trim().toLowerCase()
        )
      ) {
        return;
      }
      destinations.push(this.state.newDestination.trim());
      this.setState({ destinations: destinations, newDestination: "" });
    }
  };

  removeDestination = (destination) => {
    let _destinations = this.state.destinations.filter((a) => a != destination);
    this.setState({ destinations: _destinations });
  };

  advanceStep1 = async () => {
    if (
      !this.state.payload?.animalSpecie ||
      !this.state.payload?.beefWeightInKg
    ) {
      Toastr("error", "Some required fields are not filled");
      return;
    }

    if (await this.checkWeight()) {
      this.setState({ step: 2 });
    }
  };

  getLastCheckin() {
    APICall("api/vets/lastcheckin", "get")
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          if (response.data) {
            this.setState({ currentCheckin: response.data }, () => {
              //investors are tied to abattoirs
              this.getInvestors();
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkWeight = async () => {
    if (!this.state.animal?.tagNumber || !this.state.payload?.beefWeightInKg) {
      Toastr("error", "Check that form is properly filled");
      return false;
    }

    let weightOk = false;
    await APICall(
      `api/certificates/checkweight/${this.state.animal.tagNumber}/${this.state.payload.beefWeightInKg}`,
      "post"
    )
      .then((data) => {
        if (data.data.isSuccess) {
          weightOk = true;
        } else {
          Toastr("error", data.data.message);
        }
      })
      .catch((error) => {
        Toastr("error", "Unable to continue, check your network");
      });

    return weightOk;
  };

  getInvestors() {
    APICall(
      `api/abattoirs/listInvestors/${this.state.currentCheckin.id}`,
      "get"
    )
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          if (response.data) {
            response.data.forEach((element) => {
              element.value = element.id;
              element.label = element.investorName;
            });
            this.setState({ investors: response.data });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  issueCertificate() {
    if (!this.state.destinations || this.state.destinations.length <= 0) {
      this.setState({
        loading: false,
        errorMessage: "Add at least one destination",
      });
      return;
    }
    let payload = this.state.payload;
    payload.destinations = this.state.destinations;
    payload.abattoirId = this.state.currentCheckin.id;
    payload.numberOfParts = parseInt(payload.numberOfParts) || 1;
    payload.beefWeightInKg = parseFloat(payload.beefWeightInKg);

    if (payload.paymentChannel?.toLowerCase() == "card") {
      payload.receiptNumber = "";
    }

    this.setState({ loading: true, errorMessage: null });
    APICall("api/certificates/issue", "post", payload)
      .then((data) => {
        if (data.data.isSuccess) {
          Toastr("success", "Certificate issued successfully");
          this.setState({
            redirectUrl: "/in/new-certificate-success/" + data.data.data.code,
          });
        } else {
          this.setState({ loading: false, errorMessage: data.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          errorMessage: "Check your network connection",
        });
      });
  }

  modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "visible",
    },
  };

  updateProp(value, prop) {
    let payload = this.state.payload;
    if (!payload) payload = {};
    payload[prop] = value;
    this.setState({ payload: payload });
  }

  handleChange(option) {
    let payload = this.state.payload;
    if (!payload) payload = {};
    payload.distributorId = option.id;
    this.setState({ payload: payload });
  }

  formGroupRow({ colClass, children, extraChildren }) {
    return (
      <div className="form-group row">
        <div className={colClass}>{children}</div>
        {extraChildren && <div className={colClass}>{extraChildren}</div>}
      </div>
    );
  }

  formControl({ label, prop, inputType, required }) {
    if (!inputType) inputType = "text";
    let payload = this.state.payload;
    if (!payload) payload = {};

    return (
      <>
        <label className="small control-label font-weight-bold text-darker">
          {label} {required && <small className="text-danger">*</small>}
        </label>
        <input
          type={inputType}
          className="form-control form-control-sm"
          placeholder=""
          defaultValue={payload[prop]}
          onChange={(e) => this.updateProp(e.target.value, prop)}
        />
      </>
    );
  }

  resetDiseaseList() {
    this.setState({ modalIsOpen: false, diseaseListLoading: true }, () => {
      setTimeout(() => {
        this.setState({ diseaseListLoading: false });
      }, 1000);
    });
  }

  singleAnimalItem = ({ label, prop }) => {
    return (
      <>
        <p className="small">
          <strong className="text-muted">{label}</strong>
          <br />
          {this.state.animal[prop]}
          <hr />
        </p>
      </>
    );
  };

  animalBirthInformationDisplay = () => {
    return (
      <div className="row text-dark">
        <div className="col-6">
          {this.singleAnimalItem({ label: "Specie", prop: "specie" })}
        </div>
        <div className="col-6 text-right">
          {this.singleAnimalItem({ label: "Sub-specie", prop: "subSpecie" })}
        </div>

        <div className="col-6">
          {this.singleAnimalItem({ label: "Color", prop: "color" })}
        </div>
        <div className="col-6 text-right">
          {this.singleAnimalItem({ label: "Gender", prop: "gender" })}
        </div>
        <div className="col-6 ">
          <>
            <p className="small">
              <strong className="text-muted">Weight at Birth</strong>
              <br />
              {`${this.state.animal["weightAtBirth"]}kg`}              <hr />
            </p>
          </>
        </div>
        <div className="col-6 text-right">
          <>
            <p className="small">
              <strong className="text-muted">Weight at Slaughter</strong>
              <br />
              {this.state?.animalSlaughterInfo?.weight ? `${this.state?.animalSlaughterInfo?.weight}kg` : "-"}
              <hr />
            </p>
          </>
        </div>

        <div className="col-6">
          <>
            <p className="small">
              <strong className="text-muted">Date of Birth</strong>
              <br />

              {Functions.sqlDateString(this.state.animal["dateOfBirth"])}
              <hr />
            </p>
          </>
        </div>
        <div className="col-6 text-right">
          <>
            <p className="small">
              <strong className="text-muted">Date of Slaughter</strong>
              <br />
              {this.state?.animalSlaughterInfo?.dateCreated ? Functions.sqlDateString(this.state?.animalSlaughterInfo?.dateCreated) : "-"}
              <hr />
            </p>
          </>
        </div>


        <div className="col-6">
          <>
            <p className="small">
              <strong className="text-muted">State of Origin</strong>
              <br />
              {this.state.animal.user.state}
              <hr />
            </p>
          </>        </div>
        <div className="col-6 text-right">
          <>
            <p className="small">
              <strong className="text-muted">Animal Owner</strong>
              <br />
              {this.state.animal.user.name}
              <hr />
            </p>
          </>
        </div>
      </div>
    );
  };

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <>
        <MainLayout title="Fetch Animal Information">
          <Modal
            isOpen={this.state.modalIsOpen}
            contentLabel=""
            style={Constants.defaultModalStyle}
            onRequestClose={() => this.resetDiseaseList()}
          >
            <div
              className="mymodal-wrapper"
              style={{ maxHeight: "450px", overflowY: "scroll" }}
            >
              <NewDiseaseReport
                returnAction={() => this.resetDiseaseList()}
                tagNumber={this.props.tagNumber || this.state.payload?.tagNumber}
              />
            </div>
          </Modal>
          <div className="row">
            <div className="col-md-12 mb-5">
              {this.state.step == 1 && (
                <CardBlock title="">
                  <div className="row">
                    <div className="col-md-12 mb-0">
                      <form className="forms-sample">
                        <div className="form-group row">
                          <div className="col col-md-6 mx-auto mt-3">
                            <>
                              <label className="control-label font-weight-bold text-darker">
                                Animal Tag Number
                              </label>
                              <div className="input-group input-group-sm mb-3">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.updateProp(e.target.value, "tagNumber")
                                  }
                                  className="form-control"
                                />
                                <div className="input-group-append">
                                  {this.state.validateLoading ? (
                                    <Spinner />
                                  ) : (
                                    <button
                                      onClick={() => {
                                        this.validateTagNumber();
                                        this.fetchBirthInformation()
                                      }}
                                      className="btn btn-success"
                                      type="button"
                                    >
                                      Fetch Animal
                                    </button>
                                  )}
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                        {this.state.animal && (
                          <>
                            <div className="form-group row">
                              <div className="col-12 col-md-8 col-lg-8 mx-auto">
                                <h5 className="theme-color mb-4 mt-4">
                                  Animal Birth Information
                                </h5>
                                {this.animalBirthInformationDisplay()}
                              </div>
                            </div>
                            <hr style={{ visibility: "hidden" }} />













                            <div className="row">
                              {null && (
                                <>
                                  <div
                                    style={{ zoom: "0.85", marginTop: "-20px" }}
                                    className="mb-3 col"
                                  >
                                    <div className="form-group row">
                                      <div className="col col-md-3">
                                        <label className="control-label font-weight-bold text-darker">
                                          Date from
                                        </label>
                                        <DatePickerInput
                                          defaultValue={this.state.fromDate}
                                          onChange={(date) =>
                                            this.setState({ fromDate: date }, () => {
                                              this.quickReload();
                                            })
                                          }
                                          className="my-custom-datepicker-component"
                                        />
                                      </div>
                                      <div
                                        className="col text-center"
                                        style={{ maxWidth: "20px" }}
                                      >
                                        <label
                                          style={{ visibility: "hidden" }}
                                          className="control-label font-weight-bold text-darker"
                                        >
                                          -
                                        </label>
                                        <label className="control-label font-weight-bold text-darker mt-2">
                                          _
                                        </label>
                                      </div>
                                      <div className="col col-md-3">
                                        <label className="control-label font-weight-bold text-darker">
                                          Date to
                                        </label>
                                        <DatePickerInput
                                          defaultValue={this.state.toDate}
                                          onChange={(date) =>
                                            this.setState({ toDate: date }, () => {
                                              this.quickReload();
                                            })
                                          }
                                          className="my-custom-datepicker-component"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <br style={{ clear: "both" }} />
                                </>
                              )}
                              <div className="col-12 grid-margin">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-lg-3 col-sm-4 grid-margin  grid-margin-lg-0">
                                        <div className="wrapper pb-5 border-bottom">
                                          <div className="chartjs-size-monitor">
                                            <div className="chartjs-size-monitor-expand">
                                              <div className />
                                            </div>
                                            <div className="chartjs-size-monitor-shrink">
                                              <div className />
                                            </div>
                                          </div>
                                          <div className="text-wrapper d-flex align-items-center justify-content-between mb-0">
                                            <p className="mb-0 text-dark">
                                              Animal Growth  Chart
                                            </p>
                                          </div>
                                          <h3 className="mb-3 text-dark font-weight-bold mt-0">
                                            10k{" "}
                                            <small
                                              className="text-muted font-weight-normal"
                                              style={{ fontSize: "75%" }}
                                            >
                                              animals
                                            </small>
                                          </h3>
                                          <div
                                            id="total-profit"
                                            style={{
                                              display: "block",
                                              width: "216px",
                                              height: "108px",
                                            }}
                                            className="chartjs-render-monitor"
                                          >
                                            <Doughnut
                                              data={this.doughNutData}
                                              options={this.doughNutOptions}
                                            />
                                          </div>
                                        </div>
                                        <div className="wrapper pt-5">
                                          <div className="chartjs-size-monitor">
                                            <div className="chartjs-size-monitor-expand">
                                              <div className />
                                            </div>
                                            <div className="chartjs-size-monitor-shrink">
                                              <div className />
                                            </div>
                                          </div>
                                          <div className="text-wrapper d-flex align-items-center justify-content-between mb-0">
                                            <p className="mb-0 text-dark">Income</p>
                                          </div>
                                          <h3 className="mb-4 text-dark font-weight-bold">
                                            <small>YTD:</small> 59,565.00
                                          </h3>
                                          <div
                                            id="total-expences"
                                            style={{
                                              display: "block",
                                              width: "216px",
                                              height: "108px",
                                            }}
                                            className="chartjs-render-monitor"
                                          >
                                            <Line
                                              data={this.lineData}
                                              options={this.lineOptions}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-9 col-sm-8 grid-margin  grid-margin-lg-0">
                                        <div className="pl-0 pl-lg-4 ">
                                          <div className="chartjs-size-monitor">
                                            <div className="chartjs-size-monitor-expand">
                                              <div className />
                                            </div>
                                            <div className="chartjs-size-monitor-shrink">
                                              <div className />
                                            </div>
                                          </div>
                                          <div className="d-xl-flex justify-content-between align-items-center mb-2">
                                            <div className="d-lg-flex w-100">
                                              <p className="text-dark font-weight-bold mb-4 text-right w-100">
                                                Healthy vs Diseased animals
                                              </p>
                                            </div>
                                          </div>
                                          <div
                                            className="graph-custom-legend clearfix"
                                            id="device-sales-legend"
                                          >
                                            <ul className="2-legend">
                                              <li>
                                                <span
                                                  className="legend-box"
                                                  style={{ background: "#36A2EB" }}
                                                />
                                                <span className="legend-label text-dark">
                                                  Healthy
                                                </span>
                                              </li>
                                              <li>
                                                <span
                                                  className="legend-box"
                                                  style={{ background: "#FF6384" }}
                                                />
                                                <span className="legend-label text-dark">
                                                  Diseased
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                          <div
                                            id="device-sales"
                                            style={{
                                              display: "block",
                                              width: "661px",
                                              height: "330px",
                                            }}
                                            className="chartjs-render-monitor"
                                          >
                                            <Bar
                                              data={this.state.diseaseDistributionData}
                                              options={this.options}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
















                            <div className="row ">
                              <div className="col-md-12 mb-5">

                                <CardBlock>
                                  {this.state.reloading ? (
                                    <Spinner />
                                  ) : (
                                    <>

                                      <div className="flex">
                                        <h3>Animal Monthly Record</h3>
                                      </div>

                                      <RanchAnimalMonthlyList
                                        selectRecord={(r) => this.setSelectedRecord(r)}
                                        animalTag={this.state.payload?.tagNumber}
                                      />
                                    </>
                                  )}
                                </CardBlock>
                              </div>
                            </div>






                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </CardBlock>
              )}
              {this.state.step == 2 && (
                <CardBlock title="">
                  <div className="row">
                    <div className="col-md-12 mb-0">
                      <form className="forms-sample">
                        <div className="form-group row">
                          <div className="col col-md-6">
                            <h4 className="theme-color">Disease Reporting</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col pt-0" >
                            <div className="mb-4">
                              <button
                                type="button"
                                onClick={() =>
                                  this.setState({ modalIsOpen: true })
                                }
                                className="btn btn-danger btn-xs mb-4 btn-rounded"
                              >
                                + New Disease Report
                              </button>
                              <hr style={{ clear: "both" }} />
                            </div>

                            {this.state.diseaseListLoading ? (
                              <Spinner />
                            ) : (
                              <DiseaseList
                                tagNumber={
                                  this.props.tagNumber ||
                                  this.state.payload?.tagNumber
                                }
                              />
                            )}
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col mt-4">
                            <div className="row">
                              <div className="col">
                                <button
                                  type="button"
                                  onClick={() => this.setState({ step: 1 })}
                                  className="btn btn-link"
                                >
                                  {"< "} Back
                                </button>
                              </div>
                              <div className="col text-right">
                                <button
                                  type="button"
                                  onClick={() => this.setState({ step: 3 })}
                                  className="btn btn-link"
                                >
                                  Next {" >"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </CardBlock>
              )}
              {this.state.step == 3 && (
                <CardBlock title="">
                  <div className="row">
                    <div className="col-md-12 mb-0">
                      <form className="forms-sample">
                        <div className="form-group row">
                          <div className="col col-md-6">
                            <h4 className="theme-color">Details of Journey</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mx-auto">
                            <div className="form-group row">
                              <div className="col">
                                <label className="control-label font-weight-bold text-darker">
                                  Approved slaughter house
                                </label>
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control form-control-sm"
                                  placeholder={this.state.currentCheckin.name}
                                  defaultValue={this.state.currentCheckin.name}
                                />
                              </div>
                            </div>

                            <this.formGroupRow
                              colClass="col"
                              children={
                                <>
                                  <label className="control-label font-weight-bold text-darker">
                                    Veterinarian's Comments
                                  </label>
                                  <textarea
                                    defaultValue={this.state.payload.comments}
                                    className="form-control form-control-sm"
                                    placeholder="Optional comments by vet"
                                    onChange={(e) =>
                                      this.updateProp(e.target.value, "comments")
                                    }
                                  />
                                </>
                              }
                            />

                            <div className="form-group row">
                              <div className="col-12">
                                <label className="control-label font-weight-bold text-darker">
                                  Meat is dispatched to
                                </label>
                                <div className="input-group mb-3">
                                  <input
                                    type="text"
                                    value={this.state.newDestination}
                                    className="form-control form-control-sm"
                                    placeholder="Place and state of destination"
                                    onChange={(e) =>
                                      this.setState({
                                        newDestination: e.target.value,
                                      })
                                    }
                                  />
                                  <div className="input-group-append">
                                    <button
                                      onClick={() => this.addDestination()}
                                      className="btn btn-dark"
                                      type="button"
                                    >
                                      + Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {this.state.destinations &&
                                this.state.destinations.length > 0 && (
                                  <div className="col-12 mt-3">
                                    {this.state.destinations.map(
                                      (destination, index) => (
                                        <p
                                          key={index}
                                          className="text-dark border-bottom pb-1 mb-1"
                                        >
                                          <i
                                            onClick={() =>
                                              this.removeDestination(destination)
                                            }
                                            className="text-danger clickable mdi mdi-close mr-2"
                                          ></i>{" "}
                                          {destination}
                                        </p>
                                      )
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        {this.state.errorMessage ? (
                          <div className="mb-3">
                            <div
                              className="alert alert-danger small"
                              role="alert"
                            >
                              {this.state.errorMessage}
                            </div>
                          </div>
                        ) : null}

                        <div className="form-group row">
                          <div className="col mt-4">
                            <div className="row">
                              <div className="col">
                                <button
                                  onClick={() => this.setState({ step: 1 })}
                                  className="btn btn-link"
                                >
                                  {"< "} Back
                                </button>
                              </div>
                              <div className="col text-right">
                                <SpinnerButton
                                  onClick={() => this.issueCertificate()}
                                  className="btn btn-success btn-rounded"
                                  label="Issue Certificate"
                                  loading={this.state.loading}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </CardBlock>
              )}
            </div>
          </div>
        </MainLayout>
      </>
    );
  }
}
