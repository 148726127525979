import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import UserBio from "../../Components/UserBio";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import Functions from "../../Utils/Functions";
import { DataContext } from "../../Contexts/DataContexts";

export default class SingleUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabHeaders: [],
      tabContents: [],
      errorMessage: null,
      loading: true,
      loggedInUser: {},
    };

    this.init = this.init.bind(this);
  }

  static contextType = DataContext;

  componentDidMount() {
    this.preInit();
  }

  preInit() {
    this.setState({ loading: true, errorMessage: null });
  }

  init() {
    this.setState({ loading: true, errorMessage: null });
    APICall(
      `api/users/getbyusername/${this.props.match.params.userName}`,
      "GET"
    )
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ loading: false }, () => {
            this.fixPageTab(response.data);
          });
        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Try again after sometime",
            loading: false,
          });
        }
        console.log(data);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Please check your network connection",
          loading: false,
        });
      });
  }

  fixPageTab(user) {
    var _tabHeaders = [];
    var _tabContents = [];

    _tabHeaders.push({ icon: "", text: "Profile" });
    _tabContents.push(
      <CardBlock title="Profile">
        <UserBio userId={user.id} />
      </CardBlock>
    );

    this.setState({
      tabHeaders: _tabHeaders,
      tabContents: _tabContents,
      user: user,
    });
  }

  render() {
    return (
      <MainLayout
        title={
          this.context.globalState.user?.name ||
          this.props.match.params.userName
        }
      >
        {this.state.errorMessage ? (
          <CenterBlock height="150">
            <h1 className="text-danger icon-lg">
              <i className="mdi mdi-alert-circle"></i>
            </h1>
            <div className="text-dark text-center" role="alert">
              {this.state.errorMessage}
            </div>
            <button
              type="button"
              onClick={() => this.preInit()}
              className="btn btn-inverse-success btn-fw mt-4 mb-5"
            >
              Reload
            </button>
          </CenterBlock>
        ) : this.state.loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : this.context.globalState.user.id ? (
          <div className="row">
            <div className="col-md-12">
              <Tabs
                headers={this.state.tabHeaders}
                contents={this.state.tabContents}
              />
            </div>
          </div>
        ) : null}
      </MainLayout>
    );
  }
}
