import React, { Component } from "react";
import CardBlock from "../CardBlock";
import CenterBlock from "../CenterBlock";
import { Link } from "react-router-dom";
import Spinner from "../../Utils/Spinner";
import Functions from "../../Utils/Functions";
import APICall from "../../Utils/APICall";
import Constants from "../../Utils/Constants";

export default class DistributorDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorMessage: null,
      data: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true, errorMessage: null });

    APICall("api/dashboard/distributor", "GET", null)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState(
            { data: response.data, errorMessage: null, loading: false },
            () => {
              localStorage.setItem("dashboard", JSON.stringify(response.data));
            }
          );
        } else {
          this.setState({
            errorMessage: response.message,
            loading: false,
            data: null,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  statBox(prop) {
    return (
      <div className="col-xl-4 col-lg-4 col-sm-6 grid-margin stretch-card">
        <div className="w-100 card rounded">
          <Link to={prop.link} className="no-decoration">
            <div className="card-body text-center compact py-4">
              <h5 className="mb-2 font-weight-normal">{prop.title}</h5>
              <h2 className="mb-0 text-dark font-weight-bold">{prop.data}</h2>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12 mb-3 mt-0">
          {this.state.loading && !this.state.data ? (
            <CenterBlock height="150">
              <Spinner size="2" />
            </CenterBlock>
          ) : this.state.errorMessage ? (
            <CenterBlock height="150">
              <h1 className="text-danger">
                <i className="mdi mdi-exclamation"></i>
              </h1>
              <div className="text-danger small" role="alert">
                {this.state.errorMessage}
              </div>
              <button
                type="button"
                onClick={() => this.loadData()}
                className="btn btn-inverse-light btn-fw mt-4 btn-rounded"
              >
                Reload
              </button>
            </CenterBlock>
          ) : this.state.data ? (
            <>
              <div className="row ">
                <div className="col-12 mb-4">
                  <br />
                  <h5>
                    <span
                      className="font-weight-bold"
                      style={{ textTransform: "uppercase" }}
                    >
                      {this.state.data.distributor.companyName}
                    </span>
                  </h5>
                </div>
                <div className="col-12">
                  <div className="row">
                    <this.statBox
                      link={"/in/my-certificates"}
                      title="Certificates Issued"
                      data={this.state.data.certificatesCount}
                    />
                    <this.statBox
                      link={"/#"}
                      title="Total Amount Paid"
                      data={Functions.currencyFormat(
                        this.state.data.certificatesAmount
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col mt-3">
                  <div className="pt-2 text-left">
                    <Link
                      to={"/in/my-certificates"}
                      className="btn btn-dark btn-fw  btn-rounded"
                    >
                      View Certificates
                    </Link>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
