import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import StepsIndicator from "../../Components/StepsIndicator";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import APICall from "../../Utils/APICall";

export default class PasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
      redirect: null,
    };
  }

  componentDidMount() {}

  submit() {
    if (
      !this.state.data ||
      this.state.data.newPassword != this.state.data.retypePassword
    ) {
      Toastr("error", "New password and confirmation must match");
      return;
    }

    this.setState({ errorMessage: null, loading: true });
    APICall("api/users/changepassword", "POST", this.state.data)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          Toastr("success", "Password changed, redirecting ... ");
          setTimeout(() => {
            this.setState({ redirect: "/logout" });
          }, 5000);
        } else {
          Toastr("error", response.message);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        Toastr("error", "An error occurred, please check your network");
        this.setState({ loading: false });
      });
  }

  updateProp(e, prop) {
    var record = this.state.data;
    record[prop] = e.target.value;
    this.setState({ data: record });
  }

  render() {
    return this.state.redirect ? (
      <Redirect to={this.state.redirect} />
    ) : (
      <MainLayout title="Change Password">
        <div className="row">
          <div className="col-md-12 mb-5">
            <CardBlock title="" description="">
              <div className="form-group row">
                <label className="col-4 col-md-2 col-form-label text-dark">
                  Old Password
                </label>
                <div className="col-8 col-md-6 col-lg-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Old Password"
                    onChange={(e) => this.updateProp(e, "oldPassword")}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-md-2 col-form-label text-dark">
                  New Password
                </label>
                <div className="col-8 col-md-6 col-lg-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="New Password"
                    onChange={(e) => this.updateProp(e, "newPassword")}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-md-2 col-form-label text-dark">
                  Confirm New Password
                </label>
                <div className="col-8 col-md-6 col-lg-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm New Password"
                    onChange={(e) => this.updateProp(e, "retypePassword")}
                  />
                </div>
              </div>

              <SpinnerButton
                label="Change Password"
                className="btn btn-success btn-fw"
                loading={this.state.loading}
                onClick={() => this.submit()}
              />
            </CardBlock>
          </div>
        </div>
      </MainLayout>
    );
  }
}
