import React, { Component } from "react";
import CardBlock from "../../Components/CardBlock";
import MainLayout from "../../Components/Layouts/MainLayout";
import UsersList from "../../Components/UsersList";
import Constants from "../../Utils/Constants";
import Select from "react-select";
import SpinnerButton from "../../Utils/SpinnerButton";
import APICall from "../../Utils/APICall";
import Toastr from "../../Utils/Toastr";
import Modal from "react-modal";
import AbattoirsList from "../../Components/AbattoirsList";
import DataAPI from "../../Utils/DataAPI";
import Spinner from "../../Utils/Spinner";
import { CountryAndRegions } from "../../Utils/CountryAndRegions";
import { DataContext } from "../../Contexts/DataContexts";

export default class Abattoirs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      loading: false,
      saveErrorMessage: null,
      payload: {},
      states: [],
      countryy: "",
      statee: "",
      banks: [],
      selectedRecord: null,
      reloading: false,
      userRole: "superadmin",
    };

    this.newRecordForm = this.newRecordForm.bind(this);
    this.updateRecordForm = this.updateRecordForm.bind(this);
  }



  componentDidMount() {
    this.init();


  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.country !== this.state.payload?.country) {
      const countryName = this.state.payload?.country;
      const states = this.getSubdivisionsByCountry(countryName);
      if (states !== this.state.states) {
        this.setState({ states: states });
      }
    }

  }

  getSubdivisionsByCountry(countryName) {
    const country = CountryAndRegions.find(item => item.label === countryName);
    if (country) {
      return country.subdivisions;
    } else {
      return [];
    }
  }



  updatePayload(value, index, forUpdate) {
    if (!forUpdate) {
      let payload = this.state.payload;
      payload[index] = value;
      this.setState({ payload: payload });
    } else {
      let payload = this.state.selectedRecord;
      payload[index] = value;
      this.setState({ selectedRecord: payload });
    }
  }

  static contextType = DataContext;

  async init() {

    const { globalState } = this.context

    this.setState({ profile: globalState?.data })

    const userRole = globalState?.user?.userRole
    const state = globalState?.user?.state
    const country = globalState?.user?.country

    this.setState({ userRole: userRole })
    this.setState({ countryy: country })
    this.setState({ statee: state })

    let banksList = await DataAPI.banksList();

    banksList.forEach((element) => {
      element.label = element.bankName;
      element.value = element.bankCode;
    })

    this.setState({ banks: banksList });

  }

  newRecordForm() {
    return (
      <div className="mymodal-wrapper" style={{ width: "450px" }}>
        <h5 className="theme-color">ADD ABATTOIR</h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Abattoir name"
                onChange={(e) => {
                  this.updatePayload(e.target.value, "name")
                  this.updatePayload(this.state.countryy, "country")
                  this.updatePayload(this.state.statee, "state")
                }}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Registration/approval number"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "approvalNumber")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <textarea
                placeholder="Operating address"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "operatingAddress")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Phone number"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "contactPhone")
                }
                className="form-control form-control-sm"
              />
            </div>
            <div className="col">
              <input
                type="email"
                placeholder="Email address"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "emailAddress")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          {(this.state.userRole == "superadmin")
            &&
            <div className="form-group row">
              <div className="col">
                <Select
                  options={CountryAndRegions}
                  styles={Constants.selectStyles}
                  onChange={(option) =>
                    this.updatePayload(option.value, "country")
                  }
                />
              </div>
              <div className="col">
                <Select
                  options={this.state.states}
                  styles={Constants.selectStyles}
                  onChange={(option) =>
                    this.updatePayload(option.value, "state")
                  }
                />
              </div>
            </div>
          }



          <hr style={{ visibility: "hidden" }} />
          <p className="theme-color font-weight-bold">
            Designated bank account for abattoir services revenue
          </p>
          <div className="form-group row">
            <label className="control-label col-4 text-dark pt-3 text-right">
              Account number
            </label>
            <div className="col">
              <input
                type="text"
                placeholder="Account Number"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "accountNumber")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-4 text-dark pt-3 text-right">
              Bank
            </label>
            {console.log(this.state.banks)}
            <div className="col">
              <Select
                styles={Constants.selectStyles}
                options={this.state.banks}
                onChange={(option) =>
                  this.updatePayload(option.value, "bankCode")
                }
              />
            </div>
          </div>
          {this.state.saveErrorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {this.state.saveErrorMessage}
              </div>
            </div>
          ) : null}
          <SpinnerButton
            onClick={() => this.addRecord()}
            className="btn btn-success mr-2 btn-block mb-3 mt-3 btn-rounded"
            label="Add Abattoir"
            loading={this.state.loading}
          />
        </form>
      </div>
    );
  }

  updateRecordForm() {
    return (
      <div className="mymodal-wrapper" style={{ width: "450px" }}>
        <h5 className="theme-color">UPDATE ABATTOIR</h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                defaultValue={this.state.selectedRecord.name}
                placeholder="Abattoir name"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "name", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                defaultValue={this.state.selectedRecord.approvalNumber}
                placeholder="Registration/approval number"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "approvalNumber", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <textarea
                defaultValue={this.state.selectedRecord.operatingAddress}
                placeholder="Operating address"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "operatingAddress", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                defaultValue={this.state.selectedRecord.contactPhone}
                placeholder="Contact Phone number"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "contactPhone", true)
                }
                className="form-control form-control-sm"
              />
            </div>
            <div className="col">
              <input
                type="email"
                defaultValue={this.state.selectedRecord.emailAddress}
                placeholder="Email address"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "emailAddress", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <hr style={{ visibility: "hidden" }} />
          <p className="theme-color font-weight-bold">
            Designated bank account for abattoir services revenue
          </p>
          <div className="form-group row">
            <label className="control-label col-4 text-dark pt-3 text-right">
              Account number
            </label>
            <div className="col">
              <input
                type="text"
                defaultValue={this.state.selectedRecord.accountNumber}
                placeholder="Account Number"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "accountNumber", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-4 text-dark pt-3 text-right">
              Bank
            </label>
            <div className="col">
              <Select
                styles={Constants.selectStyles}
                options={this.state.banks}
                value={this.state.banks.find(
                  (a) => a.bankCode == this.state.selectedRecord.bankCode
                )}
                onChange={(option) =>
                  this.updatePayload(option.value, "bankCode", true)
                }
              />
            </div>
          </div>

          {this.state.saveErrorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {this.state.saveErrorMessage}
              </div>
            </div>
          ) : null}

          <SpinnerButton
            onClick={() => this.updateRecord()}
            className="btn btn-success mr-2 btn-block mb-3 mt-3 btn-rounded"
            label="Update Record"
            loading={this.state.loading}
          />
        </form>
      </div>
    );
  }

  addRecord() {
    this.setState({ loading: true, saveErrorMessage: null });
    APICall("api/abattoirs/add", "POST", this.state.payload)
      .then((data) => {
        var response = data.data;
        this.setState({ loading: false });
        if (response.isSuccess) {
          Toastr("success", "New record added");
          this.setState({ modalIsOpen: false, payload: {} });
        } else {
          this.setState({ saveErrorMessage: response.message });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          saveErrorMessage: "An error occurred, please check your network",
        });
      });
  }

  updateRecord() {
    this.setState({ loading: true, saveErrorMessage: null });
    APICall(
      `api/abattoirs/update/${this.state.selectedRecord.id}`,
      "POST",
      this.state.selectedRecord
    )
      .then((data) => {
        var response = data.data;
        this.setState({ loading: false });
        if (response.isSuccess) {
          Toastr("success", "Record updated");
          this.setState({ selectedRecord: null });
          this.reloadPage();
        } else {
          this.setState({ saveErrorMessage: response.message });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          saveErrorMessage: "An error occurred, please check your network",
        });
      });
  }

  selectRecord(record) {
    this.setState({ selectedRecord: record });
  }

  reloadPage() {
    this.setState({ reloading: true }, () => {
      setTimeout(() => {
        this.setState({ reloading: false });
      }, 1000);
    });
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          contentLabel=""
          style={Constants.defaultModalStyle}
          onRequestClose={() =>
            this.setState({ modalIsOpen: false, payload: {} })
          }
        >
          <this.newRecordForm />
        </Modal>
        <Modal
          isOpen={this.state.selectedRecord ? true : false}
          contentLabel=""
          style={Constants.defaultModalStyle}
          onRequestClose={() => this.setState({ selectedRecord: null })}
        >
          {this.state.selectedRecord && <this.updateRecordForm />}
        </Modal>
        <MainLayout
          title={
            <>
              Abattoirs{" "}
              <button
                onClick={() =>
                  this.setState({ modalIsOpen: true, payload: {} })
                }
                className="btn-sm btn btn-success btn-fw float-right btn-rounded"
              >
                <i className="mdi mdi-account-plus mr-1"></i> Add Abattoir
              </button>
            </>
          }
        >
          <div className="row">
            <div className="col-md-12 mb-5">
              <CardBlock>
                {this.state.reloading ? (
                  <Spinner />
                ) : (
                  <>
                    {this.state.modalIsOpen || (
                      <AbattoirsList
                        selectRecord={(r) => this.selectRecord(r)}
                      />
                    )}
                  </>
                )}
              </CardBlock>
            </div>
          </div>
        </MainLayout>
      </>
    );
  }
}
