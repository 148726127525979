import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import { Link } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import "../../sass/kanban.scss";
import Select from "react-select";
import Modal from "react-modal";
import SingleBankAccount from "../../Components/SingleBankAccount";

export default class BankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      updateLoading: false,
      errorMessage: null,
      banks: [],
      saveErrorMessage: null,
      modalIsOpen: false,
    };
  }

  componentDidMount() {
    this.loadBanks();
  }

  loadBanks() {
    this.setState({ errorMessage: null, loading: true });
    APICall("api/bankaccounts/listbanks", "GET", null)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          response.data.forEach((element) => {
            element.label = element.bankName;
            element.value = element.bankCode;
          });
          this.setState({ banks: response.data }, () => {
            this.loadData();
          });
        } else {
          this.setState({
            errorMessage: response.message,
            loading: false,
            data: {},
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  loadData() {
    this.setState({ errorMessage: null, loading: true });
    APICall("api/bankaccounts/getconfiguration", "GET", null)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ data: response.data, loading: false });
        } else {
          this.setState({
            errorMessage: response.message,
            loading: false,
            data: {},
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  render() {
    return (
      <>
        <MainLayout title="Settlement Accounts">
          <div className="row">
            <div className="col-md-12 mb-5">
              {this.state.loading ? (
                <Spinner />
              ) : (
                <CardBlock title="" description="">
                  {this.state.errorMessage ? (
                    <CenterBlock height="150">
                      <h1 className="text-danger icon-lg">
                        <i className="mdi mdi-alert-circle"></i>
                      </h1>
                      <div className="text-dark text-center" role="alert">
                        An error occurred. <br />
                        Reload page or try again after sometime.{" "}
                      </div>
                      <button
                        type="button"
                        onClick={() => this.loadData()}
                        className="btn btn-inverse-light btn-fw mt-4 mb-5"
                      >
                        Reload
                      </button>
                    </CenterBlock>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col">
                          <SingleBankAccount
                            bankAccount={this.state.data.governmentAccount}
                            owner="government"
                            banks={this.state.banks}
                            reload={() => this.loadData()}
                          />
                        </div>
                        <div className="col">
                          <SingleBankAccount
                            bankAccount={this.state.data.ourAccount}
                            owner={process.env.REACT_APP_NAME}
                            banks={this.state.banks}
                            reload={() => this.loadData()}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </CardBlock>
              )}
            </div>
          </div>
        </MainLayout>
      </>
    );
  }
}
