import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Spinner from "../../Utils/Spinner";
import "rc-datepicker/lib/style.css";
import CertificatesList from "../../Components/CertificatesList";
import { DataContext } from "../../Contexts/DataContexts";
import DiseaseReportsList from "../../Components/DiseaseReporting/DiseaseReportsList";

export default class DiseaseReportsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      errorMessage: null,
    };
  }

  static contextType = DataContext;

  componentDidMount() {}

  render() {
    return (
      <MainLayout title="My Disease Reports">
        <div className="row">
          <div className="col-md-12 mb-5">
            <CardBlock>
              <DiseaseReportsList />
            </CardBlock>
          </div>
        </div>
      </MainLayout>
    );
  }
}
