import React, { Component, useEffect, useState } from "react";
import Functions from "../Utils/Functions";
import { Link } from "react-router-dom";
import Constants from "../Utils/Constants";
import { DataContext } from "../Contexts/DataContexts";
import SpinnerButton from "../Utils/SpinnerButton";
import { initial } from "lodash";
import Modal from "react-modal";
import APICall from "../Utils/APICall";
import Toastr from "../Utils/Toastr";
import Select from "react-select";

export default function SingleBankAccount({
  owner,
  bankAccount,
  banks,
  reload,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [saveErrorMessage, setSaveErrorMessage] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    init();
  }, []);

  Modal.setAppElement("body");
  const init = () => {};

  const resetForm = () => {
    setModalIsOpen(false);
    setAccountNumber("");
    setBankCode("");
  };

  const openModal = () => {
    setModalIsOpen(true);
    setAccountNumber("");
    setBankCode("");
  };

  const update = () => {
    let _owner = owner;
    if (_owner == process.env.REACT_APP_NAME) {
      _owner = "we";
    }
    setUpdateLoading(true);
    setSaveErrorMessage(null);
    APICall("api/bankaccounts/save", "POST", {
      accountNumber: accountNumber,
      bankCode: bankCode,
      accountOwner: _owner,
    })
      .then((data) => {
        var response = data.data;
        setUpdateLoading(false);
        if (response.isSuccess) {
          Toastr("success", "Bank account updated");

          reload();
          resetForm();
        } else {
          setSaveErrorMessage(response.message);
        }
      })
      .catch((error) => {
        setUpdateLoading(false);
        setSaveErrorMessage("An error occurred, please check your network");
      });
  };

  const updateBankAccountForm = () => {
    return (
      <div className="mymodal-wrapper" style={{ width: "400px" }}>
        <h5 className="theme-color" style={{ textTransform: "uppercase" }}>
          {owner} Bank Account
        </h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <label className="control-label font-weight-bold text-darker">
                Account Number
              </label>
              <input
                type="text"
                onChange={(e) => setAccountNumber(e.target.value)}
                className="form-control form-control-sm"
              />
            </div>
          </div>

          <div className="form-group ">
            <label className="mt-3 control-label font-weight-bold text-darker">
              Your Bank
            </label>
            <Select
              styles={Constants.selectStyles}
              options={banks}
              onChange={(option) => setBankCode(option.value)}
            />
          </div>

          {saveErrorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {saveErrorMessage}
              </div>
            </div>
          ) : null}
          <SpinnerButton
            onClick={() => update()}
            className="btn btn-dark mr-2 btn-block mb-3"
            label="Update Bank Account"
            loading={updateLoading}
          />
        </form>
      </div>
    );
  };

  return (
    <>
      <Modal
        onRequestClose={() => resetForm()}
        isOpen={modalIsOpen}
        contentLabel="Update bank account"
        style={Constants.defaultModalStyle}
      >
        {updateBankAccountForm()}
      </Modal>
      {!bankAccount ? (
        <div className="text-center">
          <p className="small text-muted px-4">
            <span className="text-dark font-weight-bold">
              You have not setup any bank account for {owner.toUpperCase()}.
            </span>{" "}
            <br />
            You need to specify an account where {owner.toUpperCase()}'s revenue
            will be settled into.
          </p>
          <SpinnerButton
            className="btn-md mt-3 mb-5 btn btn-success btn-fw btn-rounded"
            label={`Add ${owner} bank account`.toUpperCase()}
            onClick={() => openModal()}
          />
        </div>
      ) : (
        <div className="board-wrapper">
          <ul
            className="portlet-card-list ui-sortable col-12"
            style={{ boxShadow: "none" }}
          >
            <li className="portlet-card ui-sortable-handle mb-0 pb-0">
              <h4
                className="task-title text-muted"
                style={{
                  borderLeft: "solid 4px #83B739",
                  paddingLeft: "20px",
                }}
              >
                <span className="font-weight-normal d-inline-block mb-2">
                  <strong>{owner.toUpperCase()}</strong> account:
                </span>
                <br />
                <span className="text-dark" style={{ fontSize: "1.75em" }}>
                  {bankAccount.accountNumber} <br />{" "}
                  <small>
                    <small>{bankAccount.bankName}</small>
                  </small>
                </span>
              </h4>
              <div className="image-grouped mt-3" />
              <div className="badge pl-0" style={{ justifyContent: "left" }}>
                <SpinnerButton
                  label="Change Bank Account"
                  onClick={() => openModal()}
                  className="btn btn-sm btn-dark"
                />
              </div>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}
