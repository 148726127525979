import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import { Link } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import "moment/locale/en-gb.js";
import Modal from "react-modal";
import Select from "react-select";
import Functions from "../../Utils/Functions";
import { DataContext } from "../../Contexts/DataContexts";

export default class IssueCertificateSuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      errorMessage: null,
      id: ""
    };
  }


  static contextType = DataContext;

  componentDidMount() {
    const { globalState, addItem } = this.context;
    this.setState({ profile: globalState?.data })
    const { id } = globalState?.user
    this.setState({ id })
  }

  render() {
    return (
      <MainLayout title="Issue Certificate: Success">
        <div className="row">
          <div className="col-md-12 mb-5">
            <div className="text-center">
              <h1 className="text-success big-icon">
                <i className="mdi mdi-checkbox-marked-circle-outline"></i>
              </h1>
              <br />
              <p>Certificate issued successfully</p>
              <h3>
                <strong>Certificate number: </strong><br />
                <span className="theme-color">
                  {this.props.match.params.certificateCode}
                </span>
              </h3>
              <div className="mt-4">
                <Link
                  to={`/in/new-certificate`}
                  className="mt-3 mb-5 btn btn-dark btn-fw btn-rounded mr-3"
                >
                  Issue another certificate
                </Link>

                <a
                  target="_blank"
                  href={`${Constants.apiBaseUrl}/api/certificates/download/${this.props.match.params.certificateCode}/${this.state.id}`}
                  className="mt-3 mb-5 btn btn-success btn-fw btn-rounded"
                >
                  Download Certificate
                </a>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
