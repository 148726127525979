import React, { Component } from "react";
import Login from "./Pages/Common/Login";
import Register from "./Pages/Common/Register";
import RegistrationSuccesful from "./Pages/Common/RegistrationSuccessful";
import RecoverPassword from "./Pages/Common/RecoverPassword";
import RegisterVerifyEmail from "./Pages/Common/RegisterVerifyEmail";
import RecoverPasswordSuccessful from "./Pages/Common/RecoverPasswordSuccessful";
import SetPassword from "./Pages/Common/SetPassword";
import SetPasswordSuccessful from "./Pages/Common/SetPasswordSuccessful";
import Logout from "./Pages/Common/Logout";
import PasswordChange from "./Pages/Common/PasswordChange";
import Home from "./Pages/Common/Home";
import Profile from "./Pages/Common/Profile";
import PaymentStatus from "./Pages/Common/PaymentStatus";
import BankAccount from "./Pages/Admin/BankAccount";
import SingleUser from "./Pages/Admin/SingleUser";
import Payments from "./Pages/Admin/Payments";
import Abattoirs from "./Pages/Admin/Abattoirs";
import Ranch from "./Pages/Ranch/Ranch";
import SingleAnimal from "./Pages/Ranch/SingleAnimal";
import TakeTagDeliveryPage from "./Pages/Ranch/TakeTagDeliveryPage";
import FetchAnimalDetails from "./Pages/Ranch/FetchAnimalDetails";
import FetchAnimalDetailsPublic from "./Pages/Ranch/FetchAnimalDetailsPublic";
import RegisterCompleteSupplier from "./Pages/Common/RegisterCompleteSupplier";
import VetCheckin from "./Pages/Vets/VetCheckin";
import IssueCertificate from "./Pages/Vets/IssueCertificate";
import CardSetupPage from "./Pages/Distributors/CardSetupPage";
import IssueCertificateSuccessPage from "./Pages/Vets/IssueCertificateSuccess";
import VetIssuedCertificatesPage from "./Pages/Vets/VetIssuedCertificatesPage";
import AllCertificatesPage from "./Pages/Admin/AllCertificatesPage";
import DistributorCertificatesPage from "./Pages/Distributors/DistributorCertificatesPage";
import VetsManagementPage from "./Pages/Admin/VetsManagementPage";
import DistributorsPage from "./Pages/Admin/DistributorsPage";
import InvestorsPage from "./Pages/Admin/InvestorsPage";
import SettingsPage from "./Pages/Admin/SettingsPage";
import NewDiseaseReportPage from "./Pages/Vets/NewDiseaseReportPage";
import NewDiseaseReportSuccessPage from "./Pages/Vets/NewDiseaseReportSuccessPage";
import DiseaseReportsPage from "./Pages/Vets/DiseaseReportsPage";
import AbattoirVetViewPage from "./Pages/Vets/AbattoirVetViewPage";
import RevenuePage from "./Pages/SuperAdmin/RevenuePage";
import VerifyCertificatePage from "./Pages/Common/VerifyCertificatePage";
import FetchAnimalInformationPage from "./Pages/Ranch/FetchAnimalInformationPage";
import VerifyCertificateViewPage from "./Pages/Common/VerifyCertificateViewPage";
import TakeDeliveryPage from "./Pages/Vets/TakeDeliveryPage";
import TakeDeliverySuccessPage from "./Pages/Vets/TakeDeliverySuccessPage";
import DeliveryPage from "./Pages/Distributors/DeliveryPage";
import DeliverySuccessPage from "./Pages/Distributors/DeliverySuccessPage";
import RegisterCompleteRegualar from "./Pages/Common/RegisterCompleteRegular";
import VerifyCertificateAfterLoginPage from "./Pages/Regular/VerifyCertificateAfterLoginPage";
import ReceiveConsignmentPage from "./Pages/Regular/ReceiveConsignmentPage";
import ReceiveConsignmentSuccessPage from "./Pages/Regular/ReceiveConsignmentSuccessPage";
import AnimalConfigPage from "./Pages/Admin/AnimalConfigPage";
import LabManagementPage from "./Pages/Admin/LabManagementPage";
import SendTestSamplePage from "./Pages/Vets/SendTestSamplePage";
import SendTestSampleSuccessPage from "./Pages/Vets/SendTestSampleSuccessPage";
import VetsLabTestsPage from "./Pages/Vets/VetsLabTestsPage";
import LabsTestsPage from "./Pages/Laboratory/LabsTestsPage";
import AllInspectionReportsPage from "./Pages/Admin/AllInspectionReportsPage";
import GovernmentInspectorsManagementPage from "./Pages/Admin/GovernmentInspectorsManagementPage";
import IntroductionPage from "./Pages/IntroductionPage";
import ColdStart from "./Pages/ColdStart";
import UsersReport from "./Pages/Admin/UsersReport";
import Animal from "./Pages/Ranch/Animal";
import AnimalBirthingInformationPage from "./Pages/Ranch/AnimalBirthingInfo";

const Routes = [
  { route: "/", component: IntroductionPage },
  { route: "/cold-start", component: ColdStart },
  { route: "/verify-certificate", component: VerifyCertificatePage },
  { route: "/in/users-reports", component: UsersReport },
  {
    route: "/view-certificate/:certificateCode",
    component: VerifyCertificateViewPage,
  },
  { route: "/login", component: Login },
  { route: "/logout", component: Logout },
  { route: "/register/:role", component: Register },
  {
    route: "/register-complete-distributor/:registrationCode",
    component: RegisterCompleteSupplier,
  },
  {
    route: "/register-complete-regular/:registrationCode",
    component: RegisterCompleteRegualar,
  },
  {
    route: "/registration-successful/:registrationCode",
    component: RegistrationSuccesful,
  },
  {
    route: "/verify-email/:verificationStatus",
    component: RegisterVerifyEmail,
  },
  {
    route: "/password-recovery",
    component: RecoverPassword,
  },
  {
    route: "/password-recovery-step1-success",
    component: RecoverPasswordSuccessful,
  },
  {
    route: "/set-password/:code",
    component: SetPassword,
  },
  {
    route: "/set-password-successful",
    component: SetPasswordSuccessful,
  },
  { route: "/in/home", component: Home },











  { route: "/in/ranch", component: Ranch },
  {
    route: "/in/ranch/fetch-animal-details",
    component: FetchAnimalDetails,
  },
  {
    route: "/in/ranch/birth-Info/:animal",
    component: AnimalBirthingInformationPage,
  },
  {
    route: "/in/ranch/:animal",
    component: Animal,
  },

  {
    route: "/in/ranch/animal/:animalTag",
    component: SingleAnimal,
  },

  // { route: "/fetch-animal-information", component: FetchAnimalInformationPage },
  { route: "/fetch-animal-information", component: FetchAnimalDetailsPublic },












  {
    route: "/in/change-password",
    component: PasswordChange,
  },
  { route: "/in/profile", component: Profile },
  { route: "/in/card", component: CardSetupPage },
  {
    route: "/in/take-delivery",
    component: TakeDeliveryPage,
  },
  {
    route: "/in/take-tag-delivery",
    component: TakeTagDeliveryPage,
  },
  {
    route: "/in/take-delivery-success/:tagNumber",
    component: TakeDeliverySuccessPage,
  },
  {
    route: "/in/new-certificate",
    component: IssueCertificate,
  },
  {
    route: "/in/new-certificate-success/:certificateCode",
    component: IssueCertificateSuccessPage,
  },
  {
    route: "/in/issued-certificates",
    component: VetIssuedCertificatesPage,
  },
  {
    route: "/in/all-inspection-reports/:laboratoryId/:retailOutletId/:vetId",
    component: AllInspectionReportsPage,
  },
  {
    route: "/in/all-certificates",
    component: AllCertificatesPage,
  },
  {
    route: "/in/all-certificates/:vetId",
    component: AllCertificatesPage,
  },
  {
    route: "/in/my-certificates",
    component: DistributorCertificatesPage,
  },
  {
    route: "/in/vets-management",
    component: VetsManagementPage,
  },
  {
    route: "/in/vets-test-samples-view",
    component: VetsLabTestsPage,
  },
  {
    route: "/in/labs-management",
    component: LabManagementPage,
  },
  {
    route: "/in/send-test-to-lab",
    component: SendTestSamplePage,
  },
  {
    route: "/in/send-test-success/:trackingCode",
    component: SendTestSampleSuccessPage,
  },
  { route: "/in/distributors", component: DistributorsPage },
  {
    route: "/in/investors/:abattoirId",
    component: InvestorsPage,
  },
  {
    route: "/in/payment/status/:reference",
    component: PaymentStatus,
  },
  { route: "/in/payment/status", component: PaymentStatus },
  { route: "/in/bank", component: BankAccount },
  { route: "/in/profile/:userName", component: SingleUser },
  { route: "/in/payments", component: Payments },
  { route: "/in/abattoirs", component: Abattoirs },
  { route: "/in/abattoirs-view", component: AbattoirVetViewPage },
  { route: "/in/checkin", component: VetCheckin },
  { route: "/in/settings", component: SettingsPage },
  { route: "/in/specie-configuration", component: AnimalConfigPage },
  { route: "/in/new-disease-report", component: NewDiseaseReportPage },
  {
    route: "/in/new-disease-report/:tagNumber",
    component: NewDiseaseReportPage,
  },
  { route: "/in/my-disease-reports", component: DiseaseReportsPage },
  {
    route: "/in/new-disease-report-success",
    component: NewDiseaseReportSuccessPage,
  },
  {
    route: "/in/supplier-delivery",
    component: DeliveryPage,
  },
  {
    route: "/in/supplier-delivery-success/:supplyCode",
    component: DeliverySuccessPage,
  },
  {
    route: "/in/revenue",
    component: RevenuePage,
  },
  {
    route: "/in/verify-certificate",
    component: VerifyCertificateAfterLoginPage,
  },
  {
    route: "/in/receive-consignment",
    component: ReceiveConsignmentPage,
  },
  {
    route: "/in/receive-consignment-success/:supplyCode",
    component: ReceiveConsignmentSuccessPage,
  },
  {
    route: "/in/lab-tests",
    component: LabsTestsPage,
  },
  {
    route: "/in/inspectors",
    component: GovernmentInspectorsManagementPage,
  },
];

export default Routes;
