import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { throttle } from "lodash";
import MyTable from "../Utils/MyTable";
import Spinner from "../Utils/Spinner";
import DropdownLinkMenu from "./DropdownLinkMenu";
import { DataContext } from "../Contexts/DataContexts";
import APICall from "../Utils/APICall";
import CenterBlock from "./CenterBlock";
import Functions from "../Utils/Functions";
import Constants from "../Utils/Constants";

const RanchAnimalMonthlyList = (props) => {
  const { selectRecord, animalTag } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [user, setUser] = useState({});
  const [alreadyGotResults, setAlreadyGotResults] = useState(false);
  const [selectedAnimal, setSelectedAnimal] = useState({});


  const throttledReload = throttle(() => {
    init(page, pageSize, searchQuery);
  }, 500);

  const context = useContext(DataContext);

  useEffect(() => {
    Modal.setAppElement("body");
    init();
    // new Functions().whoIsLoggedIn(this.setLoggedInUser, false);
  }, [animalTag]);

  const RenderTable = (_data) => {
    const headerList = [
      {
        Header: "",
        accessor: "actionsComponent",
      },
      {
        Header: "Animal Tag",
        accessor: "animalTag",
      },
      {
        Header: "Weight",
        accessor: "weight",
      },
      {
        Header: "Feed",
        accessor: "feed",
      },
      {
        Header: "Illness",
        accessor: "illness",
      },
      {
        Header: "Deworm",
        accessor: "deworm",
      },
      {
        Header: "Date",
        accessor: "dateRecordCreated",
      },
      {
        Header: "",
        accessor: "investorsTrigger",
      },
    ];

    _data._data.forEach((element) => {
      element.nameComponent = (
        <strong
          className="clickable"
          onClick={() => selectRecord(element)}
        >
          {element.name}
        </strong>
      );

      element.investorsTrigger = (
        <>

          {/* <span
          style={{ fontFamily: "Helvetica, Arial", cursor: "pointer" }}
          onClick={() => alert(element)}

        >
          {element.code}
        </span> */}


          <span
            style={{ cursor: "pointer" }}
            className="btn btn-xs btn-success btn-rounded"
            to={`/in/ranch/animal/${element.id}`}
            onClick={() => { setModalIsOpen(true); setSelectedAnimal(element) }}

          >
            View Record
          </span>

        </>

      );

      element.dateRecordCreated = (
        <>

          {Functions.sqlDateString(element.dateCreated)}

          {/* <DropdownLinkMenu
          label="..."
          linksArray={[
            `/in/all-certificates/${element.id}`,
            `/in/all-inspection-reports/0/0/${element.id}`,
          ]}
          linkLabelsArray={[
            "View Issued Certificates",
            "View Inspection Reports",
          ]}
        /> */}
        </>
      );
    });

    const tableHeader = React.useMemo(() => headerList, []);
    const tableData = React.useMemo(() => _data, []);

    return (
      <div style={{ zoom: "0.85" }}>
        <MyTable
          columns={tableHeader}
          data={tableData._data}
          enableSearch={true}
          cssArray={["w50px", "", "", ""]}
        />
      </div>
    );
  };

  const init = (page, pageSize, query) => {
    if (!query) query = "";

    setSearchQuery(query);
    setPage(page);
    setPageSize(pageSize);

    context && setUser(context.globalState?.user);

    // const { state, country, userRole, id } = context?.globalState?.user;

    // let apiURL = `api/vets/get/${country}/${state}`;

    // if (userRole === "superadmin") {
    //   apiURL = "api/vets/list";
    // }

    // /api/Ranch/getmonthlyranchanimalbyanimaltag/{animalTag}


    APICall(`/api/Ranch/getmonthlyranchanimalbyanimaltag/${animalTag}`, "GET", {})
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          setData(response.data)
        } else {
          setErrorMessage(
            "Your request generated an error. Try again after sometime"
          );
          setLoading(false);
        }
        console.log(data);
      })
      .catch((error) => {
        setErrorMessage(
          "Your request generated an error. Please check your network connection"
        );
        setLoading(false);
      });
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : errorMessage ? (
    <CenterBlock height="150">
      <h1 className="text-danger icon-lg">
        <i className="mdi mdi-alert-circle"></i>
      </h1>
      <div className="text-dark text-center" role="alert">
        An error occurred, you can try reloading the page
      </div>
      <button
        type="button"
        onClick={() => init()}
        className="btn btn-inverse-primary btn-fw mt-4 mb-5"
      >
        Reload
      </button>
    </CenterBlock>
  ) : (
    <>
      {loading ? (
        <div className="text-success" style={{ position: "absolute" }}>
          <Spinner size="1.5" />{" "}
        </div>
      ) : null}





      <Modal
        isOpen={modalIsOpen}
        contentLabel=""
        style={Constants.defaultModalStyle}
        onRequestClose={() => {
          setModalIsOpen(false);
        }}
      >
        <div className="mymodal-wrapper" style={{ width: "400px" }}>
          <h5> Monthly record for animal on {Functions.sqlDateString(selectedAnimal?.dateCreated)}
          </h5>
          <hr style={{ clear: "both", visibility: "hidden" }} />
          <form className="forms-sample">



            <div className="form-group row">
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Weight
                </label>
                <input
                  value={selectedAnimal?.weight}
                  defaultValue={selectedAnimal?.weight}
                  disabled
                  type="text"
                  placeholder="Sire"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Feed
                </label>
                <input
                  value={selectedAnimal?.feed}
                  defaultValue={selectedAnimal?.feed}
                  disabled
                  type="text"
                  placeholder="Dam"
                  className="form-control form-control-sm"
                />
              </div>
            </div>


            <div className="form-group row">
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Illness
                </label>
                <input
                  value={selectedAnimal?.illness}
                  defaultValue={selectedAnimal?.illness}
                  disabled
                  type="text"
                  placeholder="Sire"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Deworm
                </label>
                <input
                  value={selectedAnimal?.deworm}
                  defaultValue={selectedAnimal?.deworm}
                  disabled
                  type="text"
                  placeholder="Dam"
                  className="form-control form-control-sm"
                />
              </div>
            </div>






            <div className="form-group row">
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Vaccine
                </label>
                <input
                  value={selectedAnimal?.vaccine}
                  defaultValue={selectedAnimal?.vaccine}
                  disabled
                  type="text"
                  placeholder="Sire"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Suppliment
                </label>
                <input
                  value={selectedAnimal?.suppliments}
                  defaultValue={selectedAnimal?.suppliments}
                  disabled
                  type="text"
                  placeholder="Dam"
                  className="form-control form-control-sm"
                />
              </div>
            </div>







            <div className="form-group row">
              <div className="col-12 col-md-12">
                <label className="control-label font-weight-bold text-darker">
                  Attending Vet's Observations
                </label>
                <textarea
                  className="form-control form-control-sm"
                  placeholder="Attending Vet's Observations"
                  value={selectedAnimal?.vetObservations}
                  defaultValue={selectedAnimal?.vetObservations}
                  disabled
                />
              </div>
            </div>





            {/* <div className="form-group row">
              
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Weight
                </label>
                {selectedAnimal?.weight}
              </div>
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Weight
                </label>
                {selectedAnimal?.weight}
              </div>
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Feed
                </label>
                {selectedAnimal?.feed}
              </div>
            </div> */}
            {/* <div className="form-group row">
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Sire
                </label>

              </div>
              <div className="col">
                <label className="control-label font-weight-bold text-darker">
                  Dam
                </label>

              </div>
            </div> */}
            <div className="form-group row">
              <div className="col">

              </div>
            </div>

          </form>
        </div>
      </Modal>


      {!data ? null : <RenderTable _data={data} />}
    </>
  );
};

export default RanchAnimalMonthlyList;
