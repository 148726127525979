import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import { Link } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import PublicWithTopStripBackground from "../../Components/Layouts/PublicWithTopStripBackground";
import CreditCard from "../../Components/CreditCard";

export default class PaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      subscribeLoading: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(silent) {
    if (!this.props.match.params.reference) {
      //no reference was passed, this transaction failed fatally
      this.setState({ errorMessage: null, loading: false });
      return;
    }

    this.setState({ errorMessage: null, loading: silent ? false : true });
    APICall(
      "api/payments/status/" + this.props.match.params.reference,
      "GET",
      null
    )
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ data: response.data, loading: false });
        } else {
          this.setState({
            errorMessage: response.message,
            loading: false,
            data: {},
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  newSubscription() {
    this.subscribe();
  }

  subscribe(useCardOnFile, replaceCardOnFile) {
    if (!useCardOnFile) {
      useCardOnFile = false;
    } else {
      useCardOnFile = true;
    }
    if (!replaceCardOnFile) {
      replaceCardOnFile = false;
    } else {
      replaceCardOnFile = true;
    }

    this.setState({ subscribeLoading: true });
    APICall(
      `api/subscriptions/New/${useCardOnFile}/${replaceCardOnFile}`,
      "POST",
      null
    )
      .then((data) => {
        var response = data.data;
        if (response.status == "success") {
          if (response.redirectUrl) {
            //redirect to payment endpoint to complete subscription
            window.location.href = response.redirectUrl;
          } else {
            //redirect not set, this has to be that charge was done with card on file
            Toastr("success", "Subscription successful");
            this.loadData();
          }
        } else {
          Toastr("error", "Subscription failed.");
          this.setState({ subscribeLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
        Toastr("error", "An error occurred, please check your network.");
        this.setState({ subscribeLoading: false });
      });
  }

  render() {
    return (
      <PublicWithTopStripBackground
        children={
          <div className="col-12 col-md-6 mx-auto">
            {this.state.loading ? (
              <Spinner />
            ) : (
              <CardBlock title="" description="" ex>
                {this.state.errorMessage ? (
                  <CenterBlock height="150">
                    <h1 className="text-danger">
                      <i className="mdi mdi-alert-circle"></i>
                    </h1>
                    <div className="text-danger text-center" role="alert">
                      An error occurred, click the button to refresh{" "}
                    </div>
                    <button
                      type="button"
                      onClick={() => this.loadData()}
                      className="btn btn-inverse-primary btn-fw mt-4"
                    >
                      Reload
                    </button>
                  </CenterBlock>
                ) : !this.state.data ||
                  !this.state.data.id ||
                  !this.state.data.isPaid ? (
                  <CenterBlock height="150">
                    <h1 className="text-danger big-icon">
                      <i className="mdi mdi-alert-circle"></i>
                    </h1>
                    {this.state.data
                      .id /*The subscription was created but payment failed */ ? (
                      <>
                        <h1 className="mb-0 font-weight-bold text-dark ">
                          {this.state.data.amountFormatted}
                        </h1>
                        <div className="text-dark text-center" role="alert">
                          Your transaction was not successful
                        </div>
                      </>
                    ) : (
                      /*Transaction failed fatally */
                      <div className="text-dark text-center" role="alert">
                        An error occurred, subscription payment failed to
                        initialize{" "}
                      </div>
                    )}

                    <div className="row mt-5 mb-5">
                      <div className="col text-right">
                        <Link
                          className="btn btn-dark btn-fw"
                          to={"/in/subscription"}
                        >
                          Return
                        </Link>
                      </div>
                      <div className="col">
                        <SpinnerButton
                          loading={this.state.subscribeLoading}
                          className="btn btn-success btn-fw"
                          label={`Retry Now`}
                          onClick={() => this.newSubscription()}
                        />
                      </div>
                    </div>
                  </CenterBlock>
                ) : this.state.data.isPaid ? (
                  <div
                    className="text-center mx-auto"
                    style={{ maxWidth: "500px" }}
                  >
                    <h1 className="text-success big-icon">
                      <i className="mdi mdi-checkbox-marked-circle-outline"></i>
                    </h1>
                    {this.state.data.transactionType == "cardTokenization" ? (
                      <>
                        <p>
                          <strong>
                            Your card details was captured successfully.
                          </strong>{" "}
                          <br />
                          For seamless experience, always ensure you fund your
                          account adequately before going to purchase meat at
                          the abattoir.{" "}
                        </p>
                        <CreditCard
                          last4={this.state.data.switchResponse.last4}
                          name={this.state.data.owner.name}
                          expiry={`${this.state.data.switchResponse.exp_month}/${this.state.data.switchResponse.exp_year}`}
                        />
                      </>
                    ) : (
                      <>
                        <div
                          style={{ border: "0", background: "none" }}
                          className="alert alert-primary"
                        >
                          Your payment was successful
                        </div>
                        <hr />
                        <div className="row mt-5">
                          <div className="col-12 col-sm-6 col-md-4">
                            <div className="wrapper text-center text-sm-left">
                              <p className="card-text mb-0 text-dark">Amount</p>
                              <div className="fluid-container">
                                <h5 className="mb-0 font-weight-medium text-dark">
                                  {this.state.data.amountFormatted}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4">
                            <div className="wrapper text-center text-sm-left">
                              <p className="card-text mb-0 text-dark">
                                Reference
                              </p>
                              <div className="fluid-container">
                                <h5 className="mb-0 font-weight-medium text-dark">
                                  {this.state.data.paymentReference.toUpperCase()}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4">
                            <div className="wrapper text-center text-sm-left">
                              <p className="card-text mb-0 text-dark">Card</p>
                              <div className="fluid-container">
                                <h5 className="mb-0 font-weight-medium text-dark">
                                  **** **** **** {this.state.data.card}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <Link
                      className="mt-5 mb-5 btn btn-success btn-fw btn-rounded"
                      to={"/in/home"}
                    >
                      Go to Homepage
                    </Link>
                  </div>
                ) : null}
              </CardBlock>
            )}
          </div>
        }
      />
    );
  }
}
