import React, { Component } from "react";
import Constants from "../Utils/Constants";
import "../sass/credit-card.scss";

export default function CreditCard({ last4, name, expiry, style }) {
  return (
    <div className="credit-card" style={{ zoom: "0.5", ...style }}>
      <img src={Constants.subfolder + "/icon-reverse.png"} className="logo" />
      <div className="numbers">**** **** **** {last4}</div>
      <div className="name-and-expiry">
        <span>{name}</span>
        <span>{expiry}</span>
      </div>
    </div>
  );
}
