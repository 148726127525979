import React, { useState, useEffect } from "react";
import APICall from "../../Utils/APICall";
import Toastr from "../../Utils/Toastr";
import MainLayout from "../../Components/Layouts/MainLayout";
import Setting from "../../Components/Setting";
import { Link } from "react-router-dom";
import CardBlock from "../../Components/CardBlock";
import Spinner from "../../Utils/Spinner";
import Tabs from "../../Utils/Tabs";
import SpinnerButton from "../../Utils/SpinnerButton";

export default function SettingsPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [updatedTracker, changeUpdatedTracker] = useState(false);
  const [tabView, setTabView] = useState(null);

  useEffect(() => {
    console.log("use effect callsed");
    setLoading(true);
    APICall("api/configuration/list", "GET", {}).then((data) => {
      const response = data.data;
      setLoading(false);
      if (response.isSuccess) {
        const newData = response.data.map((item) => ({
          ...item,
          regexStatus: null,
        }));

        const certificationConfigs = newData.filter((a) => {
          return a.name.toLowerCase().includes("certification");
        });

        const abattoirServiceConfigs = newData.filter((a) =>
          a.name.toLowerCase().includes("abattoir services")
        );
        const otherConfigs = newData.filter(
          (a) =>
            !a.name.toLowerCase().includes("abattoir services") &&
            !a.name.toLowerCase().includes("certification")
        );

        const tabHeaders = [
          "Certification Fees",
          "Abattoir Service Fees",
          "Other Settings",
        ];
        const tabContent = [
          listSettings(certificationConfigs),
          listSettings(abattoirServiceConfigs),
          listSettings(otherConfigs),
        ];
        const _tabView = (
          <Tabs
            headers={tabHeaders}
            contents={tabContent}
            key="global-settings-tab"
          />
        );

        setData(newData);
        setTabView(_tabView);
      }
    });
  }, [updatedTracker]);

  const listSettings = (settingsList) => {
    return settingsList.map((setting, index) => {
      return <Setting key={index} {...setting} handleChange={handleChange} />;
    });
  };

  const handleRegex = (regex, value) => {
    const regexCheck = new RegExp(regex);
    return regexCheck.test(value);
  };

  const handleChange = (id, value) => {
    setData((prev) =>
      [...prev].map((item) => {
        if (item.id === id) {
          const regexStatus = handleRegex(item.regex, value);
          return { ...item, value, regexStatus };
        } else {
          return item;
        }
      })
    );
  };

  const dataToreturn = data.map((item) => ({
    name: item.name,
    value: item.value,
  }));

  const updateSetting = () => {
    setSaveLoading(true);
    APICall("api/configuration/update", "POST", dataToreturn)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          Toastr("success", "Settings updated");
          changeUpdatedTracker(!updatedTracker);
        } else {
          Toastr("error", response.message);
        }
        setSaveLoading(false);
      })
      .catch((error) => {
        setSaveLoading(false);
        Toastr("error", "An error occurred, please check your network");
      });
  };

  return (
    <MainLayout title="Configuration Settings">
      <CardBlock>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div
              className="settings"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginRight: "40px",
                marginBottom: "30px",
              }}
            >
              {tabView}
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mx-auto">
                {tabView && (
                  <SpinnerButton
                    loading={saveLoading}
                    className="btn btn-block btn-success"
                    onClick={updateSetting}
                    label="Update"
                  />
                )}
              </div>
            </div>
          </>
        )}
      </CardBlock>
    </MainLayout>
  );
}
