import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../Logo";
import AddAppScripts from "../Components/Layouts/AddAppScripts";

export default class ColdStart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
        };
    }

    componentDidMount() { }

    buttonClassNames = "text-white btn btn-lg btn-default btn-block  my-3 border-bottom border-white border-top-0 border-right-0 border-left-0";

    render() {
        return this.state.redirect ? (
            <Redirect to={this.state.redirect} />
        ) : (
            <main className="flex-shrink-0 main-container py-0" >
                <AddAppScripts />
                <div className="container-fluid vh-100 " style={{ background: "#006e47" }}>
                    <div className="row h-100">
                        <div className="container h-100">
                            <div className="row h-100">
                                <div className="col-12 align-self-start text-center"></div>
                                <div className="col-10 col-sm-8 col-md-6 mx-auto align-self-center text-center px-0">
                                    <div className="row">
                                        <div className="col-12 mb-5">
                                            <Logo reverse style={{ width: "300px" }} />
                                        </div>
                                        <div className="col-12">
                                            <Link
                                                to={"/verify-certificate"}
                                                className={this.buttonClassNames}
                                            >
                                                Verify certificate
                                            </Link>
                                        </div>
                                        <div className="col-12">
                                            <Link
                                                to={"/fetch-animal-information"}
                                                className={this.buttonClassNames}
                                            >
                                                Fetch Animal Information
                                            </Link>
                                        </div>
                                        <div className="col-12">
                                            <Link
                                                to={"/register/distributor"}
                                                className={this.buttonClassNames}
                                            >
                                                Supplier Registration
                                            </Link>
                                        </div>
                                        <div className="col-12">
                                            <Link
                                                to={"/register/regular"}
                                                className={this.buttonClassNames}
                                            >
                                                Retail Outlet Registration
                                            </Link>
                                        </div>
                                        <div className="col-12">
                                            <Link
                                                to={"/login"}
                                                className={this.buttonClassNames}
                                            >
                                                Login
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 py-4 align-self-end text-center"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}