import React, { Component } from "react";
import { Link } from "react-router-dom";

export default function SingleDiseaseReport(report) {
  const DataDisplay = ({ label, data }) => {
    return (
      <div className="form-group row  mb-2">
        <strong className="text-dark col-12 col-md-4 small font-weight-bold mb-2">
          {label}
        </strong>
        <div className="col">
          <p className="text-dark mb-2">{data}</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <DataDisplay label="Tag Number" data={report.tagNumber || "N/A"} />
      <DataDisplay label="Specie" data={report.animalSpecie} />
      <DataDisplay label="Organ" data={report.organ || "N/A"} />
      <DataDisplay
        label="Pathological Diagnosis"
        data={report.pathologicalDiagnosis}
      />
      <DataDisplay
        label="Most Likely Disease Suspected"
        data={report.diseaseSuspected}
      />
      <DataDisplay
        label="Confirmatory Diagnosis"
        data={report.confirmatoryDiagnosis}
      />
      <DataDisplay label="Condemnation" data={report.condemnation} />
      <DataDisplay label="Vet's Comments" data={report.comments} />
    </div>
  );
}
