import React, { Component } from "react";
import { Link } from "react-router-dom";

export default function StatBox({
  link,
  title,
  data,
  colClass,
  subTitle,
  subData,
}) {
  colClass = colClass || "col-xl-4 col-lg-4 col-sm-6";
  return (
    <div className={`${colClass} grid-margin stretch-card`}>
      <div className="w-100 card rounded">
        <Link to={link} className="no-decoration">
          <div className="card-body text-center compact py-3 px-3">
            <h5 className="mb-2 font-weight-normal theme-color small">
              {title}
            </h5>
            <h2 className="mb-0 text-dark font-weight-bold">
              {data}
              {subTitle && (
                <small style={{ fontSize: "50%" }}>
                  <br />
                  <span className="theme-color">{subTitle}:</span> {subData}
                </small>
              )}
            </h2>
          </div>
        </Link>
      </div>
    </div>
  );
}
