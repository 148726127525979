import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import { Link } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import "moment/locale/en-gb.js";
import Modal from "react-modal";
import Select from "react-select";
import Functions from "../../Utils/Functions";

export default class DeliverySuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.pullSupply();
  }

  async pullSupply() {
    this.setState({ loading: true });
    let apiResponse = null;
    let errorMessage = null;
    await APICall(
      `api/distributors/pullsupply/${this.props.match.params.supplyCode}`,
      "GET"
    )
      .then((data) => {
        apiResponse = data.data;
      })
      .catch((error) => {
        errorMessage = "Unable to confirm status, please check your network.";
      });

    if (apiResponse?.isSuccess) {
      this.setState({
        data: apiResponse.data,
      });
    } else {
      Toastr("error", errorMessage || apiResponse.message);
    }

    this.setState({ loading: false });
  }

  lineItem({ label, value }) {
    return (
      <h6 className="row">
        <strong className="col col-5 text-dark">{label}</strong>
        <span className="theme-color col col-7">{value}</span>
      </h6>
    );
  }

  render() {
    return (
      <MainLayout title="Delivery Successful">
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="text-center">
                <h1 className="text-success big-icon">
                  <i className="mdi mdi-checkbox-marked-circle-outline"></i>
                </h1>
                <br />
                <p className="text-dark">Delivery taken successfully</p>
                <div className="row text-left">
                  <div className="col-12 col-md-8 col-lg-6 mx-auto">
                    <hr className="col" />
                    <this.lineItem
                      label="Certificate number:"
                      value={this.state.data.certificate.code}
                    />
                    <this.lineItem
                      label="Supply Code:"
                      value={this.state.data.supply.supplyCode}
                    />
                    <this.lineItem
                      label="Delivery taken by:"
                      value={this.state.data.supply.retailer.name}
                    />
                    <this.lineItem
                      label="Weight delivered:"
                      value={`${this.state.data.supply.weight}kg`}
                    />
                    <hr className="col" />
                  </div>
                </div>

                <div className="mt-4">
                  <Link
                    to={`/in/delivery-log-distributor`}
                    className="mt-3 mb-5 btn btn-dark btn-fw btn-rounded mr-3"
                  >
                    Supply History
                  </Link>

                  <Link
                    to={"/in/supplier-delivery"}
                    className="mt-3 mb-5 btn btn-success btn-fw btn-rounded"
                  >
                    New Supply
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    );
  }
}
