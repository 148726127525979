import React, { Component } from "react";
import CenterBlock from "../CenterBlock";
import { Link } from "react-router-dom";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import { Line, Doughnut } from "react-chartjs-2";
import "moment/locale/en-gb.js";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { DataContext } from "../../Contexts/DataContexts";

export default class RanchDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorMessage: null,
      data: null,
      diseaseDistributionData: {},
      doughNutData: {
        labels: [
          "Abattoir 1",
          "Abattoir 2",
          "Abattoir 3",
          "Abattoir 4",
          "Abattoir 5",
          "Abattoir 6",
        ],
        datasets: [
          {
            label: "# of Votes",
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      lineData: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
          {
            label: "# of Votes",
            data: [12, 19, 3, 5, 2, 3],
            fill: true,
            backgroundColor: "rgb(131, 183, 57)",
            borderColor: "rgba(0, 110, 71, 0.45)",
          },
        ],
      },
      animalStat: {}
    };
  }

  static contextType = DataContext;

  data = {
    labels: ["1", "2", "3", "4", "5", "6"],
    datasets: [
      {
        label: "# of Red Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "# of Blue Votes",
        data: [2, 3, 20, 5, 1, 4],
        backgroundColor: "rgb(54, 162, 235)",
      },
    ],
  };

  options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.9,
          categoryPercentage: 0.2,
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  doughNutOptions = {
    legend: {
      display: false,
    },
  };
  doughNutData = {
    labels: [
      "Abattoir 1",
      "Abattoir 2",
      "Abattoir 3",
      "Abattoir 4",
      "Abattoir 5",
      "Abattoir 6",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  lineData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        fill: true,
        backgroundColor: "rgb(131, 183, 57)",
        borderColor: "rgba(0, 110, 71, 0.45)",
      },
    ],
  };

  lineOptions = {
    elements: {
      point: {
        radius: 0,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [
        {
          display: false,
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true, errorMessage: null });

    APICall("api/dashboard/admin", "GET", null)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState(
            { data: response.data, errorMessage: null, loading: false },
            () => {
              localStorage.setItem("dashboard", JSON.stringify(response.data));
              let diseaseData = {
                labels: [],
                datasets: [
                  {
                    label: "Healthy",
                    data: [],
                    backgroundColor: "rgb(255, 99, 132)",
                  },
                  {
                    label: "Diseased",
                    data: [],
                    backgroundColor: "rgb(54, 162, 235)",
                  },
                ],
              };

              this.state.data.diseaseDistribution.forEach((e) => {
                diseaseData.labels.push(e.key);
                diseaseData.datasets[0].data.push(e.value2);
                diseaseData.datasets[1].data.push(e.value);
              });
              this.setState({ diseaseDistributionData: diseaseData });
            }
          );
        } else {
          this.setState({
            errorMessage: response.message,
            loading: false,
            data: null,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });




    APICall(`/api/Ranch/getdistinctanimalcountbyranchid/${this.context.globalState.user?.id}`, "GET", null)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState(
            { data: response.data, errorMessage: null, loading: false },
            () => {


              const data = response.data; // Extract the data object

              const labels = Object.keys(data);
              const dataValues = Object.values(data);

              this.setState({ animalStat: data })

              const backgroundColor = [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ];
              const borderColor = [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ];

              this.setState({
                doughNutData: {
                  labels: labels,
                  datasets: [
                    {
                      label: "# of Votes",
                      data: dataValues, // Use the dynamically generated dataValues array here
                      backgroundColor: backgroundColor.slice(0, dataValues.length), // Trim to match the number of data values
                      borderColor: borderColor.slice(0, dataValues.length), // Trim to match the number of data values
                      borderWidth: 1,
                    },
                  ],
                }
              });

              this.setState({
                lineData: {
                  labels: labels,
                  datasets: [
                    {
                      label: "# of Votes",
                      data: dataValues,
                      fill: true,
                      backgroundColor: "rgb(131, 183, 57)",
                      borderColor: "rgba(0, 110, 71, 0.45)",
                    },
                  ],
                }
              });

            }
          );
        } else {
          this.setState({
            errorMessage: response.message,
            loading: false,
            data: null,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });


  }

  statBox(prop) {
    return (
      <div className="col-xl-4 col-lg-4 col-sm-6 grid-margin stretch-card">
        <div className="w-100 card rounded">
          <Link to={prop.link} className="no-decoration">
            <div className="card-body text-center compact py-4">
              <h5 className="mb-2 font-weight-normal">{prop.title}</h5>
              <h2 className="mb-0 text-dark font-weight-bold">{prop.data}</h2>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12 mb-3 mt-3">
          {this.state.loading && !this.state.data ? (
            <CenterBlock height="150">
              <Spinner size="2" />
            </CenterBlock>
          ) : this.state.errorMessage ? (
            <CenterBlock height="150">
              <h1 className="text-danger">
                <i className="mdi mdi-exclamation"></i>
              </h1>
              <div className="text-danger small" role="alert">
                {this.state.errorMessage}
              </div>
              <button
                type="button"
                onClick={() => this.loadData()}
                className="btn btn-inverse-light btn-fw mt-4 btn-rounded"
              >
                Reload
              </button>
            </CenterBlock>
          ) : this.state.data ? (
            <>
              <div className="row">
                {null && (
                  <>
                    <div
                      style={{ zoom: "0.85", marginTop: "-20px" }}
                      className="mb-3 col"
                    >
                      <div className="form-group row">
                        <div className="col col-md-3">
                          <label className="control-label font-weight-bold text-darker">
                            Date from
                          </label>
                          <DatePickerInput
                            defaultValue={this.state.fromDate}
                            onChange={(date) =>
                              this.setState({ fromDate: date }, () => {
                                this.quickReload();
                              })
                            }
                            className="my-custom-datepicker-component"
                          />
                        </div>
                        <div
                          className="col text-center"
                          style={{ maxWidth: "20px" }}
                        >
                          <label
                            style={{ visibility: "hidden" }}
                            className="control-label font-weight-bold text-darker"
                          >
                            -
                          </label>
                          <label className="control-label font-weight-bold text-darker mt-2">
                            _
                          </label>
                        </div>
                        <div className="col col-md-3">
                          <label className="control-label font-weight-bold text-darker">
                            Date to
                          </label>
                          <DatePickerInput
                            defaultValue={this.state.toDate}
                            onChange={(date) =>
                              this.setState({ toDate: date }, () => {
                                this.quickReload();
                              })
                            }
                            className="my-custom-datepicker-component"
                          />
                        </div>
                      </div>
                    </div>
                    <br style={{ clear: "both" }} />
                  </>
                )}
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-sm-4 grid-margin  grid-margin-lg-0">
                          <div className="wrapper pb-5 border-bottom">
                            <div className="chartjs-size-monitor">
                              <div className="chartjs-size-monitor-expand">
                                <div className />
                              </div>
                              <div className="chartjs-size-monitor-shrink">
                                <div className />
                              </div>
                            </div>
                            <div className="text-wrapper d-flex align-items-center justify-content-between mb-0">
                              <h3 className="mb-30 text-dark font-weight-bold">
                                {this.context.globalState.user?.name}
                              </h3>
                            </div>
                            <div className="text-wrapper d-flex align-items-center justify-content-between mb-0">
                              <p className="mb-0 text-dark">
                                Ranch Animal Summary
                              </p>
                            </div>
                            <h3 className="mb-3 text-dark font-weight-bold mt-0">
                              {Object.values(this.state.animalStat).reduce((acc, data) => acc + data, 0)}{" "}
                              <small
                                className="text-muted font-weight-normal"
                                style={{ fontSize: "75%" }}
                              >
                                animals
                              </small>
                            </h3>

                          </div>
                          <div className="wrapper pt-5">
                            <div className="chartjs-size-monitor">
                              <div className="chartjs-size-monitor-expand">
                                <div className />
                              </div>
                              <div className="chartjs-size-monitor-shrink">
                                <div className />
                              </div>
                            </div>
                            <div className="text-wrapper d-flex align-items-center justify-content-between mb-0">
                              <p className="mb-0 text-dark">Animal Frequency line Chart</p>
                            </div>
                            <h3 className="mb-4 text-dark font-weight-bold">
                              <small>Animal Count:</small>  {this.state.animalCount}{" "}

                            </h3>
                            <div
                              id="total-expences"
                              style={{
                                display: "block",
                                width: "auto",
                                height: "auto",
                              }}
                              className="chartjs-render-monitor"
                            >
                              <Line
                                data={this.state.lineData}
                                options={this.lineOptions}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-9 col-sm-8 grid-margin  grid-margin-lg-0">
                          <div className="pl-0 pl-lg-4 ">
                            <div className="chartjs-size-monitor">
                              <div className="chartjs-size-monitor-expand">
                                <div className />
                              </div>
                              <div className="chartjs-size-monitor-shrink">
                                <div className />
                              </div>
                            </div>
                            <div className="d-xl-flex justify-content-between align-items-center mb-2">
                              <div className="d-lg-flex w-100">
                                <p className="text-dark font-weight-bold mb-4 text-right w-100">
                                  Healthy vs Diseased animals
                                </p>
                              </div>
                            </div>
                            <div
                              className="graph-custom-legend clearfix"
                              id="device-sales-legend"
                            >
                              <ul className="2-legend">
                                <li>
                                  <span
                                    className="legend-box"
                                    style={{ background: "#36A2EB" }}
                                  />
                                  <span className="legend-label text-dark">
                                    Healthy
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className="legend-box"
                                    style={{ background: "#FF6384" }}
                                  />
                                  <span className="legend-label text-dark">
                                    Diseased
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div
                              id="total-profit"
                              style={{
                                display: "block",
                                width: "auto",
                                height: "108px",
                              }}
                              className="chartjs-render-monitor"
                            >
                              <Doughnut
                                data={this.state.doughNutData}
                                options={this.doughNutOptions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <div className="row mt-20">
                <div className="col-12">
                  <div className="row">


                    {Object.entries(this.state.animalStat).map(([animalName, animalCount]) => (
                      <this.statBox
                        key={animalName}
                        link={`/in/ranch/${animalName}`}
                        title={animalName}
                        data={animalCount}
                      />

                    ))}

                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
