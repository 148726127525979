import React, { useState, useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import "../sass/file-preview.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import Constants from "../Utils/Constants";

export default function RenderCertificate({ certificateCode }) {
  const certificateUrl = `${Constants.apiBaseUrl}/api/certificates/download/${certificateCode}`;
  const pdfRenderer = (
    <div className="row" style={{ marginLeft: "-50px", marginRight: "-50px" }}>
      <div className="col">
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={[{ uri: certificateUrl }]}
        />
      </div>
    </div>
  );

  return (
    <PerfectScrollbar>
      <div
        className="mymodal-wrapper file-preview-container lightbox-style"
        style={{
          width: "100vw",
          maxWidth: "800px",
        }}
      >
        {pdfRenderer}
      </div>
    </PerfectScrollbar>
  );
}
