import React, { Component } from "react";
import CardBlock from "../../Components/CardBlock";
import MainLayout from "../../Components/Layouts/MainLayout";
import UsersList from "../../Components/UsersList";
import Constants from "../../Utils/Constants";
import Select from "react-select";
import SpinnerButton from "../../Utils/SpinnerButton";
import APICall from "../../Utils/APICall";
import Toastr from "../../Utils/Toastr";
import Modal from "react-modal";
import AbattoirsList from "../../Components/AbattoirsList";
import VetsList from "../../Components/VetsList";
import DistributorsList from "../../Components/DistributorsList";

export default class DistributorsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      loading: false,
      saveErrorMessage: null,
      payload: {},
    };
  }

  componentDidMount() {}

  updatePayload(value, index) {
    let payload = this.state.payload;
    payload[index] = value;
    this.setState({ payload: payload });
  }

  render() {
    return (
      <>
        <MainLayout title={<>Supplier Records </>}>
          <div className="row">
            <div className="col-md-12 mb-5">
              <CardBlock>
                <div className="text-right"></div>
                {this.state.modalIsOpen || <DistributorsList />}
              </CardBlock>
            </div>
          </div>
        </MainLayout>
      </>
    );
  }
}
