import axios from "axios";
import Toastr from "./Toastr";
import Constants from "./Constants";

export default function (Url, Method, Data = null, timeoutOverride, silent) {
  if (localStorage.getItem("token")) {
    const authToken = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `${authToken}`;
  }

  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (response) => {
      // console.log(response);
      if (response?.data?.authorization) {
        localStorage.setItem("token", response.data.authorization);
      }
      return response;
    },
    (error) => {
      console.log(error);
      if (
        error.response &&
        error.response.status == "401" &&
        window.location.pathname.includes("/in/")
      ) {
        window.location.href = Constants.subfolder + "/logout";
      } else {
        if (!silent) {
          Toastr(
            "error",
            "Your request generated an error. Please check your network connection"
          );
        }
      }
      return Promise.reject(error);
    }
  );

  let baseUrl = Constants.apiBaseUrl;
  if (!baseUrl.endsWith("/")) {
    baseUrl = baseUrl + "/";
  }

  if (Url.startsWith("/")) {
    Url = Url.substring(1);
  }

  console.log("api baseurl: " + baseUrl);

  const Response = axios({
    method: Method,
    url: baseUrl + Url,
    data: Data,
    timeout: timeoutOverride || process.env.REACT_APP_REQUEST_TIMEOUT,
  });

  return Response;
}
