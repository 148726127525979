import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import DatePicker from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import DateFnsUtils from "@date-io/date-fns";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import RanchAnimalMonthlyList from "../../Components/RanchAnimalMonthlyList";
import Modal from "react-modal";
import Constants from "../../Utils/Constants";
import Select from "react-select";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import Functions from "../../Utils/Functions";

const SingleAnimal = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [saveErrorMessage, setSaveErrorMessage] = useState(null);
  const [payload, setPayload] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState(null);
  const [diseaseDistributionData, setDiseaseDistributionData] = useState({});
  const [animal, setAnimal] = useState({
    abattoirId: 1087,
    amountPaid: 632,
    amountPaidForAbattoirServices: 0,
    amountPaidForCertificate: 632,
    animalOwner: "Pariatur Nostrud in",
    buyerName: "Meatlink Distributors Ghana",
    color: "White",
    createdBy: 1090,
    dateCreated: "2023-10-07T12:05:08.97Z",
    distributorId: 13,
    gender: "M",
    id: 68,
    paymentChannel: "cash",
    paymentReference: "H6D6VB",
    receiptNumber: "123",
    specie: "CATTLE",
    stateOfOrigin: "Adamawa",
    subSpecie: "BOKOLO",
    tagNumber: "AGIPQ-2023",
    vetObservations: "Excepturi ut velit ",
    weight: 79,
  });
  const [lineData, setLineData] = useState({
    labels: ["1st", "2nd"],
    datasets: [
      {
        label: "# of Votes",
        data: [1, 20],
        fill: true,
        backgroundColor: "rgb(131, 183, 57)",
        borderColor: "rgba(0, 110, 71, 0.45)",
      },
    ],
  });

  const [reloadMonthlyList, setReloadMonthlyList] = useState(false); // State variable to control rendering

  // Function to toggle the reloadMonthlyList state variable
  const toggleReloadMonthlyList = () => {
    setReloadMonthlyList(!reloadMonthlyList);
  };
  const options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.9,
          categoryPercentage: 0.2,
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const doughNutOptions = {
    legend: {
      display: false,
    },
  };

  const doughNutData = {
    labels: [
      "Abattoir 1",
      "Abattoir 2",
      "Abattoir 3",
      "Abattoir 4",
      "Abattoir 5",
      "Abattoir 6",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const lineOptions = {
    elements: {
      point: {
        radius: 0,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [
        {
          display: false,
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  useEffect(() => {
    loadData();
  }, []);


  const [formData, setFormData] = useState({
    ranchAnimalId: null,
    animalTag: props.match.params.animalTag,
    weight: 0,
    feed: '',
    vaccine: '',
    deworm: '',
    suppliments: '',
    vetObservations: '',
    illness: '',

  });

  console.log(formData)
  formData.weight = parseInt(formData.weight)

  const addAnimalMonthlyRecord = () => {
    // Your addRecord logic here

    if (!formData.weight) {
      Toastr("error", "Enter Weight");
      return;
    }
    if (!formData.vaccine) {
      Toastr("error", "Enter Vaccine");
      return;
    }

    formData.ranchAnimalId = data.id

    APICall(
      `api/Ranch/createmonthlyranchanimal`,
      "post",
      formData
    )
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          Toastr("success", "Monthly record taken successfully");
          setModalIsOpen(false)
          toggleReloadMonthlyList()
        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      });


  };






  const updatePayload = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const newRecordForm = () => {
    return (
      <div className="mymodal-wrapper" style={{ width: "400px" }}>
        <h5>Add Animal Monthly Record</h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">


          {/* weight */}
          <div className="form-group row">
            <div className="col">
              <input
                type="number"
                placeholder="Animal Weignt"
                onChange={(e) =>
                  updatePayload(e.target.value, "weight")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>


          {/* feed */}
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Feed"
                onChange={(e) =>
                  updatePayload(e.target.value, "feed")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          {/* vaccines */}
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Vaccines"
                onChange={(e) =>
                  updatePayload(e.target.value, "vaccine")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          {/* Illness */}
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Illness or Injury"
                onChange={(e) =>
                  updatePayload(e.target.value, "illness")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          {/* deworming */}
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Deworming"
                onChange={(e) =>
                  updatePayload(e.target.value, "deworm")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          {/* suppliment */}
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Suppliments"
                onChange={(e) =>
                  updatePayload(e.target.value, "suppliments")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12 col-md-12">
              {/* <label className="control-label font-weight-bold text-darker">
                Attending Vet's Observations
              </label> */}
              <textarea
                className="form-control form-control-sm"
                placeholder="Attending Vet's Observations"
                value={formData.vetObservations}
                onChange={(e) => updatePayload(e.target.value, "vetObservations")}
              />
            </div>
          </div>

          {saveErrorMessage && (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {saveErrorMessage}
              </div>
            </div>
          )}

          <button
            type="button"
            onClick={addAnimalMonthlyRecord}
            className="btn btn-success mr-2 btn-block mb-3 mt-3 btn-rounded"
          >
            Add Monthly Record
          </button>
        </form>
      </div>

    );
  };

  const loadData = () => {
    setLoading(true);
    setErrorMessage(null);

    APICall("api/dashboard/admin", "GET", null)
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          setData(response.data);
          setErrorMessage(null);
          setLoading(false);

          const diseaseData = {
            labels: [],
            datasets: [
              {
                label: "Healthy",
                data: [],
                backgroundColor: "rgb(255, 99, 132)",
              },
              {
                label: "Diseased",
                data: [],
                backgroundColor: "rgb(54, 162, 235)",
              },
            ],
          };

          response.data.diseaseDistribution.forEach((e) => {
            diseaseData.labels.push(e.key);
            diseaseData.datasets[0].data.push(e.value2);
            diseaseData.datasets[1].data.push(e.value);
          });

          setDiseaseDistributionData(diseaseData);
        } else {
          setErrorMessage(response.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage("An error occurred, please check your network");
        setLoading(false);
      });





    APICall(`api/Ranch/getranchanimalbyanimaltag/${props.match.params.animalTag}`, "GET", null)
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          setData(response.data);
          setErrorMessage(null);
          setLoading(false);
          setAnimal(response.data)

          const diseaseData = {
            labels: [],
            datasets: [
              {
                label: "Healthy",
                data: [],
                backgroundColor: "rgb(255, 99, 132)",
              },
              {
                label: "Diseased",
                data: [],
                backgroundColor: "rgb(54, 162, 235)",
              },
            ],
          };

          response.data.diseaseDistribution.forEach((e) => {
            diseaseData.labels.push(e.key);
            diseaseData.datasets[0].data.push(e.value2);
            diseaseData.datasets[1].data.push(e.value);
          });

          setDiseaseDistributionData(diseaseData);
        } else {
          setErrorMessage(response.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage("An error occurred, please check your network");
        setLoading(false);
      });




    APICall(`api/Ranch/getmonthlyranchanimalbyanimaltag/${props.match.params.animalTag}`, "GET", null)
      .then((data) => {
        const response = data.data;
        console.log(response)
        alert(JSON.parse(response))
        if (true) {
          setData(response.data);
          setErrorMessage(null);
          setLoading(false);
          setLineData({
            labels: Array.from({ length: response.length }, (_, index) => `Mth ${index + 1}`),
            datasets: [
              {
                label: "# of Votes",
                data: response.map((item) => item.weight),
                fill: true,
                backgroundColor: "rgb(131, 183, 57)",
                borderColor: "rgba(0, 110, 71, 0.45)",
              },
            ],
          })
        } else {
          setErrorMessage(response.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage("An error occurred, please check your network");
        setLoading(false);
      });

  };

  const statBox = (prop) => {
    return (
      <div className="col-xl-4 col-lg-4 col-sm-6 grid-margin stretch-card">
        <div className="w-100 card rounded">
          <Link to={prop.link} className="no-decoration">
            <div className="card-body text-center compact py-4">
              <h5 className="mb-2 font-weight-normal">{prop.title}</h5>
              <h2 className="mb-0 text-dark font-weight-bold mt-0">
                {prop.data}
              </h2>
            </div>
          </Link>
        </div>
      </div>
    );
  };

  const singleAnimalItem = ({ label, prop }) => {
    return (
      <>
        <p className="small">
          <strong className="text-muted">{label}</strong>
          <br />
          {animal[prop]}
          <hr />
        </p>
      </>
    );
  };

  const animalBirthInfoDisplay = () => {
    return (
      <div className="row text-dark">
        <div className="col-6">
          {singleAnimalItem({ label: "Specie", prop: "specie" })}
        </div>
        <div className="col-6 text-right">
          {singleAnimalItem({ label: "Sub-specie", prop: "subSpecie" })}
        </div>
        <div className="col-6">
          {singleAnimalItem({ label: "Tag Number", prop: "animalTagId" })}
        </div>
        <div className="col-6 text-right">
          {singleAnimalItem({ label: "Weight", prop: "weightAtBirth" })}
        </div>
        <div className="col-6">
          {singleAnimalItem({ label: "Color", prop: "color" })}
        </div>
        <div className="col-6 text-right">
          {singleAnimalItem({ label: "Gender", prop: "gender" })}
        </div>

        <div className="col-12">
          <>
            <p className="small">
              <strong className="text-muted">Date of Birth</strong>
              <br />

              {Functions.sqlDateString(animal["dateOfBirth"])}
              <hr />
            </p>
          </>
        </div>
      </div>
    );
  };

  const animalDeliveryInfoDisplay = () => {
    return (
      <div className="row text-dark">
        <div className="col-6">
          {singleAnimalItem({ label: "Specie", prop: "specie" })}
        </div>
        <div className="col-6 text-right">
          {singleAnimalItem({ label: "Sub-specie", prop: "subSpecie" })}
        </div>
        <div className="col-6">
          {singleAnimalItem({ label: "Tag Number", prop: "animalTagId" })}
        </div>
        <div className="col-6 text-right">
          {singleAnimalItem({ label: "Weight", prop: "weightAtBirth" })}
        </div>
        <div className="col-6">
          {singleAnimalItem({ label: "Color", prop: "color" })}
        </div>
        <div className="col-6 text-right">
          {singleAnimalItem({ label: "Gender", prop: "gender" })}
        </div>
        <div className="col-6 ">
          {singleAnimalItem({ label: "Date of Birth", prop: "dateOfBirth" })}
        </div>
        <div className="col-6 text-right">
          {singleAnimalItem({ label: "Date of Birth", prop: "dateOfBirth" })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        contentLabel=""
        style={Constants.defaultModalStyle}
        onRequestClose={() =>
          setModalIsOpen(false)}

      >
        {newRecordForm()}
        {saveErrorMessage && (
          <p className="theme-color">{saveErrorMessage}</p>
        )}
      </Modal>


      <MainLayout
        title={
          <>
            {props.match.params.animalTag}{" "}
            <button
              onClick={() =>
                setModalIsOpen(true)
              }
              className="btn-sm btn btn-success btn-fw float-right btn-rounded"
            >
              <i className="mdi mdi-account-plus mr-1"></i> Add Monthly Record
            </button>
          </>
        }>
        <div className="content-wrapper">

          {animal && (
            <>
              <div className="form-group row">
                <div className="col-12 col-md-8 col-lg-8 mx-auto">
                  <h5 className="theme-color mb-4 mt-4">
                    Animal Birth Information
                  </h5>
                  {animalBirthInfoDisplay()}
                </div>
              </div>
              <hr style={{ visibility: "hidden" }} />

            </>
          )}
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="w-100 card">
                <div className="card-body rounded">
                  <h4 className="mb-4">Animal Monthly Records</h4>
                  <div className="table-responsive">

                    {modalIsOpen || (
                      <RanchAnimalMonthlyList
                        selectRecord={(r) => setSelectedRecord(r)}
                        animalTag={props.match.params.animalTag}
                      />
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12 grid-margin  grid-margin-lg-0">
              <div className="wrapper pt-5">
                <div className="chartjs-size-monitor">
                  <div className="chartjs-size-monitor-expand">
                    <div className />
                  </div>
                  <div className="chartjs-size-monitor-shrink">
                    <div className />
                  </div>
                </div>
                <div className="text-wrapper d-flex align-items-center justify-content-between mb-0">
                  <p className="mb-0 text-dark">Gross Weight at Slaughter</p>
                </div>
                <h3 className="mb-4 text-dark font-weight-bold">

                  {/* <small>KG:</small>      {this.state?.animalSlaughterInfo?.weight ? `${this.state?.animalSlaughterInfo?.weight}` : "-"} */}
                </h3>
                <div
                  id="total-expences"
                  style={{
                    display: "block",
                    width: "70%",
                    height: "auto",
                    margin: "auto"
                  }}
                  className="chartjs-render-monitor"
                >
                  <Line
                    data={lineData}
                    options={lineOptions}
                  />
                </div>
              </div>
            </div>

          </div>
          {/* <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="w-100 card">
                <div className="card-body rounded">
                  <h4 className="mb-4">Ranch Performance</h4>
                  <h5 className="mb-3">Sales Report</h5>
                  <Line data={data.salesReport} options={options} />
                  <h5 className="mb-3">Loss Report</h5>
                  <Line data={data.lossReport} options={options} />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </MainLayout></>
  );
};

export default SingleAnimal;
