import React, { Component } from "react";
import CardBlock from "../../Components/CardBlock";
import MainLayout from "../../Components/Layouts/MainLayout";
import Constants from "../../Utils/Constants";
import SpinnerButton from "../../Utils/SpinnerButton";
import APICall from "../../Utils/APICall";
import Toastr from "../../Utils/Toastr";
import Modal from "react-modal";
import VetsList from "../../Components/VetsList";
import { DataContext } from "../../Contexts/DataContexts";
import Select from "react-select";
import { CountryAndRegions } from "../../Utils/CountryAndRegions";

export default class VetsManagementPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      loading: false,
      saveErrorMessage: null,
      state: {},
      payload: {},
      selectedRecord: null,
      countryy: "",
      statee: "",
    };

    this.newRecordForm = this.newRecordForm.bind(this);
    this.updateRecordForm = this.updateRecordForm.bind(this);
  }

  static contextType = DataContext;


  componentDidUpdate(prevProps, prevState) {
    if (prevState.country !== this.state.payload?.country) {
      const countryName = this.state.payload?.country;
      const states = this.getSubdivisionsByCountry(countryName);
      if (states !== this.state.states) {
        this.setState({ states: states });
      }
    }
  }

  getSubdivisionsByCountry(countryName) {
    const country = CountryAndRegions.find(item => item.label === countryName);
    if (country) {
      return country.subdivisions;
    } else {
      return [];
    }
  }



  updatePayload(value, index, forUpdate) {
    let payload = forUpdate ? this.state.selectedRecord : this.state.payload;
    payload[index] = value;
    this.setState({ payload: payload });
  }


  componentDidMount() {
    this.init();


  }
  static contextType = DataContext;

  async init() {

    const { globalState } = this.context

    this.setState({ profile: globalState?.data })

    const userRole = globalState?.user?.userRole
    const state = globalState?.user?.state
    const country = globalState?.user?.country

    this.setState({ userRole: userRole })
    this.setState({ countryy: country })
    this.setState({ statee: state })


  }

  newRecordForm() {
    return (
      <div className="mymodal-wrapper" style={{ width: "400px" }}>
        <h5>Add Veterinary Officer</h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Officer's name"
                onChange={(e) => {
                  this.updatePayload(e.target.value, "contactName")
                  this.updatePayload(this.state.countryy, "country")
                  this.updatePayload(this.state.statee, "state")
                }
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Phone number"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "phoneNumber")
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="email"
                placeholder="Email address"
                onChange={(e) => this.updatePayload(e.target.value, "email")}
                className="form-control form-control-sm"
              />
            </div>
          </div>

          {(this.state.userRole == "superadmin")
            &&
            <div className="form-group row">
              <div className="col">
                <Select
                  options={CountryAndRegions}
                  styles={Constants.selectStyles}
                  onChange={(option) =>
                    this.updatePayload(option.value, "country")
                  }
                />
              </div>
              <div className="col">
                <Select
                  options={this.state.states}
                  styles={Constants.selectStyles}
                  onChange={(option) =>
                    this.updatePayload(option.value, "state")
                  }
                />
              </div>
            </div>
          }


          {this.state.saveErrorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {this.state.saveErrorMessage}
              </div>
            </div>
          ) : null}

          <SpinnerButton
            onClick={() => this.addRecord()}
            className="btn btn-success mr-2 btn-block mb-3 mt-3 btn-rounded"
            label="Add Veterinary Officer"
            loading={this.state.loading}
          />
        </form>
      </div>
    );
  }

  updateRecordForm() {
    return (
      <div className="mymodal-wrapper" style={{ width: "400px" }}>
        <h5>Update Veterinary Officer</h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                defaultValue={this.state.selectedRecord.name}
                placeholder="Officer's name"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "contactName", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                defaultValue={this.state.selectedRecord.phoneNumber}
                placeholder="Phone number"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "phoneNumber", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="email"
                defaultValue={this.state.selectedRecord.email}
                placeholder="Email address"
                onChange={(e) =>
                  this.updatePayload(e.target.value, "email", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          {(this.state.userRole == "superadmin")
            &&
            <div className="form-group row">
              <div className="col">
                <Select
                  options={CountryAndRegions}
                  styles={Constants.selectStyles}
                  onChange={(option) =>
                    this.updatePayload(option.value, "country")
                  }
                />
              </div>
              <div className="col">
                <Select
                  options={this.state.states}
                  styles={Constants.selectStyles}
                  onChange={(option) =>
                    this.updatePayload(option.value, "state")
                  }
                />
              </div>
            </div>
          }
          {this.state.saveErrorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {this.state.saveErrorMessage}
              </div>
            </div>
          ) : null}

          <SpinnerButton
            onClick={() => this.updateRecord()}
            className="btn btn-success mr-2 btn-block mb-3 mt-3 btn-rounded"
            label="Update Record"
            loading={this.state.loading}
          />
        </form>
      </div>
    );
  }

  addRecord() {
    this.setState({ loading: true, saveErrorMessage: null });
    APICall("api/users/register/vet", "POST", this.state.payload)
      .then((data) => {
        var response = data.data;
        this.setState({ loading: false });
        if (response.isSuccess) {
          Toastr("success", "New veterinary officer added");
          this.setState({ modalIsOpen: false, payload: {} });
        } else {
          this.setState({ saveErrorMessage: response.message });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          saveErrorMessage: "An error occurred, please check your network",
        });
      });
  }

  updateRecord() {
    let payload = {
      id: this.state.selectedRecord.id,
      contactName: this.state.payload.contactName,
      email: this.state.payload.email,
      phoneNumber: this.state.payload.phoneNumber,
    };

    this.setState({ loading: true, saveErrorMessage: null });
    APICall("api/vets/update", "POST", payload)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          Toastr("success", "Record updated");
          this.setState(
            { selectedRecord: null, payload: {}, loading: false },
            () => { }
          );
        } else {
          this.setState({ saveErrorMessage: response.message, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          saveErrorMessage: "An error occurred, please check your network",
        });
      });
  }

  selectRecord(record) {
    if (this.context.globalState.user.userRole.includes("admin")) {
      this.setState({ selectedRecord: record, payload: record });
    }
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          contentLabel=""
          style={Constants.defaultModalStyle}
          onRequestClose={() =>
            this.setState({ modalIsOpen: false, payload: {} })
          }
        >
          <this.newRecordForm />
        </Modal>

        <Modal
          isOpen={this.state.selectedRecord?.id ? true : false}
          contentLabel=""
          style={Constants.defaultModalStyle}
          onRequestClose={() =>
            this.setState({ selectedRecord: null, payload: {} })
          }
        >
          {this.state.selectedRecord && <this.updateRecordForm />}
        </Modal>

        <MainLayout
          title={
            <>
              Veterinary Officers{" "}
              {this.context.globalState.user.userRole.includes("admin") && (
                <button
                  onClick={() =>
                    this.setState({ modalIsOpen: true, payload: {} })
                  }
                  className="btn-sm btn btn-success btn-fw float-right btn-rounded"
                >
                  <i className="mdi mdi-account-plus mr-1"></i> Add New Vet
                </button>
              )}
            </>
          }
        >
          <div className="row">
            <div className="col-md-12 mb-5">
              <CardBlock>
                <div className="text-right"></div>

                {this.state.selectedRecord
                  ? null
                  : this.state.modalIsOpen || (
                    <VetsList
                      selectRecord={(record) => this.selectRecord(record)}
                    />
                  )}
              </CardBlock>
            </div>
          </div>
        </MainLayout>
      </>
    );
  }
}
