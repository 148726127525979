import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function DropdownLinkMenu({
  label,
  linksArray,
  linkLabelsArray,
}) {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <div className="dropdown show">
      <a
        className="btn dropdown-toggle btn-sm btn-rounded btn-link theme-color round-dropdown-trigger"
        href="#"
        role="button"
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => setDropdownVisible(!dropdownVisible)}
      >
        {label}
      </a>
      <div
        className={`dropdown-menu ${dropdownVisible && "show"}`}
        aria-labelledby="dropdownMenuLink"
      >
        {linksArray.map((link, index) => (
          <Link key={index} className="dropdown-item text-dark mb-3" to={link}>
            {linkLabelsArray[index]}
          </Link>
        ))}
      </div>
    </div>
  );
}
