import React, { Component } from "react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import { Link, Redirect } from "react-router-dom";
import AddAppScripts from "../Components/Layouts/AddAppScripts";
SwiperCore.use([Autoplay, Pagination]);

export default class IntroductionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
        };
    }

    componentDidMount() {
        //Functions.clearLocalStorage();
        var appOpenedBefore = localStorage.getItem("app-opened-before"); //app-opened-before will be set after a user logs in or registers
        if (appOpenedBefore) {
            this.setState({ redirect: "/login" });
        }
    }

    updateProp(e, prop) {
        var record = this.state.newRecord;
        record[prop] = e.target.value;
        this.setState({ newRecord: record });
    }

    swiperImage() {
        return <img
            src={"/logo-wide.png"}
            alt=""
            className=" h-200 mw-100 mb-4" style={{ height: "auto", width: "auto" }}
        />
    }

    render() {
        return this.state.redirect ? (
            <Redirect to={this.state.redirect} />
        ) : (
            <main className="flex-shrink-0 main-container py-0">
                <AddAppScripts />
                <div className="container-fluid vh-100 bg-white">
                    <div className="row h-100">
                        <div className="container h-100">
                            <div className="row h-100">
                                <div className="col-12 align-self-start text-center"></div>
                                <div className="col-10 col-sm-8 col-md-6 mx-auto align-self-center text-center px-0">
                                    {/* Swiper */}
                                    <Swiper
                                        autoplay={{ delay: 5000 }}
                                        loop={true}
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        onSlideChange={() => console.log("slide change")}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        pagination={{ clickable: true }}
                                    >
                                        <SwiperSlide>
                                            <div className="swiper-slide px-3 py-4 ">
                                                <this.swiperImage />
                                                <h4 className="text-default theme-color">
                                                    The pathway to meat export <br /> in Nigeria
                                                </h4>
                                                <p className="mb-4">
                                                    Strengthening due diligence in the systems that transfer edible meat and its byproducts from farm to fork
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide px-3 py-4 ">
                                                <this.swiperImage />
                                                <h4 className="text-default theme-color">
                                                    Inclusion, Transparency <br />and Quality Control
                                                </h4>
                                                <p className="mb-4">
                                                    Seamless electronic mechanism to facilitate statutory functions required for meat passed on to the public for consumption
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide px-3 py-4 ">
                                                <this.swiperImage />
                                                <h4 className="text-default theme-color">Ante-mortem and <br />Post-mortem Inspection</h4>
                                                <p className="mb-4">
                                                    Automation of multiple inspection stops into a seamless one stop process, enabling traceback, auditing, professionalism, accountability and consumer confidence.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                                <div className="col-12 py-4 align-self-end text-center">
                                    <div className="row">
                                        <div className="col">
                                            <Link
                                                to={"/cold-start"}
                                                className=" btn btn-lg btn-success btn-block btn-rounded"
                                            >
                                                Start
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
