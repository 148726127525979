import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import "rc-datepicker/lib/style.css";
import { DataContext } from "../../Contexts/DataContexts";
import LabTestList from "../../Components/LabTestList";
import Modal from "react-modal";
import Constants from "../../Utils/Constants";
import Select from "react-select";
import SpinnerButton from "../../Utils/SpinnerButton";
import Functions from "../../Utils/Functions";
import APICall from "../../Utils/APICall";
import Toastr from "../../Utils/Toastr";

export default class LabsTestsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      errorMessage: null,
      selectedRecord: null,
      saveLoading: false,
      saveErrorMessage: null,
    };
  }

  static contextType = DataContext;

  vetActions = [
    { label: "No Action Required", value: "okay" },
    { label: "Blacklist Outlet", value: "blacklist" },
    { label: "Issue Warning", value: "warning" },
  ];

  componentDidMount() {}

  displayModal(record) {
    this.setState({ selectedRecord: record });
  }

  resetForm() {
    this.setState({ selectedRecord: null });
  }

  takeAction() {
    this.setState({ saveLoading: true });
    let payload = { result: this.state.result, details: this.state.details };
    APICall(
      `api/labs/filltestresult/${this.state.selectedRecord.sampleCode}`,
      "post",
      payload
    )
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          Toastr("success", "Test result submitted");
          this.setState({
            selectedRecord: response.data,
            result: null,
            details: null,
          });
        } else {
          Toastr("error", response.message);
        }
        this.setState({ saveLoading: false });
      })
      .catch((err) => {
        this.setState({
          saveLoading: false,
          saveErrorMessage: "Please check your network",
        });
      });
  }

  displayRecord = () => {
    return (
      <div
        className="mymodal-wrapper"
        style={{ maxWidth: "100%", width: "600px" }}
      >
        <h5 className="theme-color">
          Tracking code: <strong>{this.state.selectedRecord.sampleCode}</strong>
        </h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <label className="small control-label font-weight-bold">
                Sample / Test Requested
              </label>
              <p className="text-darker mb-0">
                {this.state.selectedRecord.sampleDescription}
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12 col-md-4">
              <label className="small control-label font-weight-bold">
                Retail outlet
              </label>
              <p className="text-darker  mb-0">
                {this.state.selectedRecord.retailOutlet.name}
              </p>
            </div>
            <div className="col-12 col-md-4">
              <label className="control-label font-weight-bold small">
                Laboratory
              </label>
              <p className="text-darker  mb-0">
                {this.state.selectedRecord.laboratory.name}
              </p>
            </div>
            <div className="col-12 col-md-4">
              <label className="control-label font-weight-bold small">
                Veterinarian
              </label>
              <p className="text-darker  mb-0">
                {this.state.selectedRecord.vet.name}
              </p>
            </div>
          </div>

          {this.state.selectedRecord.testResult && (
            <div className="form-group row">
              <div className="col">
                <label className="small control-label font-weight-bold">
                  Test results:
                </label>
                <p className="text-darker mb-0">
                  {this.state.selectedRecord.testResult}
                </p>
                <p className="text-darker mt-3">
                  <strong className="text-muted">Recommendation</strong> <br />
                  {this.state.selectedRecord.laboratoryComments}
                </p>
                <p className="text-darker mt-3">
                  <strong>Date: </strong>
                  {Functions.toReadableDate(
                    this.state.selectedRecord.testResultDate
                  )}
                </p>
              </div>
            </div>
          )}
          <hr style={{ visibility: "hidden" }} />
          {!this.state.selectedRecord.testResult && (
            <p className="text-danger small mb-0">
              You have not submitted test results
            </p>
          )}
          {!this.state.selectedRecord.testResult && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="form-group ">
                    <label className="mt-3 control-label font-weight-bold text-darker">
                      Test results
                    </label>
                    <textarea
                      style={{ height: "75px" }}
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ result: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group ">
                    <label className="mt-3 control-label font-weight-bold text-darker">
                      Recommendation
                    </label>
                    <textarea
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ details: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              {this.state.saveErrorMessage ? (
                <div className="mb-3">
                  <div className="alert alert-danger small" role="alert">
                    {this.state.saveErrorMessage}
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col-12 col-md-6 mx-auto">
                  <SpinnerButton
                    onClick={() => this.takeAction()}
                    className="btn btn-dark mr-2 mb-3 btn-block btn-rounded"
                    label="Submit Test Result"
                    loading={this.state.saveLoading}
                  />
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    );
  };

  render() {
    return (
      <MainLayout title="Test Samples">
        {this.state.selectedRecord && (
          <Modal
            onRequestClose={() => this.resetForm()}
            isOpen={this.state.selectedRecord ? true : false}
            style={Constants.defaultModalStyle}
          >
            {this.displayRecord()}
          </Modal>
        )}
        <div className="row">
          <div className="col-md-12 mb-5">
            <CardBlock>
              <LabTestList
                laboratoryId={this.context.globalState.user.id}
                selectAction={(record) => this.displayModal(record)}
              />
            </CardBlock>
          </div>
        </div>
      </MainLayout>
    );
  }
}
