import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import "../../sass/kanban.scss";
import "moment/locale/en-gb.js";
import Modal from "react-modal";
import Select from "react-select";
import DataAPI from "../../Utils/DataAPI";
import Functions from "../../Utils/Functions";
import { isThisHour } from "date-fns";
import { DataContext } from "../../Contexts/DataContexts";

export default class NewDiseaseReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      errorMessage: null,
      redirectUrl: null,
      payload: {
        tagNumber: this.props.match.params.tagNumber
          ? this.props.match.params.tagNumber
          : null,
      },
      species: [],
      organs: [],
      pathologicalDiagnosis: [],
      diseases: [],
      secondConfirmation: null,
      saveLoading: false,
      animal: null,
      validateLoading: false,
    };

    this.formControl = this.formControl.bind(this);
  }

  static contextType = DataContext;

  async componentDidMount() {
    this.init();
    Modal.setAppElement("body");
    if (this.props.match.params.tagNumber) {
      await this.validateTagNumber();
    }
  }

  async validateTagNumber() {
    this.setState({ validateLoading: true });
    let tagNumber = this.props.match.params.tagNumber
      ? this.props.match.params.tagNumber
      : this.state.payload?.tagNumber;
    let animal = await DataAPI.findAnimal(tagNumber);
    if (animal) {
      this.setState({ animal: animal });
      this.updateSpecie(animal.specie);
    } else {
      Toastr("error", "Invalid animal tag number");
    }
    this.setState({ validateLoading: false });
  }

  async init() {
    let configuration = await DataAPI.diseaseReportingConfiguration();
    this.setState(
      {
        configuration: configuration,
        loading: false,
        initError: configuration ? false : true,
      },
      () => {
        if (this.state.configuration) {
          const species = [];
          this.state.configuration.species.forEach((element) => {
            species.push({ label: element, value: element });
          });

          this.setState({ species: species });
        }
      }
    );
  }

  condemnationOptions = [
    { label: "Partial", value: "Partial" },
    { label: "Full", value: "Full" },
    { label: "Inconsequential", value: "Inconsequential" },
  ];

  saveReport() {
    let payload = this.state.payload;

    this.setState({ saveLoading: true, errorMessage: null });
    APICall("api/diseasereports/add", "post", payload)
      .then((data) => {
        if (data.data.isSuccess) {
          Toastr("success", "Report saved");
          this.setState({
            redirectUrl: "/in/new-disease-report-success",
          });
        } else {
          this.setState({
            saveLoading: false,
            errorMessage: data.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          saveLoading: false,
          errorMessage: "Check your network connection",
        });
      });
  }

  updateFile(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      this.updateProp(reader.result, "base64File");
    };
    reader.readAsDataURL(file);
  }

  updateSpecie(value) {
    this.updateProp(value, "animalSpecie");
    let organs = this.state.configuration.organs.filter((a) => {
      return a.specie.toLowerCase() == value.toLowerCase();
    });

    if (organs && organs.length > 0) {
      organs.forEach((element) => {
        element.value = element.organ;
        element.label = element.organ;
      });
    }
    this.setState({ organs: organs });

    let diseases = this.state.configuration.diseases.filter((a) => {
      return a.specie.toLowerCase() == value.toLowerCase();
    });

    if (diseases && diseases.length > 0) {
      diseases.forEach((element) => {
        element.value = element.disease;
        element.label = element.disease;
      });
    }
    this.setState({ diseases: diseases });
  }

  updateOrgan(organ) {
    this.updateProp(organ.value, "organ");
    const pathologicalDiagnosis = [];
    if (organ.pathologicalDiagnosis && organ.pathologicalDiagnosis.length > 0) {
      organ.pathologicalDiagnosis.forEach((element) => {
        pathologicalDiagnosis.push({ label: element, value: element });
      });
    }

    this.setState({ pathologicalDiagnosis: pathologicalDiagnosis });
  }

  updateDisease(disease) {
    this.updateProp(disease.value, "diseaseSuspected");
    if (disease.confirmation && disease.confirmation.trim() != "") {
      this.setState({
        secondConfirmation: {
          label: disease.confirmationLabel,
          notes: disease.confirmation,
        },
      });
    } else {
      this.setState({ secondConfirmation: null });
    }
  }

  updateProp(value, prop) {
    let payload = this.state.payload;
    if (!payload) payload = {};
    payload[prop] = value;
    this.setState({ payload: payload });
  }

  formGroupRow({ colClass, children }) {
    return (
      <div className="form-group row">
        <div className={colClass}>{children}</div>
      </div>
    );
  }

  formControl({ label, prop, inputType, required }) {
    if (!inputType) inputType = "text";
    let payload = this.state.payload;
    if (!payload) payload = {};

    return (
      <>
        <label className="control-label font-weight-bold text-darker">
          {label} {required && <small className="text-danger">*</small>}
        </label>
        <input
          type={inputType}
          className="form-control form-control-sm"
          placeholder=""
          defaultValue={payload[prop]}
          onChange={(e) => this.updateProp(e.target.value, prop)}
        />
      </>
    );
  }

  MustHaveVCNView() {
    return (
      <CenterBlock height="150">
        <h1 className="text-danger icon-lg">
          <i className="mdi mdi-alert-circle"></i>
        </h1>
        <div className="text-dark text-center" role="alert">
          Only Vets with VCN Registration Number can fill disease reporting
        </div>
        <Link
          to="/in/home"
          className="btn btn-inverse-light btn-fw mt-4 mb-5 btn-rounded"
        >
          Back
        </Link>
      </CenterBlock>
    );
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <MainLayout
        title={
          !this.context.globalState.user.vcnRegistrationNumber
            ? ""
            : "Disease Reporting"
        }
      >
        <div className="row">
          <div className="col-md-12 mb-5">
            {this.state.loading ? (
              <Spinner />
            ) : this.state.initError ? (
              <CenterBlock height="150">
                <h1 className="text-danger icon-lg">
                  <i className="mdi mdi-alert-circle"></i>
                </h1>
                <div className="text-dark text-center" role="alert">
                  An error occurred, you can try reloading the page
                </div>
                <button
                  type="button"
                  onClick={() => this.init()}
                  className="btn btn-inverse-light btn-fw mt-4 mb-5 btn-rounded"
                >
                  Reload
                </button>
              </CenterBlock>
            ) : null}
            {this.state.loading || this.state.initError ? null : !this.context
                .globalState.user.vcnRegistrationNumber ? (
              <this.MustHaveVCNView />
            ) : (
              <CardBlock title="">
                <div className="row">
                  <div className="col-md-12 mb-0">
                    <form className="forms-sample">
                      <div className="form-group row">
                        <div className="col col-md-6">
                          <h4 className="theme-color">
                            Fill new disease report
                          </h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6 mx-auto">
                          <div className="form-group row">
                            <div className="col-12">
                              {this.props.match.params.tagNumber ? (
                                <>
                                  <label className="control-label font-weight-bold text-darker">
                                    Animal Tag Number
                                  </label>
                                  <label className="form-control text-darker">
                                    {this.props.match.params.tagNumber.toUpperCase()}
                                  </label>
                                  {this.state.validateLoading ? (
                                    <Spinner />
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  <label className="control-label font-weight-bold text-darker">
                                    Animal Tag Number
                                  </label>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        this.updateProp(
                                          e.target.value,
                                          "tagNumber"
                                        )
                                      }
                                      className="form-control"
                                    />
                                    <div className="input-group-append">
                                      {this.state.validateLoading ? (
                                        <Spinner />
                                      ) : (
                                        <button
                                          onClick={() =>
                                            this.validateTagNumber()
                                          }
                                          className="btn btn-success"
                                          type="button"
                                        >
                                          Fetch Animal
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {this.state.animal && (
                            <div className="form-group row">
                              <div className="col-12">
                                <label className="control-label font-weight-bold text-darker">
                                  Specie{" "}
                                  <small className="text-danger">*</small>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  readOnly
                                  value={this.state.payload.animalSpecie}
                                />
                              </div>
                            </div>
                          )}

                          {this.state.organs && this.state.organs.length > 0 && (
                            <div className="form-group row">
                              <div className="col-12">
                                <label className="control-label font-weight-bold text-darker">
                                  Organ <small className="text-danger">*</small>
                                </label>
                                <Select
                                  value={this.state.organs.find(
                                    (a) => a.value == this.state.payload.organ
                                  )}
                                  styles={Constants.selectStyles}
                                  options={this.state.organs}
                                  onChange={(option) =>
                                    this.updateOrgan(option)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {this.state.pathologicalDiagnosis &&
                            this.state.pathologicalDiagnosis.length > 0 && (
                              <div className="form-group row">
                                <div className="col-12">
                                  <label className="control-label font-weight-bold text-darker">
                                    Pathological Diagnosis{" "}
                                    <small className="text-danger">*</small>
                                  </label>
                                  <Select
                                    value={this.state.pathologicalDiagnosis.find(
                                      (a) =>
                                        a.value ==
                                        this.state.payload.pathologicalDiagnosis
                                    )}
                                    styles={Constants.selectStyles}
                                    options={this.state.pathologicalDiagnosis}
                                    onChange={(option) =>
                                      this.updateProp(
                                        option.value,
                                        "pathologicalDiagnosis"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          {this.state.payload.animalSpecie && (
                            <div className="form-group row">
                              <div className="col-12">
                                <label className="control-label font-weight-bold text-darker">
                                  {this.state.diseases &&
                                  this.state.diseases.length > 0
                                    ? this.state.diseases[0].diseaseLabel
                                    : "Most likely disease suspected"}
                                  <small className="text-danger">*</small>
                                </label>
                                <Select
                                  value={this.state.diseases.find(
                                    (a) =>
                                      a.value ==
                                      this.state.payload.diseaseSuspected
                                  )}
                                  styles={Constants.selectStyles}
                                  options={this.state.diseases}
                                  onChange={(option) =>
                                    this.updateDisease(option)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {this.state.secondConfirmation && (
                            <div className="form-group row">
                              <div className="col">
                                <this.formControl
                                  label={
                                    <span>
                                      {this.state.secondConfirmation.label}
                                      <br />
                                      <small>
                                        {this.state.secondConfirmation.notes}
                                      </small>
                                    </span>
                                  }
                                  prop="confirmatoryDiagnosis"
                                />
                              </div>
                            </div>
                          )}
                          {this.state.payload.animalSpecie && (
                            <div className="form-group row">
                              <div className="col-12">
                                <label className="control-label font-weight-bold text-darker">
                                  Condemnation{" "}
                                  <small className="text-danger">*</small>
                                </label>
                                <Select
                                  value={this.condemnationOptions.find(
                                    (a) =>
                                      a.value == this.state.payload.condemnation
                                  )}
                                  styles={Constants.selectStyles}
                                  options={this.condemnationOptions}
                                  onChange={(option) =>
                                    this.updateProp(
                                      option.value,
                                      "condemnation"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {this.state.payload.animalSpecie && (
                            <this.formGroupRow
                              colClass="col"
                              children={
                                <>
                                  <label className="control-label font-weight-bold text-darker">
                                    Comments
                                  </label>
                                  <textarea
                                    className="form-control"
                                    onChange={(e) =>
                                      this.updateProp(
                                        e.target.value,
                                        "comments"
                                      )
                                    }
                                  />
                                </>
                              }
                            />
                          )}

                          {this.state.payload.animalSpecie && (
                            <this.formGroupRow
                              colClass="col"
                              children={
                                <>
                                  <label className="control-label font-weight-bold text-darker">
                                    Attach image
                                  </label>
                                  <input
                                    accept=".png, .jpg, .jpeg"
                                    title="Optional: attach an image"
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => this.updateFile(e)}
                                  />
                                </>
                              }
                            />
                          )}
                        </div>
                      </div>
                      {this.state.errorMessage ? (
                        <div className="mb-3">
                          <div
                            className="alert alert-danger small"
                            role="alert"
                          >
                            {this.state.errorMessage}
                          </div>
                        </div>
                      ) : null}

                      {this.state.animal && (
                        <div className="form-group row">
                          <div className="col col-md-3 mx-auto mt-4">
                            <SpinnerButton
                              onClick={() => this.saveReport()}
                              className="btn btn-success mr-2 btn-block btn-rounded"
                              label="Save Disease Report"
                              loading={this.state.saveLoading}
                            />
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </CardBlock>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }
}
