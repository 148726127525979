import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Spinner from "../../Utils/Spinner";
import CertificatesList from "../../Components/CertificatesList";

export default class AllCertificatesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      errorMessage: null,
      user: {},
    };
  }

  componentDidMount() {}

  render() {
    return (
      <MainLayout title="All Certificates">
        <div className="row">
          <div className="col-md-12 mb-5">
            {this.state.loading ? (
              <Spinner />
            ) : (
              <CardBlock>
                <CertificatesList
                  issuedBy={this.props.match.params.vetId || 0}
                  issuedTo={0}
                />
              </CardBlock>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }
}
