import React, { Component } from "react";
import { Link } from "react-router-dom";
import Constants from "./Utils/Constants";

export default function Logo({ icon, style, grayscale, className, reverse }) {
  const logoImage = reverse ? (
    <img src={Constants.subfolder + "/logo-reverse.png"} style={style} />
  ) : grayscale ? (
    <img src={Constants.subfolder + "/logo-grayscale.png"} style={style} />
  ) : icon ? (
    <img src={Constants.subfolder + "/logo-icon.png"} style={style} />
  ) : (
    <img src={Constants.subfolder + "/logo-wide.png"} style={style} />
  );

  return (
    <Link className={className} to="/">
      {logoImage}
    </Link>
  );
}
