export const CountryAndRegions = [
    {
        label: "Algeria",
        value: "Algeria",
        capital: "Algiers",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Adrar",
                value: "Adrar",
            },
            {
                label: "Chlef",
                value: "Chlef",
            },
            {
                label: "Laghouat",
                value: "Laghouat",
            },
            {
                label: "Oum-El-Bouaghi",
                value: "Oum-El-Bouaghi",
            },
            {
                label: "Batna",
                value: "Batna",
            },
        ]
    },
    {
        id: 2,
        label: "Angola",
        value: "Angola",
        capital: "Luanda",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Bengo",
                value: "Bengo"
            },
            {
                label: "Benguela",
                value: "Benguela"
            },
            {
                label: "Bié",
                value: "Bié"
            },
            {
                label: "Cabinda",
                value: "Cabinda"
            },
            {
                label: "Cuando Cubango",
                value: "Cuando Cubango"
            },
            // Other provinces...
        ]
    },


    {
        label: "Benin",
        value: "Benin",
        capital: "Porto-Novo",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Alibori",
                value: "Alibori"
            },
            {
                label: "Atakora",
                value: "Atakora"
            },
            {
                label: "Atlantique",
                value: "Atlantique"
            },
            {
                label: "Borgou",
                value: "Borgou"
            },
            {
                label: "Collines",
                value: "Collines"
            }
        ]
    },
    {
        id: 4,
        label: "Botswana",
        value: "Botsuana",
        capital: "Gaborone",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Central",
                value: "Central"
            },
            {
                label: "Ghanzi",
                value: "Ghanzi"
            },
            {
                label: "Kgalagadi",
                value: "Kgalagadi"
            },
            {
                label: "Kgatleng",
                value: "Kgatleng"
            },
            {
                label: "Kweneng",
                value: "Kweneng"
            }
        ]
    },
    {
        id: 5,
        label: "Burkina-Faso",
        value: "Burkina-Faso",

        capital: "Ouagadougou",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Balé",
                value: "Balé"
            },
            {
                label: "Bam",
                value: "Bam"
            },
            {
                label: "Banwa",
                value: "Banwa"
            },
            {
                label: "Bazèga",
                value: "Bazèga"
            },
            {
                label: "Boucle du Mouhoun",
                value: "Boucle du Mouhoun"
            }
        ]
    },
    {
        id: 6,
        label: "Burundi",
        value: "Burundi",
        capital: "Bujumbura",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Bubanza",
                value: "Bubanza"
            },
            {
                label: "Bujumbura Mairie",
                value: "Bujumbura Mairie"
            },
            {
                label: "Bujumbura Rural",
                value: "Bujumbura Rural"
            },
            {
                label: "Bururi",
                value: "Bururi"
            },
            {
                label: "Cankuzo",
                value: "Cankuzo"
            },
            // Other provinces...
        ]
    },
    {
        id: 7,
        label: "Cameroon",
        value: "Cameroon",
        capital: "Yaoundé",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Adamaoua",
                value: "Adamaoua"
            },
            {
                label: "Centre",
                value: "Centre"
            },
            {
                label: "East",
                value: "East"
            },
            {
                label: "Far North",
                value: "Far North"
            },
            {
                label: "Littoral",
                value: "Littoral"
            }
        ]
    },
    {
        id: 8,
        label: "Cape Verde",
        label: "Cape-Verde",
        capital: "Praia",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Boa Vista",
                value: "Boa Vista"
            },
            {
                label: "Brava",
                value: "Brava"
            },
            {
                label: "Maio",
                value: "Maio"
            },
            {
                label: "Mosteiros",
                value: "Mosteiros"
            },
            {
                label: "Paúl",
                value: "Paúl"
            },
            {
                label: "Ribeira Brava",
                value: "Ribeira Brava"
            },
            {
                label: "Ribeira Grande",
                value: "Ribeira Grande"
            },
            {
                label: "Sal",
                value: "Sal"
            },
            {
                label: "Santa Catarina",
                value: "Santa Catarina"
            },
            {
                label: "Santa Cruz",
                value: "Santa Cruz"
            }
        ]
    },
    {
        id: 9,
        label: "Central African Republic",
        label: "Central-African-Republic",
        capital: "Bangui",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Bamingui-Bangoran",
                value: "Bamingui-Bangoran"
            },
            {
                label: "Bangui",
                value: "Bangui"
            },
            {
                label: "Basse-Kotto",
                value: "Basse-Kotto"
            },
            {
                label: "Haute-Kotto",
                value: "Haute-Kotto"
            },
            {
                label: "Haut-Mbomou",
                value: "Haut-Mbomou"
            },
            {
                label: "Mambéré-Kadéï",
                value: "Mambéré-Kadéï"
            },
            {
                label: "Nana-Gribizi",
                value: "Nana-Gribizi"
            },
            {
                label: "Nana-Mambéré",
                value: "Nana-Mambéré"
            },
            {
                label: "Ombella-M'Poko",
                value: "Ombella-M'Poko"
            },
            {
                label: "Ouaka",
                value: "Ouaka"
            }
        ]
    },
    // {
    //     id: 10,
    //     label: "Chad",
    //     capital: "N'Djamena",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Bahr el Gazel",
    //         "Batha",
    //         "Borkou",
    //         "Ennedi",
    //         "Guéra"
    //     ]
    // },
    // Additional countries...
    // {
    //     id: 11,
    //     label: "Comoros",
    //     capital: "Moroni",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Anjouan",
    //         "Grande Comore",
    //         "Mohéli"
    //     ]
    // },
    {
        id: 12,
        label: "Congo",
        value: "Congo",
        capital: "Brazzaville",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Bouenza",
                value: "Bouenza"
            },
            {
                label: "Cuvette",
                value: "Cuvette"
            },
            {
                label: "Cuvette-Ouest",
                value: "Cuvette-Ouest"
            },
            {
                label: "Kouilou",
                value: "Kouilou"
            },
            {
                label: "Lékoumou",
                value: "Lékoumou"
            },
            {
                label: "Likouala",
                value: "Likouala"
            },
            {
                label: "Niari",
                value: "Niari"
            },
            {
                label: "Plateaux",
                value: "Plateaux"
            },
            {
                label: "Pointe-Noire",
                value: "Pointe-Noire"
            },
            {
                label: "Pool",
                value: "Pool"
            }
        ]
    },
    // {
    //     id: 13,
    //     label: "Congo, Democratic Republic of the",
    //     capital: "Kinshasa",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Bandundu",
    //         "Bas-Congo",
    //         "Équateur",
    //         "Haut-Congo",
    //         "Kasaï-Occidental"
    //     ]
    // },
    {
        id: 14,
        label: "Côte d'Ivoire",
        value: "Côte-d'Ivoire",
        capital: "Yamoussoukro",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Abidjan",
                value: "Abidjan"
            },
            {
                label: "Bas-Sassandra",
                value: "Bas-Sassandra"
            },
            {
                label: "Denguélé",
                value: "Denguélé"
            },
            {
                label: "Gôh-Djiboua",
                value: "Gôh-Djiboua"
            },
            {
                label: "Lacs",
                value: "Lacs"
            },
            {
                label: "Savanes",
                value: "Savanes"
            },
            {
                label: "Vallée du Bandama",
                value: "Vallée du Bandama"
            },
            {
                label: "Woroba",
                value: "Woroba"
            },
            {
                label: "Zanzan",
                value: "Zanzan"
            }
        ]
    },
    // {
    //     id: 15,
    //     label: "Djibouti",
    //     capital: "Djibouti",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Ali Sabieh",
    //         "Arta",
    //         "Dikhil",
    //         "Djibouti",
    //         "Obock"
    //     ]
    // },
    {
        id: 16,
        label: "Egypt",
        value: "Egypt",
        capital: "Cairo",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Alexandria",
                value: "Alexandria"
            },
            {
                label: "Aswan",
                value: "Aswan"
            },
            {
                label: "Asyut",
                value: "Asyut"
            },
            {
                label: "Cairo",
                value: "Cairo"
            },
            {
                label: "Dakahlia",
                value: "Dakahlia"
            },
            {
                label: "Giza",
                value: "Giza"
            },
            {
                label: "Luxor",
                value: "Luxor"
            },
            {
                label: "Port Said",
                value: "Port Said"
            },
            {
                label: "Suez",
                value: "Suez"
            }
        ]
    },
    {
        id: 17,
        label: "Equatorial Guinea",
        value: "Equatorial-Guinea",
        capital: "Malabo",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Annobón",
                value: "Annobón"
            },
            {
                label: "Bioko Norte",
                value: "Bioko Norte"
            },
            {
                label: "Bioko Sur",
                value: "Bioko Sur"
            },
            {
                label: "Centro Sur",
                value: "Centro Sur"
            },
            {
                label: "Kié-Ntem",
                value: "Kié-Ntem"
            },
            {
                label: "Litoral",
                value: "Litoral"
            },
            {
                label: "Wele-Nzas",
                value: "Wele-Nzas"
            },
            {
                label: "Sul",
                value: "Sul"
            },
            {
                label: "Norte",
                value: "Norte"
            }
        ]
    },
    // {
    //     id: 18,
    //     label: "Eritrea",
    //     capital: "Asmara",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Anseba",
    //         "Debub",
    //         "Gash-Barka",
    //         "Maekel",
    //         "Northern Red Sea"
    //     ]
    // },
    // {
    //     id: 19,
    //     label: "Eswatini",
    //     capital: "Mbabane",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Hhohho",
    //         "Lubombo",
    //         "Manzini",
    //         "Shiselweni"
    //     ]
    // },
    {
        id: 20,
        label: "Ethiopia",
        value: "Ethiopia",
        capital: "Addis Ababa",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Addis Ababa",
                value: "Addis Ababa"
            },
            {
                label: "Afar",
                value: "Afar"
            },
            {
                label: "Amhara",
                value: "Amhara"
            },
            {
                label: "Benishangul-Gumuz",
                value: "Benishangul-Gumuz"
            },
            {
                label: "Dire Dawa",
                value: "Dire Dawa"
            },
            {
                label: "Gambela",
                value: "Gambela"
            },
            {
                label: "Harari",
                value: "Harari"
            },
            {
                label: "Oromia",
                value: "Oromia"
            },
            {
                label: "Sidama",
                value: "Sidama"
            },
            {
                label: "Somali",
                value: "Somali"
            },
            {
                label: "Southern Nations, Nationalities, and Peoples' Region",
                value: "Southern Nations, Nationalities, and Peoples' Region"
            },
            {
                label: "Tigray",
                value: "Tigray"
            }
        ]
    },
    // {
    //     id: 21,
    //     label: "Gabon",
    //     capital: "Libreville",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Estuaire",
    //         "Haut-Ogooué",
    //         "Moyen-Ogooué",
    //         "Ngounié",
    //         "Nyanga"
    //     ]
    // },
    // {
    //     id: 22,
    //     label: "Gambia",
    //     capital: "Banjul",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Banjul",
    //         "Central River",
    //         "Lower River",
    //         "North Bank",
    //         "Upper River"
    //     ]
    // },
    {
        id: 23,
        label: "Ghana",
        value: "Ghana",
        capital: "Accra",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Ashanti",
                value: "Ashanti"
            },
            {
                label: "Brong-Ahafo",
                value: "Brong-Ahafo"
            },
            {
                label: "Central",
                value: "Central"
            },
            {
                label: "Eastern",
                value: "Eastern"
            },
            {
                label: "Greater Accra",
                value: "Greater Accra"
            },
            {
                label: "Northern",
                value: "Northern"
            },
            {
                label: "Upper East",
                value: "Upper East"
            },
            {
                label: "Upper West",
                value: "Upper West"
            },
            {
                label: "Volta",
                value: "Volta"
            },
            {
                label: "Western",
                value: "Western"
            }
        ]
    },
    {
        id: 24,
        label: "Guinea",
        value: "Guinea",
        capital: "Conakry",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Boké",
                value: "Boké"
            },
            {
                label: "Conakry",
                value: "Conakry"
            },
            {
                label: "Faranah",
                value: "Faranah"
            },
            {
                label: "Kankan",
                value: "Kankan"
            },
            {
                label: "Kindia",
                value: "Kindia"
            },
            {
                label: "Labé",
                value: "Labé"
            },
            {
                label: "Mamou",
                value: "Mamou"
            },
            {
                label: "Nzérékoré",
                value: "Nzérékoré"
            },
            {
                label: "Siguiri",
                value: "Siguiri"
            },
            {
                label: "Tougué",
                value: "Tougué"
            }
        ]
    },
    // {
    //     id: 25,
    //     label: "Guinea-Bissau",
    //     capital: "Bissau",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Bafatá",
    //         "Biombo",
    //         "Bissau",
    //         "Bolama-Bijagos",
    //         "Cacheu"
    //     ]
    // },
    {
        id: 26,
        label: "Kenya",
        value: "Kenya",
        capital: "Nairobi",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Nairobi",
                value: "Nairobi"
            },
            {
                label: "Baringo",
                value: "Baringo"
            },
            {
                label: "Bomet",
                value: "Bomet"
            },
            {
                label: "Bungoma",
                value: "Bungoma"
            },
            {
                label: "Busia",
                value: "Busia"
            },
            {
                label: "Elgeyo-Marakwet",
                value: "Elgeyo-Marakwet"
            },
            {
                label: "Embu",
                value: "Embu"
            },
            {
                label: "Garissa",
                value: "Garissa"
            },
            {
                label: "Homa Bay",
                value: "Homa Bay"
            },
            {
                label: "Isiolo",
                value: "Isiolo"
            },
            {
                label: "Kajiado",
                value: "Kajiado"
            }
        ]
    },
    {
        id: 27,
        label: "Lesotho",
        value: "Lesotho",
        capital: "Maseru",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Maseru",
                value: "Maseru"
            },
            {
                label: "Berea",
                value: "Berea"
            },
            {
                label: "Butha-Buthe",
                value: "Butha-Buthe"
            },
            {
                label: "Leribe",
                value: "Leribe"
            },
            {
                label: "Mafeteng",
                value: "Mafeteng"
            },
            {
                label: "Maseru",
                value: "Maseru"
            },
            {
                label: "Mohale's Hoek",
                value: "Mohale's Hoek"
            },
            {
                label: "Mokhotlong",
                value: "Mokhotlong"
            },
            {
                label: "Qacha's Nek",
                value: "Qacha's Nek"
            },
            {
                label: "Quthing",
                value: "Quthing"
            },
            {
                label: "Thaba-Tseka",
                value: "Thaba-Tseka"
            }
        ]
    },
    // {
    //     id: 28,
    //     label: "Liberia",
    //     capital: "Monrovia",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Bomi",
    //         "Bong",
    //         "Gbarpolu",
    //         "Grand Bassa",
    //         "Grand Cape Mount"
    //     ]
    // },
    // {
    //     id: 29,
    //     label: "Libya",
    //     capital: "Tripoli",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Al Wahat",
    //         "Benghazi",
    //         "Derna",
    //         "Jabal al Akhdar",
    //         "Jabal al Gharbi"
    //     ]
    // },

    // {
    //     id: 30,
    //     label: "Madagascar",
    //     capital: "Antananarivo",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Antananarivo",
    //         "Antsiranana",
    //         "Fianarantsoa",
    //         "Mahajanga",
    //         "Toamasina"
    //     ]
    // },
    {
        id: 31,
        label: "Malawi",
        value: "Malawi",
        capital: "Lilongwe",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Central Region",
                value: "Central Region"
            },
            {
                label: "Northern Region",
                value: "Northern Region"
            },
            {
                label: "Southern Region",
                value: "Southern Region"
            },
            {
                label: "Eastern Region",
                value: "Eastern Region"
            },
            {
                label: "Volta Region",
                value: "Volta Region"
            },
            {
                label: "Western Region",
                value: "Western Region"
            }
        ]
    },
    {
        id: 32,
        label: "Mali",
        value: "Mali",
        capital: "Bamako",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Bamako",
                value: "Bamako"
            },
            {
                label: "Gao",
                value: "Gao"
            },
            {
                label: "Kayes",
                value: "Kayes"
            },
            {
                label: "Kidal",
                value: "Kidal"
            },
            {
                label: "Koulikoro",
                value: "Koulikoro"
            },
            {
                label: "Mopti",
                value: "Mopti"
            },
            {
                label: "Ségou",
                value: "Ségou"
            },
            {
                label: "Sikasso",
                value: "Sikasso"
            }
        ]
    },
    // {
    //     id: 33,
    //     label: "Mauritania",
    //     capital: "Nouakchott",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Adrar",
    //         "Assaba",
    //         "Brakna",
    //         "Dakhlet Nouadhibou",
    //         "Gorgol"
    //     ]
    // },
    // {
    //     id: 34,
    //     label: "Mauritius",
    //     capital: "Port Louis",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Black River",
    //         "Flacq",
    //         "Grand Port",
    //         "Moka",
    //         "Pamplemousses"
    //     ]
    // },
    {
        id: 35,
        label: "Morocco",
        value: "Morocco",
        capital: "Rabat",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Agadir-Ida-Ou Tanane",
                value: "Agadir-Ida-Ou Tanane"
            },
            {
                label: "Béni Mellal-Khénifra",
                value: "Béni Mellal-Khénifra"
            },
            {
                label: "Casablanca-Settat",
                value: "Casablanca-Settat"
            },
            {
                label: "Draa-Tafilalet",
                value: "Draa-Tafilalet"
            },
            {
                label: "Fès-Meknès",
                value: "Fès-Meknès"
            },
            {
                label: "Guelmim-Oued Noun",
                value: "Guelmim-Oued Noun"
            },
            {
                label: "Laâyoune-Sakia El Hamra",
                value: "Laâyoune-Sakia El Hamra"
            },
            {
                label: "Marrakesh-Safi",
                value: "Marrakesh-Safi"
            }
        ]
    },
    {
        id: 36,
        label: "Mozambique",
        value: "Mozambique",
        capital: "Maputo",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Cabo Delgado",
                value: "Cabo Delgado"
            },
            {
                label: "Gaza",
                value: "Gaza"
            },
            {
                label: "Inhambane",
                value: "Inhambane"
            },
            {
                label: "Manica",
                value: "Manica"
            },
            {
                label: "Maputo",
                value: "Maputo"
            },
            {
                label: "Nampula",
                value: "Nampula"
            },
            {
                label: "Niassa",
                value: "Niassa"
            },
            {
                label: "Sofala",
                value: "Sofala"
            }
        ]
    },
    // {
    //     id: 37,
    //     label: "Namibia",
    //     capital: "Windhoek",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Erongo",
    //         "Hardap",
    //         "Karas",
    //         "Kavango East",
    //         "Kavango West"
    //     ]
    // },
    // {
    //     id: 38,
    //     label: "Niger",
    //     capital: "Niamey",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Agadez",
    //         "Diffa",
    //         "Dosso",
    //         "Maradi",
    //         "Tahoua"
    //     ]
    // },
    // {
    //     id: 38,
    //     label: "Niger",
    //     capital: "Niamey",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Agadez",
    //         "Diffa",
    //         "Dosso",
    //         "Maradi",
    //         "Tahoua"
    //     ]
    // },
    {
        id: 39,
        label: "Nigeria",
        value: "Nigeria",
        capital: "Abuja",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Lagos",
                value: "Lagos"
            },
            {
                label: "Abia",
                value: "Abia"
            },
            {
                label: "Adamawa",
                value: "Adamawa"
            },
            {
                label: "Akwa Ibom",
                value: "Akwa Ibom"
            },
            {
                label: "Anambra",
                value: "Anambra"
            },
            {
                label: "Bauchi",
                value: "Bauchi"
            },
            {
                label: "Bayelsa",
                value: "Bayelsa"
            },
            {
                label: "Benue",
                value: "Benue"
            },
            {
                label: "Borno",
                value: "Borno"
            }
        ]
    },
    {
        id: 40,
        label: "Rwanda",
        value: "Rwanda",
        capital: "Kigali",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Kigali",
                value: "Kigali"
            },
            {
                label: "Eastern Province",
                value: "Eastern Province"
            },
            {
                label: "Kigali",
                value: "Kigali"
            },
            {
                label: "Northern Province",
                value: "Northern Province"
            },
            {
                label: "Southern Province",
                value: "Southern Province"
            },
            {
                label: "Western Province",
                value: "Western Province"
            },
            {
                label: "Southern Province",
                value: "Southern Province"
            },
            {
                label: "Western Province",
                value: "Western Province"
            },
            {
                label: "Eastern Province",
                value: "Eastern Province"
            }
        ]
    },
    // {
    //     id: 41,
    //     label: "São Tomé and Príncipe",
    //     capital: "São Tomé",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Príncipe",
    //         "São Tomé"
    //     ]
    // },
    {
        id: 42,
        label: "Senegal",
        value: "Senegal",
        capital: "Dakar",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Dakar",
                value: "Dakar"
            },
            {
                label: "Diourbel",
                value: "Diourbel"
            },
            {
                label: "Fatick",
                value: "Fatick"
            },
            {
                label: "Kaffrine",
                value: "Kaffrine"
            },
            {
                label: "Kaolack",
                value: "Kaolack"
            },
            {
                label: "Thiès",
                value: "Thiès"
            },
            {
                label: "Louga",
                value: "Louga"
            },
            {
                label: "Saint-Louis",
                value: "Saint-Louis"
            }
        ]
    },
    // {
    //     id: 43,
    //     label: "Seychelles",
    //     capital: "Victoria",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Anse Boileau",
    //         "Anse Etoile",
    //         "Anse Royale",
    //         "Baie Lazare",
    //         "Baie Sainte Anne"
    //     ]
    // },
    {
        id: 44,
        label: "Sierra Leone",
        value: "Sierra-Leone",
        capital: "Freetown",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Eastern",
                value: "Eastern"
            },
            {
                label: "Northern",
                value: "Northern"
            },
            {
                label: "Southern",
                value: "Southern"
            },
            {
                label: "Western Area",
                value: "Western Area"
            },
            {
                label: "North West",
                value: "North West"
            },
            {
                label: "South West",
                value: "South West"
            },
            {
                label: "Kambia",
                value: "Kambia"
            }
        ]
    },
    // {
    //     id: 45,
    //     label: "Somalia",
    //     capital: "Mogadishu",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Awdal",
    //         "Bakool",
    //         "Banaadir",
    //         "Bari",
    //         "Bay"
    //     ]
    // },
    {
        id: 46,
        label: "South Africa",
        value: "South-Africa",
        capital: "Pretoria",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Pretoria",
                value: "Pretoria"
            },
            {
                label: "Eastern Cape",
                value: "Eastern Cape"
            },
            {
                label: "Free State",
                value: "Free State"
            },
            {
                label: "Gauteng",
                value: "Gauteng"
            },
            {
                label: "KwaZulu-Natal",
                value: "KwaZulu-Natal"
            },
            {
                label: "Limpopo",
                value: "Limpopo"
            },
            {
                label: "Mpumalanga",
                value: "Mpumalanga"
            },
            {
                label: "North West",
                value: "North West"
            },
            {
                label: "Northern Cape",
                value: "Northern Cape"
            },
            {
                label: "Western Cape",
                value: "Western Cape"
            }
        ]
    },
    // {
    //     id: 47,
    //     label: "South Sudan",
    //     capital: "Juba",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Central Equatoria",
    //         "Eastern Equatoria",
    //         "Jonglei",
    //         "Lakes",
    //         "Northern Bahr el Ghazal"
    //     ]
    // },
    // {
    //     id: 48,
    //     label: "Sudan",
    //     capital: "Khartoum",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Al Jazirah",
    //         "Blue Nile",
    //         "Central Darfur",
    //         "East Darfur",
    //         "Kassala"
    //     ]
    // },
    {
        id: 49,
        label: "Tanzania",
        value: "Tanzania",
        capital: "Dodoma",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Arusha",
                value: "Arusha"
            },
            {
                label: "Dar es Salaam",
                value: "Dar es Salaam"
            },
            {
                label: "Dodoma",
                value: "Dodoma"
            },
            {
                label: "Geita",
                value: "Geita"
            },
            {
                label: "Iringa",
                value: "Iringa"
            },
            // Other regions...
        ]
    },
    // {
    //     id: 50,
    //     label: "Togo",
    //     capital: "Lomé",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Centrale",
    //         "Kara",
    //         "Maritime",
    //         "Plateaux",
    //         "Savannes"
    //     ]
    // },
    {
        id: 51,
        label: "Tunisia",
        value: "Tunisia",
        capital: "Tunis",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Arusha",
                value: "Arusha"
            },
            {
                label: "Dar es Salaam",
                value: "Dar es Salaam"
            },
            {
                label: "Dodoma",
                value: "Dodoma"
            },
            {
                label: "Geita",
                value: "Geita"
            },
            {
                label: "Iringa",
                value: "Iringa"
            },
            // Other regions...
        ]
    },
    {
        id: 52,
        label: "Uganda",
        value: "Uganda",
        capital: "Kampala",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Central",
                value: "Central"
            },
            {
                label: "Eastern",
                value: "Eastern"
            },
            {
                label: "Northern",
                value: "Northern"
            },
            {
                label: "Western",
                value: "Western"
            },
            // Other provinces...
        ]
    },
    // {
    //     id: 53,
    //     label: "Zambia",
    //     capital: "Lusaka",
    //     geo_political_zone: "N/A",
    //     subdivisions: [
    //         "Central",
    //         "Copperbelt",
    //         "Eastern",
    //         "Luapula",
    //         "Lusaka"
    //     ]
    // },
    {
        id: 54,
        label: "Zimbabwe",
        value: "Zimbabwe",
        capital: "Harare",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Bulawayo",
                value: "Bulawayo"
            },
            {
                label: "Harare",
                value: "Harare"
            },
            {
                label: "Manicaland",
                value: "Manicaland"
            },
            {
                label: "Mashonaland Central",
                value: "Mashonaland Central"
            },
            {
                label: "Mashonaland East",
                value: "Mashonaland East"
            },
            // Other provinces...
        ]
    },

    {
        label: "Luanda",
        value: "Luanda",
        capital: "Luanda",
        geo_political_zone: "N/A",
        subdivisions: [
            {
                label: "Bengo",
                value: "Bengo"
            },
            {
                label: "Benguela",
                value: "Benguela"
            },
            {
                label: "Bié",
                value: "Bié"
            },
            {
                label: "Cabinda",
                value: "Cabinda"
            },
            {
                label: "Cuando Cubango",
                value: "Cuando Cubango"
            }
        ]
    },
]
