import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import RanchAnimalList from "../../Components/RanchAnimalList";
import { DataContext } from "../../Contexts/DataContexts";
import Select from "react-select";
import { CountryAndRegions } from "../../Utils/CountryAndRegions";
import Constants from "../../Utils/Constants";
import CardBlock from "../../Components/CardBlock";
import MainLayout from "../../Components/Layouts/MainLayout";
import SpinnerButton from "../../Utils/SpinnerButton";
import APICall from "../../Utils/APICall";
import Toastr from "../../Utils/Toastr";

const AnimalsPage = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveErrorMessage, setSaveErrorMessage] = useState(null);
  const [state, setState] = useState({});
  const [payload, setPayload] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [animalConfiguration, setAnimalConfiguration] = useState({});
  const [species, setSpecies] = useState([]);
  const [subSpecies, setSubSpecies] = useState([]);
  const [animalColors, setAnimalColors] = useState([]);
  const [nigerianStates, setNigerianStates] = useState([]);
  const [paymentChannel, setPaymentChannel] = useState(null);
  const [amountPayable, setAmountPayable] = useState(0);
  const [countryy, setCountryy] = useState("");
  const [statee, setStatee] = useState("");
  const [animal, setAnimal] = useState("");
  const { globalState } = useContext(DataContext);
  const [profile, setProfile] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [country, setCountry] = useState(null);



  const context = useContext(DataContext);

  const genders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const colors = [
    { label: "Black", value: "Black" },
    { label: "White", value: "White" },
    { label: "Gray", value: "Gray" },
    { label: "Ash", value: "Ash" },
  ];

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  useEffect(() => {
    setProfile(globalState?.data);
    setUserRole(globalState?.user?.userRole);
    setCountryy(globalState?.user?.country);
    setState(globalState?.user?.state);
    updateAnimalState();
  }, [globalState]);

  const updatePayload = (value, index, forUpdate) => {
    let newPayload = forUpdate ? { ...selectedRecord } : { ...payload };
    newPayload[index] = value;
    forUpdate ? setSelectedRecord(newPayload) : setPayload(newPayload);
  };

  const updateProp = (value, prop) => {
    if (prop === "specie") {
      // Assuming you have a function called loadSpeciesConfigs
      // this.loadSpeciesConfigs(value);
      // this.updateProp(null, "subSpecie");
      // this.updateProp(null, "color");
    }

    let newPayload = { ...payload };
    if (!newPayload) newPayload = {};
    newPayload[prop] = value;
    setPayload(newPayload, () => {
      if (prop === "weight" || prop === "specie") {
        // Assuming you have a function called calculateAmount
        // this.calculateAmount();
      }
    });
  };


  const newRecordForm = () => {
    return (
      <div className="mymodal-wrapper" style={{ width: "400px" }}>
        <h5>Add {props.match.params.animal}</h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                placeholder="Type"
                onChange={(e) => updatePayload(e.target.value, "animalType")}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <label className="control-label font-weight-bold text-darker">
                Gender
              </label>
              <Select
                value={genders.find((a) => a.value == payload.gender)}
                styles={Constants.selectStyles}
                options={genders}
                onChange={(option) => updateProp(option.value, "gender")}
              />
            </div>
            <div className="col">
              <label className="control-label font-weight-bold text-darker">
                Color
              </label>
              <Select
                value={animalColors.find((a) => a.value == payload.color)}
                styles={Constants.selectStyles}
                options={colors}
                onChange={(option) => updateProp(option.value, "color")}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <label className="control-label font-weight-bold text-darker">
                Sire
              </label>
              <input
                type="text"
                placeholder="Sire"
                onChange={(e) => updatePayload(e.target.value, "sire")}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col">
              <label className="control-label font-weight-bold text-darker">
                Dam
              </label>
              <input
                type="text"
                placeholder="Dam"
                onChange={(e) => updatePayload(e.target.value, "dam")}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="number"
                placeholder="Weight at Birth"
                onChange={(e) => updatePayload(e.target.value, "weight")}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          {saveErrorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {saveErrorMessage}
              </div>
            </div>
          ) : null}
          <SpinnerButton
            onClick={() => addRecord()}
            className="btn btn-success mr-2 btn-block mb-3 mt-3 btn-rounded"
            label="Add Animal"
            loading={loading}
          />
        </form>
      </div>
    );
  };

  const updateRecordForm = () => {
    return (
      <div className="mymodal-wrapper" style={{ width: "400px" }}>
        <h5>Update {props.match.params.animal}</h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                defaultValue={selectedRecord.name}
                placeholder="Officer's name"
                onChange={(e) =>
                  updatePayload(e.target.value, "contactName", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                defaultValue={selectedRecord.phoneNumber}
                placeholder="Phone number"
                onChange={(e) =>
                  updatePayload(e.target.value, "phoneNumber", true)
                }
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="email"
                defaultValue={selectedRecord.email}
                placeholder="Email address"
                onChange={(e) => updatePayload(e.target.value, "email", true)}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          {context.globalState.user.userRole.includes("superadmin") && (
            <div className="form-group row">
              <div className="col">
                <Select
                  options={CountryAndRegions}
                  styles={Constants.selectStyles}
                  onChange={(option) => updatePayload(option.value, "country")}
                />
              </div>
              <div className="col">
                <Select
                  options={nigerianStates}
                  styles={Constants.selectStyles}
                  onChange={(option) => updatePayload(option.value, "state")}
                />
              </div>
            </div>
          )}
          {saveErrorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {saveErrorMessage}
              </div>
            </div>
          ) : null}
          <SpinnerButton
            onClick={() => updateRecord()}
            className="btn btn-success mr-2 btn-block mb-3 mt-3 btn-rounded"
            label="Update Record"
            loading={loading}
          />
        </form>
      </div>
    );
  };

  const addRecord = () => {
    setLoading(true);
    setSaveErrorMessage(null);
    APICall("api/users/register/vet", "POST", payload)
      .then((data) => {
        var response = data.data;
        setLoading(false);
        if (response.isSuccess) {
          Toastr("success", "New veterinary officer added");
          setModalIsOpen(false);
          setPayload({});
        } else {
          setSaveErrorMessage(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setSaveErrorMessage(
          "An error occurred, please check your network"
        );
      });
  };

  const updateRecord = () => {
    let updatedPayload = {
      id: selectedRecord.id,
      contactName: payload.contactName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
    };

    setLoading(true);
    setSaveErrorMessage(null);
    APICall("api/vets/update", "POST", updatedPayload)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          Toastr("success", "Record updated");
          setSelectedRecord(null);
          setPayload({});
          setLoading(false);
        } else {
          setSaveErrorMessage(response.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setSaveErrorMessage(
          "An error occurred, please check your network"
        );
      });
  };

  const selectRecord = (record) => {
    if (context.globalState.user.userRole.includes("admin")) {
      setSelectedRecord(record);
      setPayload(record);
    }
  };

  useEffect(() => {
    setAnimal(props.match.params.animal);
    updateAnimalState();
  }, [props.match.params.animal]);

  const updateAnimalState = () => {
    const { match } = props;
    setAnimal(props.match.params.animal)
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        contentLabel=""
        style={Constants.defaultModalStyle}
        onRequestClose={() => {
          setModalIsOpen(false);
          setPayload({});
        }}
      >
        {newRecordForm()}
      </Modal>

      <Modal
        isOpen={selectedRecord?.id ? true : false}
        contentLabel=""
        style={Constants.defaultModalStyle}
        onRequestClose={() => {
          setSelectedRecord(null);
          setPayload({});
        }}
      >
        {selectedRecord && updateRecordForm()}
      </Modal>

      <MainLayout
        title={
          <>
            {animal}s
            <Link
              to={`/in/ranch/birth-Info/${animal}`}
              className="no-decoration"
            >
              <button
                className="btn-sm btn btn-success btn-fw float-right btn-rounded"
              >
                <i className="mdi mdi-account-plus mr-1"></i> Add New {animal}
              </button>
            </Link>
          </>
        }
      >
        <div className="row">
          <div className="col-md-12 mb-5">
            <CardBlock>
              <div className="text-right"></div>
              {console.log("xxx", animal)}
              {selectedRecord ? null : modalIsOpen || <RanchAnimalList selectRecord={selectRecord} animal={animal} />}
            </CardBlock>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default AnimalsPage;
