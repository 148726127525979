import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../Logo";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import "react-phone-input-2/lib/material.css";
import "../../sass/react-phone.scss";
import Constants from "../../Utils/Constants";
import PublicWithTopStripBackground from "../../Components/Layouts/PublicWithTopStripBackground";
import { DataContext } from "../../Contexts/DataContexts";
import Select from "react-select";
import { CountryAndRegions } from "../../Utils/CountryAndRegions";
export default class RegisterCompleteRegular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {},
      errorMessage: null,
      loading: false,
      redirectUrl: null,
      states: [],

    };
  }

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
    if (!this.props.match.params.registrationCode) {
      window.location.href = "/register";
    }
  }



  static contextType = DataContext;
  componentDidUpdate(prevProps, prevState) {
    if (prevState.country !== this.state.payload?.country) {
      const countryName = this.state.payload?.country;
      const states = this.getSubdivisionsByCountry(countryName);
      if (states !== this.state.states) {
        this.setState({ states: states });
      }
    }
  }

  getSubdivisionsByCountry(countryName) {
    const country = CountryAndRegions.find(item => item.label === countryName);
    if (country) {
      return country.subdivisions;
    } else {
      return [];
    }
  }

  updateProp(e, prop, value) {
    var record = this.state.payload;
    record[prop] = value ? value : e.target.value;
    this.setState({ payload: record });
  }



  updatePayload(value, index) {
    let payload = this.state.payload;
    payload[index] = value;
    this.setState({ payload: payload });
  }

  sendToServer() {
    let payload = this.state.payload;
    payload.role = "regular";
    APICall(
      `api/users/register/complete/${this.props.match.params.registrationCode}`,
      "POST",
      payload
    )
      .then((data) => {
        if (data.data.isSuccess) {
          this.setState({
            redirectUrl: "/registration-successful/" + data.data.data,
          });
        } else {
          this.setState({ errorMessage: data.data.message, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  submit() {
    if (
      !this.state.payload.contactName ||
      this.state.payload.contactName.trim() == ""
    ) {
      this.setState({ errorMessage: "Enter your full name" });
      return;
    }

    if (!this.state.payload.email || this.state.payload.email.trim() == "") {
      this.setState({ errorMessage: "Enter your email address" });
      return;
    }

    this.setState({ errorMessage: null, loading: true }, () => {
      this.sendToServer();
    });
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <PublicWithTopStripBackground
        children={
          <div className="col-lg-4 mx-auto">
            <div
              className="auth-form-light text-left px-3"
              style={{ borderRadius: "15px" }}
            >
              <div className="brand-logo">
                <Logo />
              </div>
              <h4>
                User Registration:{" "}
                <span className="text-darker">Final step</span>
              </h4>
              <h6 className="font-weight-light">
                Tell us a little more about you.
              </h6>
              <form className="pt-3" autoComplete="newpassword">
                <div className="form-group" style={{ marginBottom: "1rem" }}>
                  <input
                    onChange={(e) => this.updatePayload(e.target.value, "contactName")}
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Full name / Company name *"
                  />
                </div>
                <div className="form-group" style={{ marginBottom: "1rem" }}>
                  <input
                    onChange={(e) => this.updatePayload(e.target.value, "email")}
                    type="email"
                    className="form-control form-control-sm"
                    placeholder="Your email *"
                  />
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Select
                      options={CountryAndRegions}
                      styles={Constants.selectStyles}
                      onChange={(option) =>
                        this.updatePayload(option.value, "country")
                      } />
                  </div>
                  <div className="col">
                    <Select
                      options={this.state.states}
                      styles={Constants.selectStyles}
                      onChange={(option) =>
                        this.updatePayload(option.value, "state")
                      }
                    />
                  </div>
                </div>
                {this.state.errorMessage ? (
                  <div className="mb-3">
                    <div className="alert alert-danger small" role="alert">
                      {this.state.errorMessage}
                    </div>
                  </div>
                ) : null}

                <div className="mt-3">
                  {this.state.loading ? (
                    <button
                      type="button"
                      className="btn btn-block btn-light btn-lg font-weight-medium auth-form-btn"
                    >
                      <Spinner size="1" />{" "}
                    </button>
                  ) : (
                    <button
                      id="confirm-phone-button"
                      type="button"
                      onClick={() => this.submit()}
                      className="btn-rounded btn btn-block btn-success btn-lg font-weight-medium auth-form-btn"
                    >
                      SUBMIT
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        }
      />
    );
  }
}
