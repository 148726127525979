import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import "../../sass/kanban.scss";
import "moment/locale/en-gb.js";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../../sass/doctors-calendar.scss";
import moment from "moment";
import Modal from "react-modal";
import Select from "react-select";
import DataAPI from "../../Utils/DataAPI";
import Functions from "../../Utils/Functions";
import { DataContext } from "../../Contexts/DataContexts";



export default class SendTestSamplePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      errorMessage: null,
      redirectUrl: null,
      payload: {},
      retailOutlets: [],
      laboratories: [],
    };

    this.formControl = this.formControl.bind(this);
  }


  static contextType = DataContext;


  init = async () => {
    const { globalState, addItem } = this.context;
    this.setState({ profile: globalState?.data })
    const { state, country } = globalState?.user
    console.log(state, country)
    await this.listRetailOutlets(country, state);
    await this.listLaboratories(country, state);
  };




  componentDidMount() {
    const { globalState, addItem } = this.context;
    this.setState({ profile: globalState?.data })
    const { state, country } = globalState?.user
    console.log(state, country)
    this.listAbattoirs(state, country);
    this.getLastCheckin();
  }


  async listRetailOutlets(country, state) {
    let retailOutlets = await DataAPI.listUsers(1, 1000, `regular/${country}/${state}`, "");
    retailOutlets.forEach((element) => {
      element.value = element.id;
      element.label = element.name;
    });
    this.setState({ retailOutlets: retailOutlets });
  }

  async listLaboratories(country, state) {
    let data = await DataAPI.listUsers(1, 1000, `laboratory/${country}/${state}`, "");
    data.forEach((element) => {
      element.value = element.id;
      element.label = element.name;
    });
    this.setState({ laboratories: data });
  }

  componentDidMount = async () => {
    await this.init();
  };

  submit() {
    let payload = this.state.payload;

    this.setState({ loading: true, errorMessage: null });
    APICall("api/labs/requesttest", "post", payload)
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          Toastr("success", "Sample forwarded successfully");
          this.setState({
            redirectUrl: "/in/send-test-success/" + response.data.sampleCode,
          });
        } else {
          this.setState({ loading: false, errorMessage: response.message });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          errorMessage: "Check your network connection",
        });
      });
  }

  updateProp(value, prop) {
    let payload = this.state.payload;
    if (!payload) payload = {};
    payload[prop] = value;
    this.setState({ payload: payload });
  }

  formGroupRow({ colClass, children }) {
    return (
      <div className="form-group row">
        <div className={colClass}>{children}</div>
      </div>
    );
  }

  formControl({ label, prop, inputType, required }) {
    if (!inputType) inputType = "text";
    let payload = this.state.payload;
    if (!payload) payload = {};

    return (
      <>
        <label className="control-label font-weight-bold text-darker">
          {label} {required && <small className="text-danger">*</small>}
        </label>
        <input
          type={inputType}
          className="form-control form-control-sm"
          placeholder=""
          defaultValue={payload[prop]}
          onChange={(e) => this.updateProp(e.target.value, prop)}
        />
      </>
    );
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <MainLayout title="New Test Sample">
        <div className="row">
          <div className="col-md-12 mb-5">
            <CardBlock title="">
              <div className="row">
                <div className="col-12 col-md-6 mb-0 mx-auto mt-5">
                  <form className="forms-sample">
                    <div className="form-group row">
                      <div className="col-12">
                        <label className="control-label font-weight-bold text-darker">
                          Animal Tag Number
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder=""
                          onChange={(e) =>
                            this.updateProp(e.target.value, "animalTagNumber")
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <label className="control-label font-weight-bold text-darker">
                          Retail Outlet <small className="text-danger">*</small>
                        </label>
                        <Select
                          value={this.state.retailOutlets.find(
                            (a) => a.id == this.state.payload.retailOutletId
                          )}
                          styles={Constants.selectStyles}
                          options={this.state.retailOutlets}
                          onChange={(option) =>
                            this.updateProp(option.id, "retailOutletId")
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <label className="control-label font-weight-bold text-darker">
                          Describe this sample and the tests required{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <textarea
                          className="form-control form-control-sm"
                          placeholder=""
                          onChange={(e) =>
                            this.updateProp(e.target.value, "vetComment")
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <label className="control-label font-weight-bold text-darker">
                          Laboratory to send samples to{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <Select
                          value={this.state.laboratories.find(
                            (a) => a.id == this.state.payload.laboratoryId
                          )}
                          styles={Constants.selectStyles}
                          options={this.state.laboratories}
                          onChange={(option) =>
                            this.updateProp(option.id, "laboratoryId")
                          }
                        />
                      </div>
                    </div>

                    {this.state.errorMessage ? (
                      <div className="mb-3">
                        <div className="alert alert-danger small" role="alert">
                          {this.state.errorMessage}
                        </div>
                      </div>
                    ) : null}

                    <div className="form-group row">
                      <div className="col  mt-4">
                        <SpinnerButton
                          onClick={() => this.submit()}
                          className="btn btn-success mr-2 btn-block btn-rounded"
                          label="Send Sample"
                          loading={this.state.loading}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </CardBlock>
          </div>
        </div>
      </MainLayout>
    );
  }
}
