import React, { Component } from "react";
import CardBlock from "../../Components/CardBlock";
import MainLayout from "../../Components/Layouts/MainLayout";
import AbattoirsList from "../../Components/AbattoirsList";

export default class AbattoirVetViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      loading: false,
      saveErrorMessage: null,
      payload: {},
    };
  }

  componentDidMount() {}

  updatePayload(value, index) {
    let payload = this.state.payload;
    payload[index] = value;
    this.setState({ payload: payload });
  }

  render() {
    return (
      <>
        <MainLayout title={"Abattoirs"}>
          <div className="row">
            <div className="col-md-12 mb-5">
              <CardBlock>
                <div className="text-right"></div>
                <AbattoirsList />
              </CardBlock>
            </div>
          </div>
        </MainLayout>
      </>
    );
  }
}
