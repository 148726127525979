import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import { Link } from "react-router-dom";
import Spinner from "../../Utils/Spinner";
import RevenueReportsList from "../../Components/RevenueReportsList";

export default class RevenuePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <MainLayout title="Revenue">
        <div className="row">
          <div className="col-md-12 mb-5">
            <CardBlock>
              <RevenueReportsList />
            </CardBlock>
          </div>
        </div>
      </MainLayout>
    );
  }
}
