import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { throttle } from "lodash";
import MyTable from "../Utils/MyTable";
import Spinner from "../Utils/Spinner";
import DropdownLinkMenu from "./DropdownLinkMenu";
import { DataContext } from "../Contexts/DataContexts";
import APICall from "../Utils/APICall";
import CenterBlock from "./CenterBlock";

const RanchAnimalList = (props) => {
  const { selectRecord, animal } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [user, setUser] = useState({});
  const [alreadyGotResults, setAlreadyGotResults] = useState(false);

  const throttledReload = throttle(() => {
    init(page, pageSize, searchQuery);
  }, 500);

  const context = useContext(DataContext);

  useEffect(() => {
    Modal.setAppElement("body");
    init();
    // new Functions().whoIsLoggedIn(this.setLoggedInUser, false);
  }, [animal]);

  const RenderTable = (_data) => {
    const headerList = [
      {
        Header: "",
        accessor: "actionsComponent",
      },
      {
        Header: "Animal Tag",
        accessor: "animalTagId",
      },
      {
        Header: "Sub-Specie",
        accessor: "subSpecie",
      },
      {
        Header: "Dam",
        accessor: "dam",
      },
      {
        Header: "Sire",
        accessor: "sire",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "",
        accessor: "investorsTrigger",
      },
    ];

    _data._data.forEach((element) => {
      element.nameComponent = (
        <strong
          className="clickable"
          onClick={() => selectRecord(element)}
        >
          {element.name}
        </strong>
      );

      element.investorsTrigger = (
        <Link
          style={{ cursor: "pointer" }}
          className="btn btn-xs btn-success btn-rounded"
          to={`/in/ranch/animal/${element.animalTagId}`}
        >
          View Animal
        </Link>
      );

      element.actionsComponent = (
        <DropdownLinkMenu
          label="..."
          linksArray={[
            `/in/all-certificates/${element.id}`,
            `/in/all-inspection-reports/0/0/${element.id}`,
          ]}
          linkLabelsArray={[
            "View Issued Certificates",
            "View Inspection Reports",
          ]}
        />
      );
    });

    const tableHeader = React.useMemo(() => headerList, []);
    const tableData = React.useMemo(() => _data, []);

    return (
      <div style={{ zoom: "0.85" }}>
        <MyTable
          columns={tableHeader}
          data={tableData._data}
          enableSearch={true}
          cssArray={["w50px", "", "", ""]}
        />
      </div>
    );
  };

  const init = (page, pageSize, query) => {
    if (!query) query = "";

    setSearchQuery(query);
    setPage(page);
    setPageSize(pageSize);

    context && setUser(context.globalState?.user);

    const { state, country, userRole, id } = context?.globalState?.user;

    let apiURL = `api/vets/get/${country}/${state}`;

    if (userRole === "superadmin") {
      apiURL = "api/vets/list";
    }


    APICall(`/api/Ranch/getranchanimalsbyspecie/${id}/${animal}`, "GET", {})
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          setData(response.data)
        } else {
          setErrorMessage(
            "Your request generated an error. Try again after sometime"
          );
          setLoading(false);
        }
        console.log(data);
      })
      .catch((error) => {
        setErrorMessage(
          "Your request generated an error. Please check your network connection"
        );
        setLoading(false);
      });
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : errorMessage ? (
    <CenterBlock height="150">
      <h1 className="text-danger icon-lg">
        <i className="mdi mdi-alert-circle"></i>
      </h1>
      <div className="text-dark text-center" role="alert">
        An error occurred, you can try reloading the page
      </div>
      <button
        type="button"
        onClick={() => init()}
        className="btn btn-inverse-primary btn-fw mt-4 mb-5"
      >
        Reload
      </button>
    </CenterBlock>
  ) : (
    <>
      {loading ? (
        <div className="text-success" style={{ position: "absolute" }}>
          <Spinner size="1.5" />{" "}
        </div>
      ) : null}

      {!data ? null : <RenderTable _data={data} />}
    </>
  );
};

export default RanchAnimalList;
