import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import Modal from "react-modal";
import Select from "react-select";
import DataAPI from "../../Utils/DataAPI";
import Functions from "../../Utils/Functions";
import { DataContext } from "../../Contexts/DataContexts";

export default class TakeDeliveryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      errorMessage: null,
      step: 1,
      currentCheckin: {},
      distributors: [],
      redirectUrl: null,
      payload: {},
      investors: [],
      animalConfiguration: {},
      species: [],
      subSpecies: [],
      animalColors: [],
      nigerianStates: [],
      modeOfSlaughter: [],
      paymentChannel: null,
      amountPayable: 0,

    };

    this.formControl = this.formControl.bind(this);
  }

  paymentChannels = [
    { label: "Card", value: "card" },
    { label: "Cash", value: "cash" },
    { label: "POS", value: "pos" },
  ];

  genders = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
  ];
  static contextType = DataContext;

  init = async () => {
    this.getLastCheckin();
    Modal.setAppElement("body");
    const _config = await DataAPI.diseaseReportingConfiguration();
    const _species = [];
    _config.species.forEach((element) => {
      _species.push({ label: element, value: element });
    });

    const _nigerianStates = [];

    Constants.nigerianStates.forEach((element) => {
      _nigerianStates.push({ label: element, value: element });
    });

    const _modeOfSlaughter = [];

    Constants.modeOfSlaughter.forEach((element) => {
      _modeOfSlaughter.push({ label: element, value: element });
    });



    const { globalState, addItem } = this.context;
    this.setState({ profile: globalState?.data })
    const { state, country } = globalState?.user



    const _distributors = (await DataAPI.listDistributors(state, country)) || [];

    _distributors &&
      _distributors.forEach((element) => {
        element.value = element.id;
        element.label = element.companyName;
      });

    _distributors.push({
      label: "UNREGISTERED",
      value: 0,
      id: 0,
      companyName: "UNREGISTERED",
    });

    this.setState({
      animalConfiguration: _config,
      species: _species,
      nigerianStates: _nigerianStates,
      modeOfSlaughter: _modeOfSlaughter,
      distributors: _distributors,
    });
  };

  componentDidMount = async () => {
    // this.getLocation();
    await this.init();
  };

  getLastCheckin() {
    APICall("api/vets/lastcheckin", "get")
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          if (response.data) {
            this.setState({ currentCheckin: response.data }, () => { });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  calculateAmount = () => {
    if (!this.state.payload?.specie || !this.state.payload?.weight) {
      return;
    }
    APICall(
      `api/abattoirs/takedelivery/getamount/${this.state.payload.specie}/${this.state.payload.weight}`,
      "get"
    )
      .then((data) => {
        if (data.data.isSuccess) {
          const response = data.data;
          this.setState({ amountPayable: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidUpdate = async () => {
    console.log(this.state.payload)

  };
  takeDeliveryNow() {
    let payload = this.state.payload;
    payload.abattoirId = this.state.currentCheckin.id;
    payload.weight = parseInt(payload.weight);

    console.log(payload)
    if (!payload.animalOwner) {
      Toastr("error", "Select animal owner");
      return;
    }
    if (!this.state.paymentChannel) {
      Toastr("error", "Select payment channel");
      return;
    }

    if (payload.distributorId === 0 && !payload.buyerName) {
      Toastr("error", "Buyer name is required");
      return;
    }

    this.setState({ loading: true, errorMessage: null });
    APICall(
      `api/Abattoirs/takedelivery/${this.state.paymentChannel}`,
      "post",
      payload
    )
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          Toastr("success", "Delivery taken successfully");
          this.setState({
            redirectUrl: "/in/take-delivery-success/" + response.data.tagNumber,
          });
        } else {
          this.setState({ loading: false, errorMessage: response.message });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          errorMessage: "Check your network connection",
        });
      });
  }

  updateProp(value, prop) {
    if (prop == "specie") {
      this.loadSpeciesConfigs(value);
      this.updateProp(null, "subSpecie");
      this.updateProp(null, "color");
    }

    let payload = this.state.payload;
    if (!payload) payload = {};
    payload[prop] = value;
    this.setState({ payload: payload }, () => {
      if (prop == "weight" || prop == "specie") {
        this.calculateAmount();
      }
    });
  }

  loadSpeciesConfigs(specie) {
    this.loadSubSpecies(specie);
    this.loadSpeciesColors(specie);
  }

  loadSpeciesColors(specie) {
    const _colors =
      this.state.animalConfiguration.colorOptions.filter(
        (a) => a.specie.toLowerCase() == specie.toLowerCase()
      ) || [];

    const _colorOptions = [];
    _colors.forEach((element) => {
      _colorOptions.push({
        label: element.color,
        value: element.color,
      });
    });

    this.setState({ animalColors: _colorOptions });
  }

  loadSubSpecies(specie) {
    const _subSpecies =
      this.state.animalConfiguration.subSpecies.filter(
        (a) => a.specie.toLowerCase() == specie.toLowerCase()
      ) || [];

    const _subSpeciesOptions = [];
    _subSpecies.forEach((element) => {
      _subSpeciesOptions.push({
        label: element.subSpecie,
        value: element.subSpecie,
      });
    });

    this.setState({ subSpecies: _subSpeciesOptions });
  }

  handleChange(option) {
    let payload = this.state.payload;
    if (!payload) payload = {};
    payload.distributorId = option.id;
    this.setState({ payload: payload });
  }

  formGroupRow({ colClass, children }) {
    return (
      <div className="form-group row">
        <div className={colClass}>{children}</div>
      </div>
    );
  }

  formGroupRow2({ colClass, children, extraChildren }) {
    return (
      <div className="form-group row">
        <div className={colClass}>{children}</div>
        {extraChildren && <div className={colClass}>{extraChildren}</div>}
      </div>
    );
  }

  formControl({ label, prop, inputType, required }) {
    if (!inputType) inputType = "text";
    let payload = this.state.payload;
    if (!payload) payload = {};

    return (
      <>
        <label className="control-label font-weight-bold text-darker">
          {label} {required && <small className="text-danger">*</small>}
        </label>
        <input
          type={inputType}
          className="form-control form-control-sm"
          placeholder=""
          defaultValue={payload[prop]}
          onChange={(e) => this.updateProp(e.target.value, prop)}
        />
      </>
    );
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <MainLayout title="Take Animal Delivery">
        <div className="row">
          <div className="col-md-12 mb-5">
            {this.state.step == 1 && (
              <CardBlock title="">
                <div className="row">
                  <div className="col-md-12 mb-0">
                    <form className="forms-sample">
                      <div className="form-group row">
                        <div className="col-12">
                          <label className="control-label font-weight-bold text-darker">
                            Approved slaughter house{" "}
                            <span className="text-muted mx-2">|</span>
                            <Link to="/in/checkin" className="theme-color">
                              Change
                            </Link>
                          </label>
                          <input
                            type="text"
                            readOnly
                            className="form-control form-control-sm"
                            placeholder={this.state.currentCheckin.name}
                            defaultValue={this.state.currentCheckin.name}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <hr />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-md-3">
                          <label className="control-label font-weight-bold text-darker mt-3">
                            Animal / Specie{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <Select
                            value={this.state.species.find(
                              (a) => a.value == this.state.payload.animalSpecie
                            )}
                            styles={Constants.selectStyles}
                            options={this.state.species}
                            onChange={(option) =>
                              this.updateProp(option.value, "specie")
                            }
                          />
                        </div>
                        <div className="col-12 col-md-3">
                          <label className="control-label font-weight-bold text-darker mt-3">
                            Sub-specie
                          </label>
                          <Select
                            value={this.state.subSpecies.find(
                              (a) => a.value == this.state.payload.subSpecie
                            )}
                            styles={Constants.selectStyles}
                            options={this.state.subSpecies}
                            onChange={(option) =>
                              this.updateProp(option.value, "subSpecie")
                            }
                          />
                        </div>
                        <div className="col-12 col-md-3">
                          <label className="control-label font-weight-bold text-darker mt-3">
                            Gender
                          </label>
                          <Select
                            value={this.genders.find(
                              (a) => a.value == this.state.payload.gender
                            )}
                            styles={Constants.selectStyles}
                            options={this.genders}
                            onChange={(option) =>
                              this.updateProp(option.value, "gender")
                            }
                          />
                        </div>
                        <div className="col-12 col-md-3">
                          <label className="control-label font-weight-bold text-darker mt-3">
                            Animal Color
                          </label>
                          <Select
                            value={this.state.animalColors.find(
                              (a) => a.value == this.state.payload.color
                            )}
                            styles={Constants.selectStyles}
                            options={this.state.animalColors}
                            onChange={(option) =>
                              this.updateProp(option.value, "color")
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-md-3 mt-3">
                          <this.formControl
                            label="Gross Weight (in kg)"
                            prop="weight"
                            inputType="number"
                          />
                        </div>
                        <div className="col-12 col-md-3 mt-3">
                          <this.formControl
                            label="Animal Owner"
                            prop="animalOwner"
                            inputType="text"
                          />
                        </div>
                        <div className="col-12 col-md-3">
                          <label className="control-label font-weight-bold text-darker mt-3">
                            State of Origin
                          </label>
                          <Select
                            value={this.state.nigerianStates.find(
                              (a) => a.value == this.state.payload.stateOfOrigin
                            )}
                            styles={Constants.selectStyles}
                            options={this.state.nigerianStates}
                            onChange={(option) =>
                              this.updateProp(option.value, "stateOfOrigin")
                            }
                          />
                        </div>
                        <div className="col-12 col-md-3">
                          <label className="control-label font-weight-bold text-darker mt-3">
                            Mode of Slaughter
                          </label>
                          <Select
                            value={this.state.modeOfSlaughter.find(
                              (a) => a.value == this.state.payload.mmodeOfSlaughter
                            )}
                            styles={Constants.selectStyles}
                            options={this.state.modeOfSlaughter}
                            onChange={(option) =>
                              this.updateProp(option.value, "modeOfSlaughter")
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-md-6">
                          <label className="control-label font-weight-bold text-darker">
                            Attending Vet's Observations
                          </label>
                          <textarea
                            className="form-control form-control-sm"
                            placeholder=""
                            onChange={(e) =>
                              this.updateProp(e.target.value, "vetObservations")
                            }
                          />
                        </div>
                      </div>

                      {this.state.amountPayable > 0 && (
                        <div className="form-group row">
                          <div className="col-12 col-md-6">
                            <hr />
                            <label className="control-label font-weight-bold text-darker">
                              Amount due:{" "}
                            </label>
                            <h1 className="mt-0 theme-color">
                              <strong>
                                {Functions.currencyFormat(
                                  this.state.amountPayable
                                )}
                              </strong>
                            </h1>
                          </div>
                        </div>
                      )}

                      <this.formGroupRow2
                        colClass={`${["pos", "cash"].includes(
                          this.state.paymentChannel?.toLowerCase()
                        )
                          ? "col-6 col-md-3"
                          : "col-12 col-md-6"
                          }`}
                        children={
                          <>
                            <label className="control-label font-weight-bold text-darker">
                              Payment Channel
                            </label>
                            <Select
                              value={this.paymentChannels.find(
                                (a) => a.value == this.state.paymentChannel
                              )}
                              styles={Constants.selectStyles}
                              options={this.paymentChannels}
                              onChange={(option) =>
                                this.setState({ paymentChannel: option.value })
                              }
                            />
                          </>
                        }
                        extraChildren={
                          ["pos", "cash"].includes(
                            this.state.paymentChannel?.toLowerCase()
                          ) ? (
                            <>
                              <label className="control-label font-weight-bold text-darker">
                                Receipt Number
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                onChange={(e) =>
                                  this.updateProp(
                                    e.target.value,
                                    "receiptNumber"
                                  )
                                }
                              />
                            </>
                          ) : null
                        }
                      />

                      <this.formGroupRow
                        colClass="col-12 col-md-6"
                        children={
                          <>
                            <label className="control-label font-weight-bold text-darker">
                              Supplier
                            </label>
                            <Select
                              value={this.state.distributors.find(
                                (a) => a.id == this.state.payload.distributorId
                              )}
                              styles={Constants.selectStyles}
                              options={this.state.distributors}
                              onChange={(option) => this.handleChange(option)}
                            />
                            {this.state.payload?.distributorId === 0 && (
                              <input
                                type="text"
                                className="form-control form-control-sm mt-3"
                                onChange={(e) =>
                                  this.updateProp(e.target.value, "buyerName")
                                }
                              />
                            )}
                          </>
                        }
                      />

                      {this.state.errorMessage ? (
                        <div className="mb-3">
                          <div
                            className="alert alert-danger small"
                            role="alert"
                          >
                            {this.state.errorMessage}
                          </div>
                        </div>
                      ) : null}

                      <div className="form-group row">
                        <div className="col col-md-3  mt-4">
                          <SpinnerButton
                            onClick={() => this.takeDeliveryNow()}
                            className="btn btn-success mr-2 btn-block btn-rounded"
                            label="Take Delivery"
                            loading={this.state.loading}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </CardBlock>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }
}
