import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardSetup from "../../Components/CardSetup";

export default class CardSetupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <MainLayout title="Payment Card">
        <div className="row">
          <div className="col-md-12 mb-5">
            <CardSetup userId={0} />
          </div>
        </div>
      </MainLayout>
    );
  }
}
