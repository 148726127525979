import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import "rc-datepicker/lib/style.css";
import { DataContext } from "../../Contexts/DataContexts";
import LabTestList from "../../Components/LabTestList";
import Modal from "react-modal";
import Constants from "../../Utils/Constants";
import Select from "react-select";
import SpinnerButton from "../../Utils/SpinnerButton";
import Functions from "../../Utils/Functions";
import APICall from "../../Utils/APICall";
import Toastr from "../../Utils/Toastr";
import Badge from "../../Components/Badge";

export default class VetsLabTestsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      errorMessage: null,
      selectedRecord: null,
      saveLoading: false,
      saveErrorMessage: null,
    };
  }

  static contextType = DataContext;

  vetActions = [
    { label: "No Action Required", value: "okay" },
    { label: "Blacklist Outlet", value: "blacklist" },
    { label: "Issue Warning", value: "warning" },
  ];

  componentDidMount() {}

  displayModal(record) {
    this.setState({ selectedRecord: record });
  }

  resetForm() {
    this.setState({ selectedRecord: null });
  }

  takeAction() {
    this.setState({ saveLoading: true });
    let payload = {
      actionTaken: this.state.actionTaken,
      comments: this.state.comments,
    };
    APICall(
      `api/labs/vetaction/${this.state.selectedRecord.sampleCode}`,
      "post",
      payload
    )
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          Toastr("success", "Report submitted");
          this.setState({
            selectedRecord: response.data,
            comments: null,
            actionTaken: null,
          });
        } else {
          Toastr("error", response.message);
        }
        this.setState({ saveLoading: false });
      })
      .catch((err) => {
        this.setState({
          saveLoading: false,
          saveErrorMessage: "Please check your network",
        });
      });
  }

  displayRecord = () => {
    return (
      <div
        className="mymodal-wrapper"
        style={{ maxWidth: "100%", width: "600px" }}
      >
        <h5 className="theme-color">
          Tracking code: <strong>{this.state.selectedRecord.sampleCode}</strong>
        </h5>
        <hr style={{ clear: "both", visibility: "hidden" }} />
        <form className="forms-sample">
          <div className="form-group row">
            <div className="col">
              <label className="small control-label font-weight-bold">
                Sample / Test Requested
              </label>
              <p className="text-darker mb-0">
                {this.state.selectedRecord.sampleDescription}
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12 col-md-4">
              <label className="small control-label font-weight-bold">
                Retail outlet
              </label>
              <p className="text-darker  mb-0">
                {this.state.selectedRecord.retailOutlet.name}
              </p>
            </div>
            <div className="col-12 col-md-4">
              <label className="control-label font-weight-bold small">
                Laboratory
              </label>
              <p className="text-darker  mb-0">
                {this.state.selectedRecord.laboratory.name}
              </p>
            </div>
            <div className="col-12 col-md-4">
              <label className="control-label font-weight-bold small">
                Veterinarian
              </label>
              <p className="text-darker  mb-0">
                {this.state.selectedRecord.vet.name}
              </p>
            </div>
          </div>

          {this.state.selectedRecord.testResult && (
            <div className="form-group row">
              <div className="col">
                <label className="small control-label font-weight-bold">
                  Test results:
                </label>
                <p className="text-darker mb-0">
                  {this.state.selectedRecord.testResult}
                </p>
                <p className="text-darker mt-3">
                  <strong className="text-muted">
                    Laboratory's Recommendation
                  </strong>{" "}
                  <br />
                  {this.state.selectedRecord.laboratoryComments}
                </p>
                <p className="text-darker mt-3">
                  <strong>Date: </strong>
                  {Functions.toReadableDate(
                    this.state.selectedRecord.testResultDate
                  )}
                </p>
              </div>
            </div>
          )}

          {this.state.selectedRecord.vetAction && (
            <div className="form-group row">
              <div className="col">
                <label className="small control-label font-weight-bold">
                  Vet Action:{" "}
                  {this.state.selectedRecord.vetAction == "blacklist" ? (
                    <span className="badge badge-danger">Blacklisted</span>
                  ) : (
                    <span className="badge badge-primary">
                      {this.state.selectedRecord.vetAction}
                    </span>
                  )}
                </label>
                <p className="text-darker mt-3">
                  <strong className="text-muted">Vet's Comments</strong> <br />
                  {this.state.selectedRecord.vetComments}
                </p>
                <p className="text-darker mt-3">
                  <strong>Vet's action date: </strong>
                  {Functions.toReadableDate(
                    this.state.selectedRecord.vetActionDate
                  )}
                </p>
              </div>
            </div>
          )}
          <hr />
          {!this.state.selectedRecord.testResult && (
            <p className="text-danger small">
              Test result has not been submitted by laboratory
            </p>
          )}
          {this.state.selectedRecord.testResult &&
            !this.state.selectedRecord.vetAction && (
              <>
                <div className="row">
                  <div className="col-12 col-lg-8 mx-auto">
                    <div className="form-group ">
                      <label className="mt-3 control-label font-weight-bold text-darker">
                        Action to take based on test results
                      </label>
                      <Select
                        styles={Constants.selectStyles}
                        options={this.vetActions}
                        onChange={(option) =>
                          this.setState({ actionTaken: option.value })
                        }
                      />
                    </div>
                    <div className="form-group ">
                      <label className="mt-3 control-label font-weight-bold text-darker">
                        Justification for action taken
                      </label>
                      <textarea
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ comments: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-8 mx-auto">
                    {this.state.saveErrorMessage ? (
                      <div className="mb-3">
                        <div className="alert alert-danger small" role="alert">
                          {this.state.saveErrorMessage}
                        </div>
                      </div>
                    ) : null}
                    <SpinnerButton
                      onClick={() => this.takeAction()}
                      className="btn btn-dark mr-2 mb-3 btn-block btn-rounded"
                      label="Submit Report"
                      loading={this.state.saveLoading}
                    />
                  </div>
                </div>
              </>
            )}
        </form>
      </div>
    );
  };

  render() {
    return (
      <MainLayout title="My Test Samples">
        {this.state.selectedRecord && (
          <Modal
            onRequestClose={() => this.resetForm()}
            isOpen={this.state.selectedRecord ? true : false}
            style={Constants.defaultModalStyle}
          >
            {this.displayRecord()}
          </Modal>
        )}
        <div className="row">
          <div className="col-md-12 mb-5">
            <CardBlock>
              <LabTestList
                vetId={this.context.globalState.user.id}
                selectAction={(record) => this.displayModal(record)}
              />
            </CardBlock>
          </div>
        </div>
      </MainLayout>
    );
  }
}
