import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import Modal from "react-modal";
import DataAPI from "../../Utils/DataAPI";
import Functions from "../../Utils/Functions";

export default class DeliveryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      distributor: {},
      errorMessage: null,
      certificateCode: null,
      certificate: null,
      diseaseReport: null,
      supplyLoading: false,
      redirectUrl: null,
      supplyList: [],
    };

    this.formControl = this.formControl.bind(this);
  }

  statusChecker = null;
  componentDidMount() {}

  componentWillUnmount() {
    if (this.statusChecker) {
      //CANCEL INTERVAL HERE
      clearInterval(this.statusChecker);
    }
  }

  startCheckingForStatus() {
    this.statusChecker = setInterval(() => {
      this.checkSupplyStatus();
    }, 10000);
  }

  async startSupply() {
    this.setState({ supplyLoading: true });
    const payload = {
      weight: parseFloat(this.state.weight),
      certificateCode: this.state.certificate.code,
    };

    let apiResponse = null;
    await APICall(`api/distributors/startsupply`, "POST", payload)
      .then((data) => {
        apiResponse = data.data;
      })
      .catch((error) => {
        Toastr("error", "An error occurred, please check your network.");
      });

    if (apiResponse?.isSuccess) {
      this.setState({ newsupply: apiResponse.data }, () => {
        setTimeout(() => {
          //wait for 10 seconds and start checking for status
          this.startCheckingForStatus();
        }, 10000);
      });
    } else {
      Toastr("error", apiResponse.message);
    }

    this.setState({ supplyLoading: false });
  }

  async checkSupplyStatus() {
    let apiResponse = null;
    await APICall(
      `api/distributors/pullsupply/${this.state.newsupply.supplyCode}`,
      "GET"
    )
      .then((data) => {
        apiResponse = data.data;
      })
      .catch((error) => {
        Toastr("error", "Unable to confirm status, please check your network.");
      });

    if (apiResponse?.isSuccess) {
      const supply = apiResponse.data.supply;
      if (supply.accepted) {
        this.setState({
          redirectUrl: `/in/supplier-delivery-success/${supply.supplyCode}`,
        });
      }
    }
  }

  async verifyCertificate() {
    this.setState({ validateLoading: true });
    let verifiedCertificate = await DataAPI.verifyCertificate(
      this.state.certificateCode
    );
    if (verifiedCertificate) {
      this.setState({
        certificate: verifiedCertificate.certificate,
        diseaseReport: verifiedCertificate.diseaseReport,
        supplyList: verifiedCertificate.supplyList,
      });
    } else {
      Toastr("error", "Invalid certificate number");
    }
    this.setState({ validateLoading: false });
  }

  dataRow({ label, data }) {
    return (
      <div className="row">
        <label className="col-6 control-label font-weight-bold text-darker">
          {label}
        </label>
        <label className="col-6 control-label font-weight-normal text-darker">
          {data}
        </label>
        <hr className="col-12 mt-1 mb-2" />
      </div>
    );
  }

  formControl({ label, prop, inputType, required }) {
    if (!inputType) inputType = "text";
    let payload = this.state.payload;
    if (!payload) payload = {};

    return (
      <>
        <label className="control-label font-weight-bold text-darker">
          {label} {required && <small className="text-danger">*</small>}
        </label>
        <input
          type={inputType}
          className="form-control form-control-sm"
          placeholder=""
          defaultValue={payload[prop]}
          onChange={(e) => this.updateProp(e.target.value, prop)}
        />
      </>
    );
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <MainLayout title="Meat Delivery Processing">
        {this.state.newsupply && (
          <Modal
            closeTimeoutMS={500}
            isOpen={this.state.newsupply ? true : false}
            contentLabel=""
            style={Constants.defaultModalStyle}
            onRequestClose={() =>
              this.setState({
                newsupply: null,
                certificate: null,
                weight: null,
              })
            }
          >
            <div className="p-4" style={{ maxWidth: "400px" }}>
              <h5>
                <span className="font-weight-bold theme-color">
                  Waiting for response <Spinner size={1} />
                </span>
              </h5>
              <hr style={{ clear: "both", visibility: "hidden" }} />
              <h1 className="text-darker">{this.state.newsupply.supplyCode}</h1>
              <p className="text-dark mb-5">
                Give the code above to the person taking delivery of this
                consignment to validate your supply
              </p>
              <button
                onClick={() => this.setState({ newsupply: null })}
                className="btn btn-rounded btn-sm btn-danger"
              >
                Cancel Delivery
              </button>
            </div>
          </Modal>
        )}
        <div className="row">
          <div className="col-md-12 mb-5">
            {this.state.loading ? (
              <Spinner />
            ) : (
              <CardBlock title="">
                <div className="row">
                  <div className="col-md-12 mb-0">
                    <form className="forms-sample">
                      <div className="form-group row">
                        <div className="col col-md-6 mx-auto">
                          <>
                            <label className="control-label font-weight-bold text-darker">
                              Certificate Code
                            </label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.setState({
                                    certificateCode: e.target.value,
                                  })
                                }
                                className="form-control"
                              />
                              <div className="input-group-append">
                                {this.state.validateLoading ? (
                                  <span className="ml-3">
                                    <Spinner size={2} />
                                  </span>
                                ) : (
                                  <button
                                    onClick={() => this.verifyCertificate()}
                                    className="btn btn-success"
                                    type="button"
                                  >
                                    Pull Details
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                      {this.state.certificate && (
                        <>
                          <hr />
                          <div className="form-group row my-5">
                            <div className="col-12 col-md-7 mx-auto">
                              <this.dataRow
                                label="Specie"
                                data={this.state.certificate.animalSpecie}
                              />
                              <this.dataRow
                                label="Date Issued"
                                data={new Functions().formatDate(
                                  this.state.certificate.timeIssued
                                )}
                              />
                              <this.dataRow
                                label="Type of parts"
                                data={this.state.certificate.typeOfParts}
                              />
                              <this.dataRow
                                label="Total weight (in kg)"
                                data={this.state.certificate.beefWeightInKg}
                              />
                              <this.dataRow
                                label="Supplied weight (in kg)"
                                data={this.state.supplyList.reduce((a, b) => {
                                  return a + b.weight;
                                }, 0)}
                              />
                            </div>
                            <div className="col-12 col-md-7 mx-auto mt-4">
                              <label className="control-label font-weight-bold theme-color">
                                Weight of this delivery (in kg){" "}
                                <small class="text-danger">*</small>
                              </label>
                              <br />
                              <small className="text-muted">
                                How many kg of meat are you supplying right now?
                              </small>
                              <div className="input-group mb-3 mt-2">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    this.setState({
                                      weight: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  style={{ maxWidth: "150px" }}
                                />
                                <div className="input-group-append">
                                  <SpinnerButton
                                    loading={this.state.supplyLoading}
                                    label="Process Supply"
                                    onClick={() => this.startSupply()}
                                    className="btn btn-success"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </CardBlock>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }
}
