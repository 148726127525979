import React, { Component } from "react";
import CardBlock from "../../Components/CardBlock";
import CenterBlock from "../../Components/CenterBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import MyTable from "../../Utils/MyTable";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import Functions from "../../Utils/Functions";
import Select from "react-select";
import { throttle } from "lodash";
import MainLayout from "../../Components/Layouts/MainLayout";
import "moment/locale/en-gb.js";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import DateFnsUtils from "@date-io/date-fns";

export default class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      errorMessage: null,
      searchQuery: "",
      page: 1,
      pageSize: 10,
      modalIsOpen: false,
      uploadErrorMessage: null,
      uploading: false,
      redirect: null,
      user: {},
      alreadyGotResults: false,
      dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      dateTo: new Date(),
    };

    this.RenderTable = this.RenderTable.bind(this);
    this.setLoggedInUser = this.setLoggedInUser.bind(this);
    this.init = this.init.bind(this);
  }

  quickReload() {
    this.init(1, this.state.pageSize, this.state.searchQuery);
  }

  componentDidMount() {
    Modal.setAppElement("body");
    new Functions().whoIsLoggedIn(this.setLoggedInUser, false);
  }

  setLoggedInUser(user, error) {
    this.setState({ loading: true });

    if (!error) {
      this.setState({ user: user }, () => {
        this.init(1, this.state.pageSize, "");
      });
    } else {
      this.setState({
        errorMessage:
          "Your request generated an error. Try again after sometime",
        loading: false,
      });
    }
  }

  RenderTable(_data) {
    var headerList = [
      {
        Header: "Reference",
        accessor: "referenceComponent",
      },
      {
        Header: "Licenses",
        accessor: "licenseCountComponent",
      },
      {
        Header: "Owner",
        accessor: "ownerComponent",
      },
      {
        Header: "Payment Date",
        accessor: "datePaidFormatted",
      },
      {
        Header: "Amount",
        accessor: "amountFormatted",
      },
    ];

    const tableHeader = React.useMemo(() => headerList, []);

    _data._data.forEach((element) => {
      element.referenceComponent = element.reference.toUpperCase();
      element.licenseCountComponent = element.licenseCount || 1;
      if (element.hospitalId > 0) {
        element.ownerComponent = (
          <div className={``}>{element.hospital.hospitalName}</div>
        );
      } else {
        element.ownerComponent = <div className={``}>{element.owner.name}</div>;
      }
    });

    const tableData = React.useMemo(() => _data, []);
    return (
      <div style={{ zoom: "0.85" }}>
        <MyTable
          columns={tableHeader}
          data={tableData._data}
          reloadData={this.init}
          enableSearch={true}
          searchQuery={this.state.searchQuery}
          page={this.state.page}
          pageSize={this.state.pageSize}
          cssArray={["", "text-center", "", "text-right", "text-right"]}
        />
      </div>
    );
  }

  init(page, pageSize, query) {
    if (!query) query = "";

    this.setState({
      errorMessage: null,
      loading: true,
      searchQuery: query,
      page: page,
      pageSize: pageSize,
    });

    var dateFrom = Functions.sqlDateString(this.state.dateFrom);
    var dateTo = Functions.sqlDateString(this.state.dateTo);

    APICall(
      `api/subscriptions/Fetch/${pageSize}/${page}/${dateFrom}/${dateTo}?query=${query}`,
      "GET",
      {}
    )
      .then((data) => {
        var response = data.data;
        if (response.status == "success") {
          this.setState({ loading: false, data: null }, () => {
            this.setState({ data: response.data });
          });
        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Try again after sometime",
            loading: false,
          });
        }
        console.log(data);
      })
      .catch((error) => {
        this.setState({
          errorMessage:
            "Your request generated an error. Please check your network connection",
          loading: false,
        });
      });
  }

  render() {
    return (
      <MainLayout title="Payment Transactions">
        {this.state.redirect ? (
          <Redirect to={this.state.redirect} />
        ) : this.state.errorMessage ? (
          <CenterBlock height="150">
            <h1 className="text-danger icon-lg">
              <i className="mdi mdi-alert-circle"></i>
            </h1>
            <div className="text-dark text-center" role="alert">
              An error occurred, you can try reloading the page
            </div>
            <button
              type="button"
              onClick={() => this.loadData()}
              className="btn btn-inverse-primary btn-fw mt-4 mb-5"
            >
              Reload
            </button>
          </CenterBlock>
        ) : (
          <CardBlock>
            {this.state.loading ? (
              <div
                className="text-success text-right"
                style={{ position: "absolute", right: "0px" }}
              >
                <Spinner size="1.5" />{" "}
              </div>
            ) : null}
            <div style={{ zoom: "0.85" }} className="mb-3">
              <div className="form-group row">
                <div className="col col-md-3">
                  <label className="control-label font-weight-bold text-darker">
                    Date from
                  </label>
                  <DatePickerInput
                    defaultValue={this.state.dateFrom}
                    onChange={(date) =>
                      this.setState({ dateFrom: date }, () => {
                        this.quickReload();
                      })
                    }
                    className="my-custom-datepicker-component"
                  />
                </div>
                <div className="col text-center" style={{ maxWidth: "20px" }}>
                  <label
                    style={{ visibility: "hidden" }}
                    className="control-label font-weight-bold text-darker"
                  >
                    -
                  </label>
                  <label className="control-label font-weight-bold text-darker mt-2">
                    _
                  </label>
                </div>
                <div className="col col-md-3">
                  <label className="control-label font-weight-bold text-darker">
                    Date to
                  </label>
                  <DatePickerInput
                    defaultValue={this.state.dateTo}
                    onChange={(date) =>
                      this.setState({ dateTo: date }, () => {
                        this.quickReload();
                      })
                    }
                    className="my-custom-datepicker-component"
                  />
                </div>
              </div>
            </div>
            <br style={{ clear: "both" }} />
            {!this.state.data ? null : (
              <this.RenderTable _data={this.state.data} />
            )}
          </CardBlock>
        )}
      </MainLayout>
    );
  }
}
