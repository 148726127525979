import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import { Link } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import "../../sass/kanban.scss";
import "moment/locale/en-gb.js";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../../sass/doctors-calendar.scss";
import moment from "moment";
import Modal from "react-modal";
import Select from "react-select";
import DataAPI from "../../Utils/DataAPI";
import Functions from "../../Utils/Functions";
import CertificatesList from "../../Components/CertificatesList";

export default class DistributorCertificatesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      distributor: {},
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.getDistributor();
  }

  //load user's distributor record
  getDistributor() {
    APICall("api/distributors/forcurrentuser", "get")
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          if (response.data) {
            this.setState({ distributor: response.data, loading: false });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <MainLayout title="My Certificates">
        <div className="row">
          <div className="col-md-12 mb-5">
            {this.state.loading ? (
              <Spinner />
            ) : (
              <CardBlock>
                <CertificatesList issuedTo={this.state.distributor.id} />
              </CardBlock>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }
}
