import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import Modal from "react-modal";
import Select from "react-select";
import DataAPI from "../../Utils/DataAPI";
import Functions from "../../Utils/Functions";
import RenderCertificate from "../../Components/RenderCertificate";
import DiseaseList from "../../Components/DiseaseReporting/DiseaseList";

export default class ReceiveConsignmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: null,
      certificate: null,
      diseaseReport: null,
      acceptLoading: false,
      redirectUrl: null,
      display: null,
    };

    this.formControl = this.formControl.bind(this);
  }

  componentDidMount() {}

  async acceptSupply() {
    this.setState({ acceptLoading: true });
    let apiResponse = null;
    await APICall(
      `api/distributors/acceptsupply/${this.state.supplyCode}`,
      "POST"
    )
      .then((data) => {
        apiResponse = data.data;
      })
      .catch((error) => {
        Toastr("error", "An error occurred, please check your network.");
        return;
      });

    if (apiResponse?.isSuccess) {
      this.setState({
        redirectUrl: `/in/receive-consignment-success/${this.state.supplyCode}`,
      });
    } else {
      Toastr("error", apiResponse.message);
      this.setState({ acceptLoading: false });
    }
  }

  rejectSupply() {
    this.setState({
      supply: null,
      certificate: null,
      diseaseReport: null,
      display: null,
    });
  }

  async verifySupply() {
    this.setState({ validateLoading: true });
    let apiResponse = null;
    await APICall(`api/distributors/pullsupply/${this.state.supplyCode}`, "GET")
      .then((data) => {
        apiResponse = data.data;
      })
      .catch((error) => {
        Toastr("error", "Unable to confirm status, please check your network.");
        return;
      });

    if (apiResponse?.isSuccess) {
      this.setState(
        {
          supply: apiResponse.data.supply,
          certificate: apiResponse.data.certificate,
          diseaseReport: apiResponse.data.diseaseReport,
        },
        () => {
          this.prepareView();
        }
      );
    } else {
      Toastr("error", apiResponse.message);
    }

    this.setState({ validateLoading: false });
  }

  prepareView() {
    const tabHeaders = [
      { icon: "", text: "Supply Details" },
      { icon: "", text: "Certificate" },
      { icon: "", text: "Condemnation History" },
    ];

    const tabContents = [
      this.supplyDetails(),
      <RenderCertificate certificateCode={this.state.certificate.code} />,
      <div className="p-4 mt-3">
        {<DiseaseList diseaseList={this.state.diseaseReport} />}
      </div>,
    ];

    this.setState({
      display: <Tabs headers={tabHeaders} contents={tabContents} />,
    });
  }

  dataRow({ label, data }) {
    return (
      <div className="row">
        <label className="col-6 control-label font-weight-bold text-darker">
          {label}
        </label>
        <label className="col-6 control-label font-weight-normal text-darker">
          {data}
        </label>
        <hr className="col-12 mt-1 mb-2" />
      </div>
    );
  }

  formControl({ label, prop, inputType, required }) {
    if (!inputType) inputType = "text";
    let payload = this.state.payload;
    if (!payload) payload = {};

    return (
      <>
        <label className="control-label font-weight-bold text-darker">
          {label} {required && <small className="text-danger">*</small>}
        </label>
        <input
          type={inputType}
          className="form-control form-control-sm"
          placeholder=""
          defaultValue={payload[prop]}
          onChange={(e) => this.updateProp(e.target.value, prop)}
        />
      </>
    );
  }

  supplyDetails = () => {
    return (
      <div className="form-group row my-5">
        <div className="col-12 col-md-7 mx-auto">
          <this.dataRow
            label="Specie"
            data={this.state.certificate.animalSpecie}
          />
          <this.dataRow
            label="Date Issued"
            data={new Functions().formatDate(this.state.certificate.timeIssued)}
          />
          <this.dataRow
            label="Type of parts"
            data={this.state.certificate.typeOfParts}
          />
          <this.dataRow
            label="Total weight supplied"
            data={`${this.state.supply.weight}kg`}
          />
        </div>
        <div className="col-12 col-md-7 mx-auto mt-4">
          <SpinnerButton
            loading={this.state.acceptLoading}
            label="Accept Supply"
            onClick={() => this.acceptSupply()}
            className="btn btn-success btn-rounded"
          />
          <button
            onClick={() => this.rejectSupply()}
            className="btn btn-danger btn-rounded ml-3"
            type="button"
          >
            Reject Supply
          </button>
        </div>
      </div>
    );
  };

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <MainLayout title="Receive Consignment">
        <div className="row">
          <div className="col-md-12 mb-5">
            {this.state.loading ? (
              <Spinner />
            ) : (
              <CardBlock title="">
                <div className="row">
                  <div className="col-md-12 mb-0">
                    <form className="forms-sample">
                      <div className="form-group row">
                        <div className="col col-md-6 mx-auto">
                          <>
                            <label className="control-label font-weight-bold text-darker theme-color mt-3">
                              ENTER SUPPLY CODE
                            </label>
                            <p>
                              The person in charge of your delivery will issue a
                              supply code to you. Enter it below to confirm
                              consignment details.
                            </p>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.setState({
                                    supplyCode: e.target.value,
                                  })
                                }
                                className="form-control"
                              />
                              <div className="input-group-append">
                                {this.state.validateLoading ? (
                                  <span className="ml-3">
                                    <Spinner size={1} />
                                  </span>
                                ) : (
                                  <button
                                    onClick={() => this.verifySupply()}
                                    className="btn btn-success"
                                    type="button"
                                  >
                                    View Details
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                      {this.state.display && <>{this.state.display}</>}
                    </form>
                  </div>
                </div>
              </CardBlock>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }
}
