import React, { Component } from "react";
import CardBlock from "../CardBlock";
import CenterBlock from "../CenterBlock";
import { Link } from "react-router-dom";
import Spinner from "../../Utils/Spinner";
import Functions from "../../Utils/Functions";
import APICall from "../../Utils/APICall";
import Constants from "../../Utils/Constants";

export default class RegularDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: null,
      data: null,
    };
  }

  componentDidMount() {}

  statBox(prop) {
    return (
      <div className="col-xl-4 col-lg-4 col-sm-6 grid-margin stretch-card">
        <div className="w-100 card rounded">
          <Link to={prop.link} className="no-decoration">
            <div className="card-body text-center compact py-4">
              <div className="row">
                <div className="col-4 text-left">
                  <i className={`${prop.icon} icon-lg theme-color`}></i>
                </div>
                <div className="col">
                  <h5 className="mt-3 font-weight-normal text-muted text-left">
                    {prop.title}
                  </h5>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="row ">
        <div className="col-12">
          <div className="row">
            <this.statBox
              link={"/in/receive-consignment"}
              title="Receive Consignment"
              icon={"mdi mdi-cube-send menu-icon"}
            />
            <this.statBox
              link={"/in/my-delivery-log"}
              title="Consignment Delivery Log"
              icon={"mdi mdi-format-align-center menu-icon"}
            />
            <this.statBox
              link={"/in/verify-certificate"}
              title="Check Certificate Validity"
              icon={"mdi mdi-file-check menu-icon"}
            />
          </div>
        </div>
      </div>
    );
  }
}
